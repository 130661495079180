import axios from 'axios';

axios.interceptors.request.use(async (config) => {

  if (!config.url.endsWith('load')) {
    // console.log("Interceptor - request " + config.url);
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + localStorage.token,
        Device: localStorage.device
      }
    } else {
      config.headers = {
        Device: localStorage.device
      }
    }
  } else {
    // console.log("Interceptor - load");
    if (localStorage.token != null) {
      config.headers = {
        Authorization: 'Bearer ' + localStorage.token,
        Device: localStorage.device
      }
    } else {
      config.headers = {
        Device: localStorage.device
      }
    }
    // console.log(config);
  }

  if (window.location.href.indexOf("/totem/") > -1) {
    config.headers["Channel"] = "totem";
  } else {
    config.headers["Channel"] = "webapp";
  }
  // console.log("Interceptor - ok ");
  return config;
}, (error) => {
  // I cand handle a request with errors here
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('AQUI ESTA ERROR INTERCEPTOR', { error })
  if (error.response.data.message === 'Sessão não informada.') {
    let path = encodeURI(window.location.pathname);
    let pathSplited = path.split("/")
    let shopping, campaign, totem = ''
    if (pathSplited[1] == 'totem') {
      shopping = pathSplited[2]
      campaign = pathSplited[3]
      totem = 'totem/'
    } else {
      shopping = pathSplited[1]
      campaign = pathSplited[2]
    }
    window.open(`${totem}${shopping}/${campaign}/user/login`, "_self")
  }
  return Promise.reject(error);
});