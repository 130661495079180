<template>
  <MainLayout>
    <div class="qrcode-campaign-container" :style="style">
      <div class="top-title">
        <h1>Aponte a câmera para o QRcode da nota</h1>
      </div>
      <!-- <code v-if="device">{{ device.label }}</code> -->

      <div v-if="readQrcode">
        <div class="camera-content">
          <div class="button-area">
            <img class="qrcode-frame" src="../assets/icons/qrcode_frame.svg" alt="" />
          </div>
        </div>

        <div class="screen" v-if="readQrcode">
          <qrcode-stream d="qrcode-stream" @decode="onDecode" v-if="readQrcode">
          </qrcode-stream>
        </div>

        <div class="footer-content"
          v-if="campaign.gears && campaign.gears.control && campaign.gears.control.upload_image">
          <button class="primary">QRCode</button>
          <button class="primary-off" @click="openCamera()">Foto da nota</button>
        </div>
      </div>

      <div v-if="process">
        <h2>Processando sua nota...</h2>
      </div>
    </div>
    <!-- <div v-if="campaign.quizz && campaign.quizz[0].is_enable">
      <popup-question :question="campaign.quizz[0].question" :options="campaign.quizz[0].options"
        :campaignSlug="campaign.company.slug + '-' + campaign.slug" @closed="isOpenQuestion = false"
        :isOpen="isOpenQuestion"></popup-question>
    </div> -->
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/camera";
import CampaignMixin from "../mixins/campaign.mixin.js";
import { QrcodeStream } from 'vue-qrcode-reader'
// import PopupQuestion from "../components/popup-question.vue";

export default {
  // name: "BillQrCode",
  mixins: [CampaignMixin],
  components: {
    MainLayout,
    // PopupQuestion,
    QrcodeStream
  },
  data() {
    return {
      photo: null,
      QRCode: "",
      readQrcode: true,
      process: false,
      isOpenQuestion: false
    };
  },
  mounted() {
    //this.$refs.rootElement.requestFullscreen();
    // if (this.campaign) {
    //   if (this.campaign.quizz[0].is_enable) {
    //     this.isOpenQuestion = true;
    //   }
    //   var token = "question-" + this.campaign.company.slug + '-' + this.campaign.slug;
    //   console.log(token);
    //   console.log('aqui esta', localStorage[token]);

    //   if (localStorage[token] && this.campaign.quizz[0].frequency == 'ONCE') {
    //     console.log("passed");
    //     this.isOpenQuestion = false;
    //   }
    // }

    console.log("isOpenQuestion", this.isOpenQuestion);
  },
  methods: {
    openCamera() {
      window.open(this.campaignHome + "/camera-photo", "_self");
    },
    openSuccess() {
      window.open(this.campaignHome + "/sucesso", "_self");
    },
    onDecode(decodedString) {
      if (decodedString.trim() != "") {
        let self = this;
        this.QRCode = decodedString;
        this.process = true;
        this.takePhoto = false;
        this.readQrcode = false;

        // const question = "question-" + this.campaign.company.slug + '-' + this.campaign.slug
        // let answer
        // if (localStorage[question]) answer = localStorage[question]

        this.sendBillQRCode(decodedString).then((response) => {
          console.log(response);
          self.process = false;
          self.QRCode = "";

          if (response) {
            self.openSuccess();
            self.sucess = true;
          } else {
            if (self.campaign.gears.control.upload_image) {
              self.openCamera();
              self.takePhoto = true;
            } else {
              self.QRCode = decodedString;
              self.process = false;
              self.readQrcode = true;
              self.sucess = false;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" >
@import "../scss/colors.scss";

.qrcode-campaign-container {
  position: relative;
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .top-title {
    position: relative;
    z-index: 100;

    h1 {
      font-size: 11pt !important;
    }
  }

  .camera-content {
    position: relative;
    z-index: 100;

    .qrcode-frame {


      position: relative;
      height: 70%;
      margin: auto;
      margin-top: 5%;
    }

    .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
  }

  h2 {
    text-align: center;
    margin-top: 20%;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;


    video {
      position: absolute;
      height: auto;
      width: 100%;
    }
  }

  .footer-content {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;

    flex-direction: row;
    padding: 10px 0px;
  }
}

.qrcode-stream-camera,
.qrcode-stream-pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}

@media only screen and (max-width: 800px) {
  .qrcode-campaign-container {
    position: relative;
    min-height: calc(100vh - 100px);

    .camera-content {
      .qrcode-frame {
        position: absolute;
        width: 70%;
        height: auto;
        // max-height: 70%;
        margin: 15%;
      }
    }

    .screen {
      height: 100vh !important;
      position: fixed !important;
      top: 0px;

      .qrcode-frame {
        max-height: 100vh;
      }
    }
  }
}
</style>