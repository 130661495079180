import Vue from 'vue'

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        var temp = parseFloat(value);
        if(!temp) {
            return value;
        } else {
            value = temp;
        }
        
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});