<template>
  <MainLayout>
    <div class="note-list" v-if="campaign.gears && campaign.gears.control">
      <div class="top-title" v-if="!campaign.gears.control.has_wallet">
        <h1>Notas enviadas</h1>
      </div>
      <div class="top-title" v-if="!(campaign.gears.control.send_qrcode || campaign.gears.control.upload_image)">
        <h1>Compras pelo app</h1>
      </div>
      <div class="top-title"
        v-if="campaign.gears.control.has_wallet && (campaign.gears.control.send_qrcode || campaign.gears.control.upload_image)">
        <ul>
          <li v-if="campaign.gears.control.send_qrcode || campaign.gears.control.upload_image">
            <button :class="{ active: tab == 'bill' }" @click="tab = 'bill'">Notas enviadas</button>
          </li>
          <li>
            <button :class="{ active: tab == 'payment' }" @click="tab = 'payment'">Compras pelo app</button>
          </li>
        </ul>
      </div>

      <div class="notes-content container">
        <h1 class="info" v-if="orders.length == 0">{{ listMessage }}</h1>

        <div v-if="orders.length > 0">
          <div v-for="order in orders" :key="order.numero" @click="openOrder(order)">
            <order-item :order="order"></order-item>
          </div>

        </div>
      </div>


    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import OrderMixin from "../mixins/order.mixin.js";
import userMixin from "../mixins/user.mixin";


import OrderItem from "../components/order-item.vue";

import "../filters/toCurrency";

export default {
  mixins: [CampaignMixin, userMixin, OrderMixin],
  components: {
    MainLayout,
    OrderItem
  },
  data() {
    return {
      tab: "bill",
      orders: [],
      listMessage: "Carregando",
      listMessagePayment: "Carregando"
    };
  },
  mounted: function () {
    let self = this
    let user = JSON.parse(localStorage.user)
    const data = {
      shopping: localStorage.shoppingSlug,
      campaign: localStorage.campaignSlug,
      user: user._id,
    };

    this.listBills(data).then((response) => {
      self.listMessage = "Você não possui notas";
      if (response) {
        self.orders = response;
      }
    }).catch(function () {
      self.listMessage = "Você não possui notas";
    });

    // this.listBillsPayment().then((response) => {
    //   self.listMessagePayment = "Você não possui pagamentos";
    //   if(response) {
    //     self.orders = response;
    //   }
    // }).catch(function () {
    //     self.listMessage = "Você não possui notas";
    // });
  },
  methods: {
    openOrder(order) {
      this.$router.push(this.campaignHome + "/nota-fiscal/" + order._id, "_self");
    },
  }
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.note-list {
  position: relative;
  margin-bottom: 150px;
}

.notes-content {
  height: 100%;
  padding: 20px 10px 0px 20px;
  margin-bottom: 50px;

  text-align: center;
  align-items: center;
  justify-content: space-evenly;

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }


}
</style>