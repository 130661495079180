<template>
  <layout class="painel-user-edit">
    <div class="page-left dark" @click="keyboardSide = 'hide'">
      <div class="container">
        <header style="height: 70px;">
          <a :href="home">
            <img class="logo" v-if="campaign && campaign.company && campaign.company.image"
              :src="getImageUrl(campaign.company.image)" alt="" />
            <img v-else class="logo" src="~@/assets/images/logo-big.png" alt="" />
          </a>
        </header>
        <div>
          <div class="card">
            <form @click.stop="">
              <ok-input :model="customer.name" @updated="(x) => {
                customer.name = x;
                storeUser();
              }
                " :label="'Nome*'" :id="'name'" :placeholder="' '" @onFocus="onFocus('name', 'right')">
              </ok-input>

              <ok-input :model="customer.lastname" @updated="(x) => {
                customer.lastname = x;
                storeUser();
              }
                " :label="'Sobrenome*'" :id="'lastname'" :placeholder="' '" @onFocus="onFocus('lastname', 'right')">
              </ok-input>

              <div class="input-frame">
                <input v-if="campaign.user_fields.birthday !== 'INACTIVE'" id="birthday" name="birthday"
                  placeholder="DD/MM/AAAA" v-model="customer.birthday" v-on:focus="onFocus('birthday', 'right')"
                  v-mask="'##/##/####'" autocomplete="off" />
                <label for="birthday"><span>Data de nascimento{{ addAsterisk(campaign.user_fields.birthday)
                }}</span></label>
              </div>

              <ok-input v-if="campaign.user_fields.cpf !== 'INACTIVE'" :model="customer.document" @updated="(x) => {
                customer.document = x;
                storeUser();
              }
                " :label="'CPF' + addAsterisk(campaign.user_fields.cpf)" :id="'document'" :mask="'###.###.###-##'"
                :placeholder="'000.000.000-00'" @onFocus="onFocus('document', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.rg !== 'INACTIVE'" :model="customer.document_rg" @updated="(x) => {
                customer.document_rg = x;
                storeUser();
              }
                " :label="'RG' + addAsterisk(campaign.user_fields.rg)" :id="'document-rg'" :placeholder="' '"
                @onFocus="onFocus('document_rg', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.email !== 'INACTIVE'
                " :model="customer.email" @updated="(x) => {
    customer.email = x;
    storeUser();
  }
    " :label="'Email' + addAsterisk(campaign.user_fields.email)" :id="'email'" :placeholder="'nome@email.com'"
                @onFocus="onFocus('email', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.phone !== 'INACTIVE'
                " :model="customer.phone" @updated="(x) => {
    customer.phone = x;
    storeUser();
  }
    " :label="'Telefone' + addAsterisk(campaign.user_fields.phone)" :id="'phone'" :mask="'(##) #.####.####'"
                :placeholder="'(00) 0.0000-0000'" @onFocus="onFocus('phone', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.gender !== 'INACTIVE'" :model="customer.gender" @updated="(x) => {
                customer.gender = x;
                storeUser();
              }
                " :label="'Sexo' + addAsterisk(campaign.user_fields.gender)" :id="'gender'" :options="genderOptions"
                :placeholder="'Sexo'" @onFocus="onFocus('gender', 'right')">
              </ok-input>
              <div class="terms-conditions" v-if="campaign.lgpd.accept_emailContact">
                <label class="checkbox bounce">
                  <input :id="'email-contact'" type="checkbox" v-model="customer.lgpd.accept_emailContact"
                    @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito receber informações por email. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_phoneContact">
                <label class="checkbox bounce">
                  <input :id="'phone-contact'" type="checkbox" v-model="customer.lgpd.accept_phoneContact"
                    @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito receber informações por SMS. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_lgpd">
                <label class="checkbox bounce">
                  <input :id="'accept-lgpd'" type="checkbox" v-model="customer.lgpd.accept_lgpd" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito compartilhar minhas informações. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_terms">
                <label class="checkbox bounce">
                  <input :id="'terms'" type="checkbox" v-model="userAgreeTerms" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span @click="openTerms()">
                  Li e concordo com os
                  <strong>termos de uso</strong>
                  e com a
                  <strong>politica de privacidade</strong>.
                </span>
              </div>

              <div class="rules-conditions" v-if="campaign.lgpd.accept_rules">
                <label class="checkbox bounce">
                  <input :id="'rules'" type="checkbox" v-model="userAgreeRules" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span @click="openRules()">
                  Li e concordo com o
                  <strong>regulamento</strong>
                  da campanha.
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="page-right" @click="keyboardSide = 'hide'">
      <div style="height: 70px;"></div>
      <div class="card">
        <form @click.stop="">

          <ok-input :model="address.zipcode" @updated="(x) => {
            address.zipcode = x;
            updateUserAddress();
            storeAddress();
          }
            " @onEnter="findAddress" @onBlur="findAddress" :label="'CEP' + addAsterisk(campaign.user_fields.address)"
            :id="'zipcode'" :mask="'##.###-###'" :placeholder="'00.000-000'" @onFocus="onFocus('zipcode', 'left')">
          </ok-input>

          <ok-input :model="address.street" @updated="(x) => {
            address.street = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'Logradouro' + addAsterisk(campaign.user_fields.address)" :id="'street'" :placeholder="'Rua ..'"
            @onFocus="onFocus('street', 'left')">
          </ok-input>

          <ok-input :model="address.number" @updated="(x) => {
            address.number = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'número' + addAsterisk(campaign.user_fields.address)" :id="'number'" :placeholder="'0'"
            @onFocus="onFocus('number', 'left')">
          </ok-input>

          <ok-input :model="address.complement" @updated="(x) => {
            address.complement = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'Complemento'" :id="'complement'" :placeholder="'complemento ...'"
            @onFocus="onFocus('complement', 'left')">
          </ok-input>

          <ok-input :model="address.district" @updated="(x) => {
            address.district = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'Bairro' + addAsterisk(campaign.user_fields.address)" :id="'district'" :placeholder="' '"
            @onFocus="onFocus('district', 'left')">
          </ok-input>

          <ok-input :model="address.city" @updated="(x) => {
            address.city = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'Cidade' + addAsterisk(campaign.user_fields.address)" :id="'city'" :placeholder="' '"
            @onFocus="onFocus('city', 'left')">
          </ok-input>

          <ok-input :model="address.state" @updated="(x) => {
            address.state = x;
            updateUserAddress();
            storeAddress();
          }
            " :label="'Estado' + addAsterisk(campaign.user_fields.address)" :id="'state'" :placeholder="' '"
            @onFocus="onFocus('state', 'left')">
          </ok-input>
        </form>
      </div>
    </div>

    <VirtualKeyboard @onKeyPress="onKeyPress" :inputId="editVar" :side="keyboardSide" />
  </layout>
</template>


<script >
import Layout from "../layout/base";
import CampaignMixin from "@/mixins/campaign.mixin.js";
import UserMixin from "@/mixins/user.mixin.js";
import OkInput from "../../../components/ok-input.vue";
import VirtualKeyboard from "../../../components/virtual-keyboard.vue";

export default {
  mixins: [CampaignMixin, UserMixin],
  components: {
    Layout,
    OkInput,
    VirtualKeyboard
  },
  data() {
    return {
      customer: {
        lgpd: {
          accept_emailContact: false
        },
        name: '',
        lastname: '',
        birthday: '',
        document: '',
        document_rg: '',
        email: '',
        phone: '',
        type: 'CUSTOMER',
        password: '',
        address: [
          {
            zipcode: '',
            street: '',
            number: '',
            apto: '',
            district: '',
            state: '',
            complement: '',
            city: '',
            country: ''
          }
        ],
      },
      home: "",
      isOpenRules: false,
      isOpenTerms: false,
      address: {
        zipcode: "",
        street: "",
        number: "",
        apto: "",
        district: "",
        state: "",
        complement: "",
        city: "",
        country: "",
      },
      userAgree: false,
      userAgreeTerms: false,
      userAgreeRules: false,
      genderOptions: [
        { name: "Masculino", value: "M" },
        { name: "Feminino", value: "F" },
        { name: "Outros", value: "O" },
        { name: "Não informar", value: "NI" },
      ],
      keyboardSide: 'hide',
      editVar: '',
    };
  },
  watch: {
    campaignHome(newValue) {
      this.home = `totem${newValue}`;
    },
    userAgreeTerms() {
      this.checkUserAgree();
    },
    userAgreeRules() {
      this.checkUserAgree();
    },
    customer(newValue) {
      // console.log("User", newValue);

      this.userAgreeTerms = this.customer.lgpd.accept_terms
      this.userAgreeRules = this.customer.lgpd.accept_rules
      if (newValue.address && newValue.address[0]) {
        this.address = newValue.address[0];
      }
    },
    user() {
      // console.log("User", newValue);

      this.customer = JSON.parse(JSON.stringify(this.user))
      if (this.customer.birthday) this.customer.birthday = this.dateToString(this.customer.birthday)
    },
    'address.zipcode': {
      handler(newValue) {
        // console.log(`Novo valor: ${newValue}, Valor antigo: ${oldValue}`);
        if (newValue.length == 10) {
          this.findAddress()
        }
      },
      deep: true
    },
  },
  mounted: function () {
  },
  methods: {
    checkUserAgree() {
      this.userAgree = true;
      if (this.campaign && this.campaign.lgpd.accept_rules) {
        if (!this.userAgreeRules) {
          this.userAgree = false;
        }
      }

      if (this.campaign && this.campaign.lgpd.accept_terms) {
        if (!this.userAgreeTerms) {
          this.userAgree = false;
        }
      }
    },
    openRules() {
      this.isOpenRules = true;
    },
    openTerms() {
      this.isOpenTerms = true;
    },

    findAddress() {
      let self = this;
      if (!this.address.zipcode || this.address.zipcode.length < 10) return
      this.getAddress(this.address.zipcode).then((response) => {
        if (response) {
          self.address.street = response.street;
          self.address.district = response.district;
          self.address.city = response.city;
          self.address.state = response.state;
        }

      });
    },

    updateUserAddress() {
      if (this.address.zipcode.length > 9) {
        // this.address.zipcode = this.address.zipcode.replace(/\D/g, "");
        if (!this.customer.address) this.customer.address = [];
        this.customer.address[0] = this.address;
      }
    },
    storeUser() {
      let userCopy = JSON.parse(JSON.stringify(this.customer));
      userCopy.lgpd.accept_rules = this.userAgreeRules
      userCopy.lgpd.accept_terms = this.userAgreeTerms
      localStorage.setItem('newUser', JSON.stringify(userCopy))
    },
    storeAddress() {
      localStorage.setItem('newAddress', JSON.stringify(this.address))
    },
    cleanData() {
      // if (this.$route.fullPath !== `totem${self.campaignHome}/user/register`) return
      localStorage.removeItem('newUser')
      localStorage.removeItem('newAddress')
    },
    onKeyPress(button) {
      console.log("onKeyPress", button, this.editVar)
      if (button === '{bksp}') {
        const newValue = this.keyboardSide === 'right' ? this.customer[this.editVar].slice(0, -1) : this.address[this.editVar].slice(0, -1)
        this.keyboardSide === 'right' ? this.customer[this.editVar] = newValue : this.address[this.editVar] = newValue
      } else if (button === '{space}') {
        this.keyboardSide === 'right' ? this.customer[this.editVar] += ' ' : this.address[this.editVar] += ' '
      } else if (button === '{tab}' || button === '{lock}' || button === '{shift}' || button === '{enter}') {
        return
      } else {
        console.log({ keyboardSide: this.keyboardSide, editVar: this.editVar })
        this.keyboardSide === 'right' ? this.customer[this.editVar] = this.insertOnObj(this.customer[this.editVar], button) : this.address[this.editVar] = this.insertOnObj(this.address[this.editVar], button)
      }
    },
    onFocus(input, side) {
      this.editVar = input;
      this.keyboardSide = side
      this.$forceUpdate();
      console.log("Focus", this.editVar);
    }
  },
};
</script>


<style  lang="scss" >
@import "~@/scss/colors.scss";

.painel-user-edit {
  .page-left {
    grid-area: left;
    padding: 35px;
    padding-bottom: 50px;
    background-color: var(--bg-color);
    height: 100%;
    // overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &.dark {
      background-color: var(--bg-color-dark);
    }

    .rules-conditions,
    .terms-conditions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        position: relative;
        top: 2vh;

        align-self: center;
        font-size: 0.95rem;
        font-weight: 500;

        strong {
          font-weight: 00;
        }
      }
    }

    form {
      padding: 0px 15px;
    }
  }

  .page-right {
    grid-area: right;
    padding: 35px;
    padding-bottom: 50px;
    background-color: var(--bg-color-dark);
    height: 100%;
    // overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    form {
      padding: 0px 15px;
    }

  }

  header {
    img.logo {
      // padding: 10px;
      height: 40px;
    }

    .campaign-info {
      width: 300px;
      float: right;
      text-align: right;
      margin-top: 10px;
    }
  }

  .campaign-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
      // max-height: 40vh;
    }

    button {
      bottom: 15px;
    }
  }

  .card {
    position: relative;
    background-color: var(--bg-color);
    box-shadow: $shadow-border;
    max-width: 90%;
    padding: 50px 20px;
    margin: auto;
    min-height: 548px;

    &.wide {
      max-width: 100%;

      display: grid;
      // grid-template-rows: 7.2vh 1fr 7vh;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        "left right"
        "clean back";

      .image {
        grid-area: left;
      }

      .info {
        grid-area: right;
      }

      .back {
        grid-area: back;

        .back-btn {
          border-radius: 0px;
          width: 230px;
          float: right;
          background-color: var(--secundary);
        }
      }
    }

    &.thin {
      max-width: 90%;
      padding: 0;
    }

    .image {
      width: 80%;
      max-width: 420px;
      min-height: 400px;
      background: var(--bg-color-dark);
      display: block;
      margin: auto;

      &.clean-bg {
        background: transparent
      }
    }


    .info {
      background: var(--bg-color-dark);
      padding: 10px 10px;
      font-size: 10pt !important;

      table {
        width: 100%;

        td {
          padding: 10px 0px;
          text-align: left;

          &.label {
            font-weight: bold;
            color: var(--primary);
          }
        }
      }
    }
  }

}
</style>

