const guard = function (to, from, next) {
  let path = encodeURI(window.location.pathname);
  if (localStorage.token) {
    next();
  } else {
    // window.open("./", "_self");
    // var pathList = path.split("/");
    // if(pathList.length > 3) {
    //   var _path = `./${pathList[1]}/${pathList[2]}/${pathList[3]}`
    //   window.open( _path + "/user/login?redirect="+path, "_self");
    // } else {
    //   window.open("./user/login?redirect="+path, "_self");
    // }

    let pathSplited = path.split("/")
    let shopping, campaign, totem = ''
    if (pathSplited[1] == 'totem') {
      shopping = pathSplited[2]
      campaign = pathSplited[3]
      totem = 'totem/'
    } else {
      shopping = pathSplited[1]
      campaign = pathSplited[2]
    }
    window.open(`${totem}${shopping}/${campaign}/user/login?redirect=${path}`, "_self")
  }
};

module.exports = {
  guard
}