<template>
  <layout class="totem-user-register">
    <div class="page-left" @click="keyboardSide = 'hide'">
      <div class="container">
        <header style="height: 70px;">
          <a :href="home">
            <img class="logo" v-if="campaign && campaign.company && campaign.company.image"
              :src="getImageUrl(campaign.company.image)" alt="" />
            <img v-else class="logo" src="~@/assets/images/logo-big.png" alt="" />
          </a>
          <div class="campaign-info">

          </div>
        </header>

        <div class="card">
          <div class="form-user" v-if="campaign.user_fields">
            <form @click.stop="">
              <ok-input :model="user.name" @updated="(x) => {
                user.name = x;
                storeUser();
              }
                " :label="'Nome*'" :id="'name'" :placeholder="' '" @onFocus="onFocus('name', 'right')">
              </ok-input>

              <ok-input :model="user.lastname" @updated="(x) => {
                user.lastname = x;
                storeUser();
              }
                " :label="'Sobrenome*'" :id="'lastname'" :placeholder="' '" @onFocus="onFocus('lastname', 'right')">
              </ok-input>

              <!-- <ok-input v-if="campaign.user_fields.birthday == 'REQUIRED' ||
                campaign.user_fields.birthday === 'LOGIN'
                " :model="user.birthday" @updated="(x) => {
    user.birthday = x;
    storeUser();
  }
    " :label="'Data de nascimento*'" :id="'birthday'" :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'" :isDate="true"
                @onFocus="onFocus('birthday', 'right')">
              </ok-input> -->

              <div class="input-frame">

                <input v-if="campaign.user_fields.birthday !== 'INACTIVE'" id="birthday" name="birthday"
                  placeholder="DD/MM/AAAA" v-model="user.birthday" v-on:focus="onFocus('birthday', 'right')"
                  v-mask="'##/##/####'" autocomplete="off" />
                <label for="birthday"><span>Data de nascimento{{ addAsterisk(campaign.user_fields.birthday)
                }}</span></label>
              </div>

              <ok-input v-if="campaign.user_fields.cpf !== 'INACTIVE'
                " :model="user.document" @updated="(x) => {
    user.document = x;
    storeUser();
  }
    " :label="'CPF' + addAsterisk(campaign.user_fields.cpf)" :id="'document'" :mask="'###.###.###-##'"
                :placeholder="'000.000.000-00'" @onFocus="onFocus('document', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.rg !== 'INACTIVE'" :model="user.document_rg" @updated="(x) => {
                user.document_rg = x;
                storeUser();
              }
                " :label="'RG' + addAsterisk(campaign.user_fields.rg)" :id="'document-rg'" :placeholder="' '"
                @onFocus="onFocus('document_rg', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.email !== 'INACTIVE'
                " :model="user.email" @updated="(x) => {
    user.email = x;
    storeUser();
  }
    " :label="'Email' + addAsterisk(campaign.user_fields.email)" :id="'email'" :placeholder="'nome@email.com'"
                @onFocus="onFocus('email', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.phone !== 'INACTIVE'
                " :model="user.phone" @updated="(x) => {
    user.phone = x;
    storeUser();
  }
    " :label="'Telefone' + addAsterisk(campaign.user_fields.phone)" :id="'phone'" :mask="'(##) #.####.####'"
                :placeholder="'(00) 0.0000-0000'" @onFocus="onFocus('phone', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.password !== 'INACTIVE'
                " :model="user.password" @updated="(x) => {
    user.password = x;
    storeUser();
  }
    " :label="'Senha' + addAsterisk(campaign.user_fields.password)" :id="'password'" :type="'password'"
                @onFocus="onFocus('password', 'right')">
              </ok-input>

              <ok-input v-if="campaign.user_fields.gender !== 'INACTIVE'" :model="user.gender" @updated="(x) => {
                user.gender = x;
                storeUser();
              }
                " :label="'Sexo' + addAsterisk(campaign.user_fields.gender)" :id="'gender'" :options="genderOptions"
                :placeholder="'Sexo'" @onFocus="onFocus('gender', 'right')">
              </ok-input>
              <div class="terms-conditions" v-if="campaign.lgpd.accept_emailContact">
                <label class="checkbox bounce">
                  <input :id="'email-contact'" type="checkbox" v-model="user.lgpd.accept_emailContact"
                    @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito receber informações por email. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_phoneContact">
                <label class="checkbox bounce">
                  <input :id="'phone-contact'" type="checkbox" v-model="user.lgpd.accept_phoneContact"
                    @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito receber informações por SMS. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_lgpd">
                <label class="checkbox bounce">
                  <input :id="'accept-lgpd'" type="checkbox" v-model="user.lgpd.accept_lgpd" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span>Aceito compartilhar minhas informações. </span>
              </div>

              <div class="terms-conditions" v-if="campaign.lgpd.accept_terms">
                <label class="checkbox bounce">
                  <input :id="'terms'" type="checkbox" v-model="userAgreeTerms" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span @click="openTerms()">
                  Li e concordo com os
                  <strong>termos de uso</strong>
                  e com a
                  <strong>politica de privacidade</strong>.
                </span>
              </div>

              <div class="rules-conditions" v-if="campaign.lgpd.accept_rules">
                <label class="checkbox bounce">
                  <input :id="'rules'" type="checkbox" v-model="userAgreeRules" @change="storeUser()" />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
                <span @click="openRules()">
                  Li e concordo com o
                  <strong>regulamento</strong>
                  da campanha.
                </span>
              </div>
            </form>

          </div>

        </div>

      </div>
    </div>
    <div class="page-right" @click="keyboardSide = 'hide'">
      <div class="container">
        <div style="height: 70px;"></div>
        <div class="card">
          <div class="form-user" v-if="campaign.user_fields">
            <form @click.stop="">

              <ok-input :model="address.zipcode" @updated="(x) => {
                address.zipcode = x;
                updateUserAddress();
                storeAddress();
              }
                " @onEnter="findAddress" @onBlur="findAddress"
                :label="'CEP' + addAsterisk(campaign.user_fields.address)" :id="'zipcode'" :mask="'##.###-###'"
                :placeholder="'00.000-000'" @onFocus="onFocus('zipcode', 'left')">
              </ok-input>

              <ok-input :model="address.street" @updated="(x) => {
                address.street = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'Logradouro' + addAsterisk(campaign.user_fields.address)" :id="'street'"
                :placeholder="'Rua ..'" @onFocus="onFocus('street', 'left')">
              </ok-input>

              <ok-input :model="address.number" @updated="(x) => {
                address.number = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'número' + addAsterisk(campaign.user_fields.address)" :id="'number'" :placeholder="'0'"
                @onFocus="onFocus('number', 'left')">
              </ok-input>

              <ok-input :model="address.complement" @updated="(x) => {
                address.complement = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'Complemento'" :id="'complement'" :placeholder="'complemento ...'"
                @onFocus="onFocus('complement', 'left')">
              </ok-input>

              <ok-input :model="address.district" @updated="(x) => {
                address.district = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'Bairro' + addAsterisk(campaign.user_fields.address)" :id="'district'" :placeholder="' '"
                @onFocus="onFocus('district', 'left')">
              </ok-input>

              <ok-input :model="address.city" @updated="(x) => {
                address.city = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'Cidade' + addAsterisk(campaign.user_fields.address)" :id="'city'" :placeholder="' '"
                @onFocus="onFocus('city', 'left')">
              </ok-input>

              <ok-input :model="address.state" @updated="(x) => {
                address.state = x;
                updateUserAddress();
                storeAddress();
              }
                " :label="'Estado' + addAsterisk(campaign.user_fields.address)" :id="'state'" :placeholder="' '"
                @onFocus="onFocus('state', 'left')">
              </ok-input>
            </form>
          </div>
        </div>

      </div>
    </div>
    <VirtualKeyboard @onKeyPress="onKeyPress" :inputId="editVar" :side="keyboardSide" />
    <popup-content :content="campaign" :title="'Regulamento'" @closed="isOpenRules = false" :isOpen="isOpenRules">
    </popup-content>
  </layout>
</template>


<script >
import Layout from "../layout/base";
import CampaignMixin from "../../../mixins/campaign.mixin.js";
import UserMixin from "../../../mixins/user.mixin.js";
// import FormUser from "../../../components/form-user.vue";
import OkInput from "../../../components/ok-input.vue";
import VirtualKeyboard from "../../../components/virtual-keyboard.vue";
import PopupContent from "../../../components/popup-content.vue";
// import PageInfo from "../../../views/campaign.vue";
// import CampaignMixin from "../mixins/campaign.mixin.js";


export default {
  mixins: [CampaignMixin, UserMixin],
  components: {
    Layout,
    // PageInfo
    // FormUser,
    VirtualKeyboard,
    PopupContent,
    OkInput
  },
  data() {
    return {
      home: "",
      subpage: "",
      isOpenRules: false,
      isOpenTerms: false,
      address: {
        zipcode: "",
        street: "",
        number: "",
        apto: "",
        district: "",
        state: "",
        complement: "",
        city: "",
        country: "",
      },
      userAgree: false,
      userAgreeTerms: false,
      userAgreeRules: false,
      genderOptions: [
        { name: "Masculino", value: "M" },
        { name: "Feminino", value: "F" },
        { name: "Outros", value: "O" },
        { name: "Não informar", value: "NI" },
      ],
      keyboardSide: 'hide',
      editVar: '',
    };
  },
  watch: {
    campaignHome(newValue) {
      this.home = `totem${newValue}`;
    },
    $route() {
      var hash = window.location.hash.substr(1);
      this.subpage = hash;
    },
    userAgreeTerms() {
      this.checkUserAgree();
    },
    userAgreeRules() {
      this.checkUserAgree();
    },
    user(newValue) {
      // console.log("User", newValue);

      this.userAgreeTerms = this.user.lgpd.accept_terms
      this.userAgreeRules = this.user.lgpd.accept_rules
      if (newValue.address && newValue.address[0]) {
        this.address = newValue.address[0];
      }
    },
    'address.zipcode': {
      handler(newValue) {
        // console.log(`Novo valor: ${newValue}, Valor antigo: ${oldValue}`);
        if (newValue.length == 10) {
          this.findAddress()
        }
      },
      deep: true
    },
    campaign(newValue) {
      console.log("Campaign", newValue);
    },
  },
  mounted: function () {
    var hash = window.location.hash.substr(1);
    this.subpage = hash;
    this.isOpenRules = false
  },
  methods: {
    checkUserAgree() {
      this.userAgree = true;
      if (this.campaign && this.campaign.lgpd.accept_rules) {
        if (!this.userAgreeRules) {
          this.userAgree = false;
        }
      }

      if (this.campaign && this.campaign.lgpd.accept_terms) {
        if (!this.userAgreeTerms) {
          this.userAgree = false;
        }
      }
    },
    openRules() {
      this.isOpenRules = true;
    },
    openTerms() {
      this.isOpenTerms = true;
    },

    findAddress() {
      let self = this;
      if (!this.address.zipcode || this.address.zipcode.length < 10) return
      this.getAddress(this.address.zipcode).then((response) => {
        if (response) {
          self.address.street = response.street;
          self.address.district = response.district;
          self.address.city = response.city;
          self.address.state = response.state;
        }

      });
    },

    updateUserAddress() {
      if (this.address.zipcode.length > 9) {
        // this.address.zipcode = this.address.zipcode.replace(/\D/g, "");
        if (!this.user.address) this.user.address = [];
        this.user.address[0] = this.address;
      }
    },
    storeUser() {
      let userCopy = JSON.parse(JSON.stringify(this.user));
      userCopy.lgpd.accept_rules = this.userAgreeRules
      userCopy.lgpd.accept_terms = this.userAgreeTerms
      localStorage.setItem('newUser', JSON.stringify(userCopy))
    },
    storeAddress() {
      localStorage.setItem('newAddress', JSON.stringify(this.address))
    },
    cleanData() {
      // if (this.$route.fullPath !== `totem${self.campaignHome}/user/register`) return
      localStorage.removeItem('newUser')
      localStorage.removeItem('newAddress')
    },
    onKeyPress(button) {
      console.log("onKeyPress", button, this.editVar)
      if (button === '{bksp}') {
        const newValue = this.keyboardSide === 'right' ? this.user[this.editVar].slice(0, -1) : this.address[this.editVar].slice(0, -1)
        this.keyboardSide === 'right' ? this.user[this.editVar] = newValue : this.address[this.editVar] = newValue
      } else if (button === '{space}') {
        this.keyboardSide === 'right' ? this.user[this.editVar] += ' ' : this.address[this.editVar] += ' '
      } else if (button === '{tab}' || button === '{lock}' || button === '{shift}' || button === '{enter}') {
        return
      } else {
        this.keyboardSide === 'right' ? this.user[this.editVar] = this.insertOnObj(this.user[this.editVar], button) : this.address[this.editVar] = this.insertOnObj(this.address[this.editVar], button)
      }
    },
    onFocus(input, side) {
      this.editVar = input;
      this.keyboardSide = side
      this.$forceUpdate();
      console.log("Focus", this.editVar);
    }
  },
};
</script>
<style  lang="scss" scoped>
@import "~@/scss/colors.scss";

.totem-user-register {

  .rules-conditions,
  .terms-conditions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      position: relative;
      top: 2vh;

      align-self: center;
      font-size: 0.95rem;
      font-weight: 500;

      strong {
        font-weight: 00;
      }
    }
  }

  form {
    padding: 0px 15px;
  }

  .page-left {
    grid-area: left;
    padding: 35px;
    padding-bottom: 50px;
    background-color: var(--bg-color-dark);
    //   height: 100%;
    //   overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .page-right {
    grid-area: right;
    padding: 35px;
    padding-bottom: 50px;
    background-color: var(--bg-color-dark);
    //   height: 100%;
    //   overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  header {
    img.logo {
      // padding: 10px;
      height: 40px;
    }

    .campaign-info {
      width: 300px;
      float: right;
      text-align: right;
      margin-top: 10px;
    }
  }

  .campaign-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
      // max-height: 40vh;
    }

    button {
      bottom: 15px;
    }
  }

  .card {
    background-color: var(--bg-color);
    box-shadow: $shadow-border;
    max-width: 90%;
    padding: 50px 20px;
    margin: auto;
    height: 100%;
    min-height: 548px;
  }
}
</style>