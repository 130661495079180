<template >
  <div :style="style">
    <div v-if="isLogged" class="container-tab-bar">
      <div class="tab-button" v-bind:class="{ 'selected': checkSelected('/cupons') }">
        <a :href="campaignCouponList">
          <span id="icon-coupom" class="icon-svg">
            <img src="../assets/icons/icon_coupom_white.svg" alt="" srcset="" />
          </span>
          <strong v-if="campaign">{{ typeName[campaign.type] }}</strong>
        </a>
      </div>

      <div class="tab-button" v-bind:class="{ 'selected': checkSelected('/camera') }"
        v-if="campaign.gears && campaign.gears.control && (campaign.gears.control.send_qrcode || campaign.gears.control.upload_image) && isCampaingActive">
        <a :href="campaignCamera">
          <span id="icon-qrcode2" class="icon-svg">
            <img src="../assets/icons/icon_qrcode.svg" alt="" srcset="" />
          </span>
          <strong>Enviar nota</strong>
        </a>
      </div>

      <div class="tab-button" v-bind:class="{ 'selected': checkSelected('/confirmar-compra') }"
        v-if="campaign.gears && campaign.gears.control && campaign.gears.control.has_wallet">
        <a :href="campaignBuy">
          <span id="icon-cards" class="icon-svg">
            <img src="../assets/icons/icon_cards.svg" alt="" srcset="" />
          </span>
          <strong>Comprar</strong>
        </a>
      </div>

      <div class="tab-button" v-bind:class="{ 'selected': checkSelected('/notas') }">
        <a :href="campaignBillList">
          <span id="icon-note" class="icon-svg">
            <img src="../assets/icons/icon_note_white.svg" alt="" srcset="" />
          </span>
          <strong>Histórico</strong>
        </a>
      </div>
    </div>

    <div v-if="layout.bottomLogin_show">
      <div v-if="
        !isLogged &&
        this.$route.path != '/user/login' &&
        this.$route.path != '/user/register'
      " class="container-tab-bar">
        <div>
          <a :href="userLogin">
            <h1>Logar</h1>
          </a>
        </div>
        <hr />
        <div>
          <a :href="userRegister">
            <h1>Criar uma Conta</h1>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignMixin from "../mixins/campaign.mixin.js";

export default {
  mixins: [CampaignMixin],
  props: {
    isLogged: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      home: "",
      userLogin: "",
      userEdit: "",
      userRegister: "",
      campaignCouponList: "",
      campaignCamera: "",
      campaignBuy: "",
      campaignBillList: "",
      typeName: {
        CULTURAL: "Cultural", // Concurso cultural
        SNAPSHOT: "Instantâneo", // Instantâneo
        GIFT: "Brindes",  // Brindes
        CUPOM: "Cupons",  // Cupons
      },
      selected: "",
      isCampaingActive: true,
    };
  },
  mounted: function () {
    // console.log(this.campaignHome);
    console.log('this.campaign', this.campaign)
    
    let endDate = new Date(this.campaign.date_end_bills)
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(endDate.getHours() + 3)
    console.log(new Date(), endDate)
    if (new Date() > endDate) this.isCampaingActive =  false
  },
  watch: {
    campaignHome(newValue) {
      this.campaignCouponList = `${newValue}/cupons`;

      if (this.campaign.gears.control.send_qrcode) {
        this.campaignCamera = `${newValue}/camera`;
      } else {
        this.campaignCamera = `${newValue}/camera-photo`;
      }
      this.campaignBuy = `${newValue}/comprar`;
      this.campaignBillList = `${newValue}/notas`;

      this.home = `${newValue}`;
      this.userLogin = `${newValue}/user/login`;
      this.userEdit = `${newValue}/user`;
      this.userRegister = `${newValue}/user/register`;
    },
    style(newValue, oldValue) {
      console.log(
        "Main layout - Mudança de estilo identificada",
        newValue,
        oldValue
      );
      this.$forceUpdate();
    },
  },
  methods: {
    checkSelected(item) {
      if (this.$route.fullPath.indexOf(item) > 0) {
        return true
      }
      return false
    }
  },
};
</script>

<style lang="scss" escoped>
@import "../scss/colors.scss";

#icon-coupom {
  mask: url("../assets/icons/icon_coupom_white.svg");
}

#icon-qrcode2 {
  mask: url("../assets/icons/icon_qrcode.svg");
}

#icon-cards {
  mask: url("../assets/icons/icon_cards.svg");
}

#icon-note {
  mask: url("../assets/icons/icon_note_white.svg");
}

.container-tab-bar {
  height: 80px;

  background-color: var(--bg-color-light);
  display: none;
  align-items: center;
  justify-content: center;

  position: fixed;
  width: 100%;
  bottom: 0;

  div {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  hr {
    height: 45%;
    background-color: black;
    width: 1px;
  }

  a {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 0.5vh;

    h1 {
      margin-top: 30px;
    }

    img {
      height: 3.7vh;
      width: auto;
      margin-bottom: 0.4vh;
    }

    strong {
      font-size: 0.75rem;
      font-weight: 800;
    }
  }

  .tab-button {
    // padding: 20px;
    padding-top: 12px;
    display: block;
    height: 80px;
    // display: grid;
    // width: auto;

    span.icon-svg {
      display: inline-block;
      position: relative;
      height: 100%;
      margin-bottom: 10px;
      background: var(--secundary);
      mask-size: contain !important;
      mask-repeat: no-repeat !important;
      mask-position: center !important;

      img {
        height: 100%;
        opacity: 0;
      }
    }

    // img{
    //   filter: invert(25%) sepia(20%) saturate(5786%) hue-rotate(273deg) brightness(92%) contrast(93%);
    // }
  }

  .selected {
    background-color: var(--secundary);

    span.icon-svg {
      background: white;
    }

    img {
      filter: none;
    }

    strong {
      color: white;
    }
  }

  a.qr-button {
    padding: 0 6vw;
    position: relative;
    bottom: 20px;

    strong {
      font-size: 0.8rem;
    }

    div {
      height: 8vh;
      width: 8vh;
      margin: auto;
      background-color: var(--secundary);
      border-radius: 100%;
      margin-bottom: 0.8vh;

      display: grid;
      place-items: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .container-tab-bar {
    display: flex;

    a:not(:last-child) {
      // margin-right: 10vw;
    }
  }
}
</style>