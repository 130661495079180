<template>
  <MainLayout :style="style">
    <div class="webapp-disabled">
      <h1>{{ text }}</h1>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";

export default {
  mixins: [userMixin, campaignMixin],
  components: {
    MainLayout,
  },
  data: function () {
    return {
      text: '',
    };
  },
  mounted() {
    if (!this.isDateValid()) this.text = 'Campanha não existe ou está fora do período de participação'
    else if (!this.campaign.gears.control.enable_webapp) this.text = 'Campanha não utiliza esta plataforma'
    else this.$router.push(`/${this.$route.params.shopping}/${this.$route.params.campanha}`)
  },
  methods: {
    isDateValid() {
      const dateStart = new Date(this.campaign.date_start)
      const endDate = new Date(this.campaign.date_end)
      endDate.setDate(endDate.getDate() + 1)
      endDate.setHours(endDate.getHours() + 3)
      const now = new Date()

      if (dateStart.getTime() < now.getTime() && endDate.getTime() > now.getTime()) {
        console.log('Dentro do funcionamento da campanha.')
        return true
      } else {
        console.log('Fora da data de funcionanmento da campanha.')
        return false
      }
    },

  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.webapp-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  height: calc(100vh - 140px);

  h1 {
    text-align: center;
    font-size: 20px;
  }
}
</style>