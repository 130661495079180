<template>
  <section :style="style">
    <div class="cupom-content totem-promotion-container">
      <div>
        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_cupom.is_enable">
          <h2>Números da sorte</h2>
          <span class="tickets-amounts">{{ cupomList.length }}</span>
          <div class="ticket-list">
            <p style="text-align: center;" v-if="campaign.gears.type_cupom.max_tickets_per_customer <= cupomList.length">
              Número máximo de tickets
              atingido</p>
            <div v-for="cupom of cupomList" :key="cupom._id">
              <TicketItem :ticket="cupom" :type="'CUPOM'" @ticketUpdated="loadTickets()" @click="openCupom(cupom)">
              </TicketItem>
            </div>
          </div>
        </div>

        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_gift.is_enable">
          <h2>Brinde</h2>
          <span class="tickets-amounts">{{ giftList.length }}</span>
          <div class="ticket-list">
            <p style="text-align: center;" v-if="campaign.gears.type_gift.max_gift_per_customer <= giftList.length">
              Número máximo de tickets
              atingido</p>
            <div v-for="gift of giftList" :key="gift._id">
              <TicketItem :ticket="gift" :type="'GIFT'" @ticketUpdated="loadTickets()" @click="openCupom(gift)">
              </TicketItem>
            </div>
          </div>
        </div>

        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_snapshot.is_enable">
          <h2>Brinde instantâneo</h2>
          <span class="tickets-amounts">{{ contemplatedTickets }}/{{ snapshotList.length }}</span>
          <div class="ticket-list">
            <p style="text-align: center;"
              v-if="campaign.gears.type_snapshot.max_gift_per_customer == snapshotList.length">
              Número máximo de tickets
              atingido</p>
            <TicketItem v-for="snapshot of snapshotList" :key="snapshot._id" :ticket="snapshot" :type="'SNAPSHOT'"
              @click="openCupom(snapshot)"></TicketItem>
          </div>
          <div class="ticket-list-empty" v-if="snapshotList.length == 0">
            {{ listMessage }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CampaignMixin from "@/mixins/campaign.mixin.js";
import userMixin from "@/mixins/user.mixin";
import TicketItem from "@/components/ticket-item.vue";

export default {
  mixins: [CampaignMixin, userMixin],
  components: {
    TicketItem,
  },
  data() {
    return {
      listMessage: "Carregando ...",
      typeName: {
        CULTURAL: "Cultural", // Concurso cultural
        SNAPSHOT: "Instantâneo", // Instantâneo
        GIFT: "Brindes",  // Brindes
        CUPOM: "Cupons",  // Cupons
      },
      ticketValue: 0,
      userBalance: 0,
      contemplatedTickets: 0,
      ticketList: [],
      cupomList: [],
      giftList: [],
      snapshotList: [],
    };
  },
  mounted: function () {
    this.loadTickets();
  },
  watch: {
    campaign(newValue) {
      this.ticketValue = this.campaign.gears.control.value
      console.log("Campaign", newValue);
    },
    user(newValue) {
      this.userBalance = this.user.balance
      console.log("user", newValue);
    },
  },
  methods: {
    loadTickets() {
      const self = this;

      this.getTickets().then((response) => {
        self.ticketList = response.ticket_list;
        this.listMessage = 'Você ainda não tem tickets'
        this.organizeTickets();
      });
    },
    openCupom(item) {
      console.log("openCupom", item);
      this.$emit("onSelected", item);
      // this.$router.push(this.campaignHome + "/cupom/" + cupom._id, "_self");
    },
    organizeTickets() {
      this.cupomList = []
      this.giftList = []
      this.snapshotList = []
      for (let ticket of this.ticketList) {
        if (ticket.type == 'CUPOM') {
          this.cupomList.push(ticket)
        } else if (ticket.type == 'GIFT') {
          this.giftList.push(ticket)
        } else if (ticket.type == "SNAPSHOT") {
          this.snapshotList.push(ticket)
        }
      }
      this.snapshotList.sort(function (a, b) { return b.contemplated - a.contemplated })
      this.contemplatedTickets = 0
      this.snapshotList.map((item) => {
        if (item.contemplated) this.contemplatedTickets++
      })
    }
  },
};
</script>

<style lang="scss">
@import "~@/scss/colors.scss";

.cupom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &>h1 {
    padding-top: 36vh;
  }

  h1.info {
    font-size: 20px;
    color: $primary-light;
  }
}

.status-content {
  padding: 20px;
  width: 100%;

  .box {
    background: #e8e8e8;
    padding: 15px;
    margin-bottom: 10px;

    border-radius: 10px;

    h3 {
      color: var(--primary);
      margin-bottom: 8px;
    }
  }

  .item-tatus {
    color: $text-color;
  }
}



.totem-promotion-container {
  display: block;
  //   align-items: flex-start;
  //   justify-content: center;
  //   flex-flow: row wrap;
  margin-bottom: 50px;
  margin-top: 20px;
  padding: 0px;
  gap: 40px;

  h2 {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary);
  }


  .ticket-type {
    h2 {
      display: inline-block;
    }

    .tickets-amounts {
      font-weight: bold;
      font-size: 20px;
      float: right;
      margin-bottom: 0px;
    }

    .ticket-list {
      display: flex;
      flex-flow: column wrap;
      gap: 10px;
    }
  }

  h2 {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .totem-promotion-container {
    .left {
      width: 100%;

      .balance {
        height: 70px;
      }
    }

    .right {
      width: 100%;
    }
  }
}
</style>