<template>
  <MainLayout>
    <div class="top-title">
      <h1>Pesquisa</h1>
    </div>
    <div class="survey-view" v-if="survey != null">
      <h2 class="survey-name">{{ survey.name }}</h2>
      <form onSubmit="return false;" v-if="answers && answers.length > 0">
        <div class="question" v-for="(question, index) of survey.questions" :key="question.title">
          <p class="title">{{ question.title }}</p>
          <div v-if="question.type == 'MULTIPLE'">
            <div class="radio-container" v-for="(option, optionIndex) of question.options" :key="option.title">
              <input type="radio" class="input-radio" :id="'question-' + index + '-option-' + optionIndex"
                :value="option.title" v-model="answers[index].answer_text" />
              <label :for="'question-' + index + '-option-' + optionIndex">{{ option.title }}</label>

              <div v-if="question.other_enable && question.options.length == optionIndex + 1">
                <ok-input :model="answers[index].answer_number" @updated="
                  (x) => {
                    answers[index].answer_number = x;
                  }
                " :label="'Resposta'" :placeholder="' '" :id="'other-answer'" style="margin-left: 20px;">
                </ok-input>
              </div>
            </div>
          </div>
          <div v-else>
            <ok-input v-if="question.answer_type == 'TEXT'" :model="answers[index].answer_text" @updated="
              (x) => {
                answers[index].answer_text = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" style="width: 80%;">
            </ok-input>
            <ok-input v-if="question.answer_type == 'NUMBER'" :model="answers[index].answer_number" @updated="
              (x) => {
                answers[index].answer_number = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :type="'number'"
              style="width: 80%;">
            </ok-input>
            <ok-input v-if="question.answer_type == 'DATE'" :model="answers[index].answer_date" @updated="
              (x) => {
                answers[index].answer_date = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :mask="'##/##/####'" :isDate="true"
              style="width: 80%;">
            </ok-input>
          </div>
        </div>
        <div style="width: 100%; text-align: center;">
          <button type="button" class="primary" @click="sendAnswer()">Confirmar</button>

        </div>
      </form>
    </div>
    <div v-else>
      Não há pesquisa para ser respondida
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import OkInput from "../components/ok-input.vue";
import campaignMixin from "../mixins/campaign.mixin.js";
import surveyMixin from "../mixins/survey.mixin.js";
export default {
  mixins: [campaignMixin, surveyMixin],
  components: {
    MainLayout,
    OkInput,
    // PopupQuestion,
    // WebCam,
  },
  data: function () {
    return {
      survey: {},
      answers: [],
      nextRoute: '',
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.user);
    this.getSurvey(user)
    if (localStorage.nextRoute) {
      this.nextRoute = localStorage.nextRoute
      localStorage.removeItem('nextRoute')
    }

  },
  watch: {
  },
  methods: {
    getSurvey(user) {
      let self = this;

      self.loadSurvey(this.campaign.survey[0]._id, user._id).then((res) => {
        if (res) {
          self.survey = res
          this.prepareAnswers()
        } else {
          self.survey = null
        }
      })
    },
    prepareAnswers() {
      this.answers = this.survey.questions.map(item => {
        console.log(item)
        let obj = {
          question: item._id,
          question_type: item.type,
          question_title: item.title,
          answer_type: item.answer_type,
          answer_text: "",
          answer_number: 0,
          answer_date: null,
          answer: '',
        }
        if (item.other_enable) item.options.push({ title: 'Outro' })
        return obj
      })
      console.log('aqui answers', this.answers)
    },
    sendAnswer() {
      const self = this
      const shopping = localStorage.shoppingSlug;
      const campaign = localStorage.campaignSlug;

      let answersCopy = JSON.parse(JSON.stringify(self.answers));
      const user = JSON.parse(localStorage.user)
      console.log('aqui user', user)

      answersCopy.forEach(element => {
        if (element.question_type == 'MULTIPLE' && element.answer_number != 0) {
          if (element.answer_text == 'Outro') element.answer_text = element.answer_number
          element.answer_number = 0
        }
      });

      const data = {
        survey_answer: {
          company: shopping,
          campaign,
          user: user._id,
          survey: self.survey._id,
          answers: answersCopy,
        },
      }
      console.log('PESQUISA DATA', data)
      this.sendSurvey(data)
        .then((response) => {
          if (response && response != undefined) {
            self.$toasted.show('Pesquisa enviada com sucesso', { type: 'success' })

            self.$router.push(self.nextRoute)
          }

        });
    },
  },
};
</script>

<style lang="scss" >
@import "../scss/colors.scss";

.survey-view {
  padding: 20px;
  width: 100%;

  form {
    align-items: flex-start;
    margin-bottom: 100px;
  }

  h1 {
    color: var(--secundary);
    font-size: 18px;
    font-weight: bold;
  }

  .survey-name {
    color: #464646;
    font-size: 20px;
    font-weight: normal;
  }

  .question {

    margin-top: 35px;
    width: 100%;

    .title {
      color: #6E6E6E;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .radio-container {
      display: flex;
      gap: 6px;
      align-items: center;

      .input-radio {
        width: 25px;
        height: 25px;
        accent-color: var(--primary);

      }

      label {
        color: #4B4B4B;
      }

    }

    .other-option {
      display: flex;
      gap: 25px;
    }

  }
}
</style>