<template>
  <section>
    <div class="note-list" v-if="campaign.gears && campaign.gears.control">
      <div class="totem-notes-content container">
        <h1 class="info" v-if="orders.length == 0">{{ listMessage }}</h1>

        <div v-if="orders.length > 0">
          <div v-for="order in orders" :key="order.numero" @click="openOrder(order)">
            <order-item :order="order"></order-item>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>

import CampaignMixin from "@/mixins/campaign.mixin.js";
import OrderMixin from "@/mixins/order.mixin.js";
import userMixin from "@/mixins/user.mixin";

import OrderItem from "@/components/order-item.vue";

import "@/filters/toCurrency";

export default {
  mixins: [CampaignMixin, userMixin, OrderMixin],
  components: {
    OrderItem
  },
  data() {
    return {
      tab: "bill",
      orders: [],
      listMessage: "Carregando",
      listMessagePayment: "Carregando"
    };
  },
  mounted: function () {
    let self = this
    let user = JSON.parse(localStorage.user)
    const data = {
      shopping: localStorage.shoppingSlug,
      campaign: localStorage.campaignSlug,
      user: user._id,
    };

    this.listBills(data).then((response) => {
      self.listMessage = "Você não possui notas";
      if (response) {
        self.orders = response;
      }
    }).catch(function () {
      self.listMessage = "Você não possui notas";
    });
  },
  methods: {
    openOrder(order) {
      // this.$router.push(this.campaignHome + "/nota-fiscal/" + order._id, "_self");
      this.$emit("onSelected", order);
    },
  }
};
</script>

<style lang="scss">
@import "~@/scss/colors.scss";

.note-list {
  position: relative;
  margin-bottom: 150px;
}

.totem-notes-content {
  height: 100%;
  padding: 20px 0px 0px 0px;
  margin-bottom: 50px;

  text-align: center;
  align-items: center;
  justify-content: space-evenly;

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }


}
</style>