<template>
  <header :style="style">
    <DevTopBar />
    <div class="container-header container">
      <a :href="home">
        <img class="logo" v-if="campaign && campaign.company && campaign.company.image"
          :src="getImageThumbUrl(campaign.company.image)" alt="" />
        <img v-else class="logo" src="../assets/images/logo-big.png" alt="" />
      </a>

      <div>
        <a class="icon" :href="home" v-if="layout.buttonHome_show">
          <span id="icon-home" class="icon-svg">
            <img src="../assets/icons/menu_icon_home.svg" alt="" srcset="" />
          </span>
          <strong>Home</strong>
        </a>

        <a class="icon" :href="userMenu">
          <span id="icon-user" class="icon-svg">
            <img src="../assets/icons/menu_icon_user.svg" alt="" srcset="" />
          </span>
          <strong>Perfil</strong>
        </a>

        <a class="icon desktop-only" :href="campaignCouponList">
          <span id="icon-tag" class="icon-svg">
            <img src="../assets/icons/menu_icon_tag.svg" srcset="../assets/icons/menu_icon_tag.svg" alt="" />
          </span>
          <strong v-if="campaign">{{ typeName[campaign.type] }}</strong>
        </a>

        <a class="icon desktop-only" :href="campaignBillList">
          <span id="icon-bill" class="icon-svg">
            <img src="../assets/icons/menu_icon_bill.svg" alt="" srcset="" />
          </span>
          <strong>Histórico</strong>
        </a>

        <a class="icon desktop-only" :href="campaignBuy" v-if="campaign.layout && campaign.layout.has_wallet">
          <span id="icon-cards" class="icon-svg">
            <img src="../assets/icons/icon_cards.svg" alt="" srcset="" />
          </span>
          <strong>Comprar</strong>
        </a>

        <a class="icon desktop-only qr-button" :href="campaignCamera"
          v-if="campaign.gears && campaign.gears.control && (campaign.gears.control.send_qrcode || campaign.gears.control.upload_image)">
          <span id="icon-qrcode2" class="icon-svg">
            <img src="../assets/icons/icon_qrcode.svg" alt="" srcset="" />
          </span>
          <strong style="margin-top: 19px; margin-left: 10px">Enviar Nota</strong>
        </a>

      </div>
    </div>
    <load-bar></load-bar>
  </header>
</template>

<script>
import LoadBar from "./load-bar.vue";
import DevTopBar from "./DevTopBar.vue";
import CampaignMixin from "../mixins/campaign.mixin.js";

export default {
  mixins: [CampaignMixin],
  name: "Header",
  components: {
    LoadBar,
    DevTopBar,
  },
  data: function () {
    return {
      mySVG: require("../assets/icons/menu_icon_home.svg"),
      home: "",
      userLogin: "",
      userMenu: "",
      campaignCouponList: "",
      campaignCamera: "",
      campaignBuy: "",
      campaignBillList: "",
      garbage: null,
      typeName: {
        CULTURAL: "Cultural", // Concurso cultural
        SNAPSHOT: "Instantâneo", // Instantâneo
        GIFT: "Brindes",  // Brindes
        CUPOM: "Cupons",  // Cupons
      },
    };
  },
  watch: {
    campaign(newValue, oldValue) {
      this.garbage = [newValue, oldValue];
      // console.log("Main layout - Campanha identificada", newValue, oldValue);
      this.$forceUpdate();
    },
    style(newValue, oldValue) {
      console.log(
        "Main layout - Mudança de estilo identificada",
        newValue,
        oldValue
      );
      this.$forceUpdate();
    },
    campaignHome(newValue) {
      this.home = `${newValue}`;
      this.userLogin = `${newValue}/user/login`;
      this.userMenu = `${newValue}/user/menu`;
      this.campaignCouponList = `${newValue}/cupons`;
      this.campaignCamera = `${newValue}/camera`;
      this.campaignBuy = `${newValue}/comprar`;
      this.campaignBillList = `${newValue}/notas`;
    },
  },
  mounted: function () {
    // console.log(this.campaignHome);
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" >
@import "../scss/colors.scss";

header {
  grid-area: header;
  position: relative;
  display: block;
  // padding: 3vw 3vw;
}

#icon-home {
  mask: url("../assets/icons/menu_icon_home.svg");
}

#icon-user {
  mask: url("../assets/icons/menu_icon_user.svg");
}

#icon-tag {
  mask: url("../assets/icons/menu_icon_tag.svg");
}

#icon-bill {
  mask: url("../assets/icons/menu_icon_bill.svg");
}

#icon-qrcode {
  mask: url("../assets/icons/menu_icon_qrcode.svg");
}

#icon-qrcode2 {
  mask: url("../assets/icons/icon_qrcode.svg");
}

.container-header {
  // min-height: 7.2vh;

  height: 100px;
  padding: 10px;
  position: relative;

  width: auto;
  min-height: 60px;

  display: flex;
  align-items: center;

  justify-content: space-between;
  background-color: var(--bg-color-light);
  border-bottom: 1px solid #aeaeae;

  a {
    height: 100%;

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      color: $text-color;
      opacity: 0.7;
      cursor: default;
    }

    img.logo {
      // padding: 10px;
      height: -webkit-fill-available;
    }

    &.icon {
      padding: 8px 15px;
      height: 70px;
      display: grid;
      grid-template-rows: 30px 1fr;
      row-gap: 10px;
      width: auto;
      margin-right: 10px;

      &:last-child {
        margin-right: 5px;
      }

      &.qr-button {
        display: inline-flex;
      }

      strong {
        font-size: 10pt;
        font-weight: bold;
      }

      .round-frame {
        display: block;
        background: var(--primary);
        padding: 10px;
        width: fit-content;
        margin-top: 0px;
        border-radius: 50%;
        margin-right: 5px;
      }

      span.icon-svg {
        display: inline-block;
        position: relative;
        height: 100%;
        background: var(--primary);
        mask-size: contain !important;
        mask-repeat: no-repeat !important;
        mask-position: center !important;

        img {
          height: 100%;
          opacity: 0;
        }
      }
    }
  }

  .nav-back {
    display: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 800px) {
  header {
    // position: fixed;
    z-index: 100;
    background: $bg-color;
    // width: 100%;

    .container-header {
      height: 60px;
      padding: 10px;
      position: relative;

      a.icon {
        padding: 8px;
        height: 40px;
        display: block;

        strong {
          display: none !important;
        }
      }
    }
  }
}
</style>