import VueRouter from 'vue-router'
import { publicPath } from '../../vue.config'

import { guard } from '../middlewares/guard.js';

import UserLogin from '../views/user-login.vue';
import UserForgotPassword from '../views/forgot-password.vue';
import UserEdit from '../views/user-edit.vue';
import UserCards from '../views/user-cards.vue';
import UserMenu from '../views/user-menu.vue';
import UserCardsRegister from '../views/user-cards-register.vue';
import UserRegister from '../views/user-register.vue';
import UserResetPassword from '../views/reset-password.vue';

import Campaign from '../views/campaign.vue';
import StoreList from '../views/store-list.vue';
import BillList from '../views/bill-list.vue';
import BillView from '../views/bill-view.vue';

import BillCamera from '../views/bill-camera.vue';
import BillRegister from '../views/bill-register.vue'
import BillQrCode from '../views/bill-qrcode.vue';

import ConfirmOrder from '../views/confirm-order.vue';
import BillQrCodeBuy from '../views/bill-qrcode-buy.vue';
import BillSuccessPay from '../views/bill-success-pay.vue';

import CouponList from '../views/coupon-list.vue';
import CouponView from '../views/coupon-view.vue';

import BillSuccess from '../views/bill-success.vue';

import SurveyView from '../views/survey-view.vue';

import WebappDisabled from '../views/webapp-disabled.vue';

// import QrCodeCheck from '../views/qrcode-tester.vue';

//totem
import HomeTotem from '../modules/totem/views/home.vue';
import UserRegisterTotem from '../modules/totem/views/user-register.vue';
import PainelUserEdit from '../modules/totem/views/painel-user-edit.vue';
import PainelTotem from '../modules/totem/views/painel.vue';

console.log(publicPath);
console.log(process.env.NODE_ENV);

export default new VueRouter({
    mode: 'history',
    base: publicPath.replace(".", ""),
    routes: [
        // { path: '/qrcode', component: QrCodeCheck },


        // { path: '/user', component: UserEdit, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        // { path: '/user/login', component: UserLogin },
        // { path: '/user/register', component: UserRegister },


        { path: '/:shopping/:campanha', component: Campaign, beforeEnter: verifyCampaign },
        { path: '/:shopping/:campanha/lojas', component: StoreList, beforeEnter: verifyCampaign },

        { path: '/:shopping/:campanha/comprar', component: BillQrCodeBuy },
        { path: '/:shopping/:campanha/confirmar-compra', name: 'confirmOrder', component: ConfirmOrder, props: true },
        { path: '/:shopping/:campanha/pagamento-sucesso', component: BillSuccessPay },

        { path: '/:shopping/:campanha/camera', component: BillQrCode, beforeEnter: (to, from, next) => { verifyQrCodeAndCamera(to, from, next); } },
        { path: '/:shopping/:campanha/camera-photo', component: BillCamera, beforeEnter: (to, from, next) => { verifyQrCodeAndCamera(to, from, next); } },
        { path: '/:shopping/:campanha/sucesso', component: BillSuccess },
        { path: '/:shopping/:campanha/cadastro-nota-fiscal/:id', component: BillRegister },

        { path: '/:shopping/:campanha/notas', component: BillList },
        { path: '/:shopping/:campanha/nota-fiscal/:id', component: BillView },

        { path: '/:shopping/:campanha/cupons', component: CouponList },
        { path: '/:shopping/:campanha/cupom/:id', component: CouponView },

        { path: '/:shopping/:campanha/pesquisa', component: SurveyView },

        { path: '/:shopping/:campanha/user', component: UserEdit, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        { path: '/:shopping/:campanha/user/menu', component: UserMenu, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        { path: '/:shopping/:campanha/user/cards', component: UserCards, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        { path: '/:shopping/:campanha/user/cards/register', component: UserCardsRegister, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        { path: '/:shopping/:campanha/user/login', component: UserLogin, beforeEnter: verifyCampaign },
        { path: '/:shopping/:campanha/user/forgot-password', component: UserForgotPassword, beforeEnter: verifyCampaign },
        { path: '/:shopping/:campanha/user/register', component: UserRegister, beforeEnter: verifyCampaign },
        { path: '/:shopping/:campanha/user/reset-password/:id', component: UserResetPassword, beforeEnter: verifyCampaign },

        { path: '/:shopping/:campanha/campanha-inativa', component: WebappDisabled },

        //totem
        { path: '/totem/:shopping/:campanha', component: HomeTotem },
        { path: '/totem/:shopping/:campanha/user/login', component: HomeTotem },
        { path: '/totem/:shopping/:campanha/user/register', component: UserRegisterTotem },

        { path: '/totem/:shopping/:campanha/painel', component: PainelTotem, beforeEnter: (to, from, next) => { guard(to, from, next); } },
        { path: '/totem/:shopping/:campanha/painel/profile', component: PainelUserEdit, beforeEnter: (to, from, next) => { guard(to, from, next); } },

        { path: '*', component: Campaign },
    ]
})

function verifyCampaign(to, from, next) {
    let campaign
    const path = encodeURI(window.location.pathname);
    if (localStorage.campanha) campaign = JSON.parse(localStorage.campanha)
    else next()
    const dateStart = new Date(campaign.date_start)
    const endDate = new Date(campaign.date_end)
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(endDate.getHours() + 3)
    const now = new Date();

    if (dateStart.getTime() < now.getTime() && endDate.getTime() > now.getTime() && campaign.gears.control.enable_webapp) {
        next()
    } else {
        var [base, shopping, campaign2] = path.split("/");
        console.log(base);
        next(`${shopping}/${campaign2}/campanha-inativa`)
    }
}

function verifyQrCodeAndCamera(to, from, next) {
    let urlSplited = to.fullPath.split('/')
    console.log({ urlSplited })
    let campaign
    const home = `${urlSplited[1]}/${urlSplited[2]}`

    if (localStorage.campanha) campaign = JSON.parse(localStorage.campanha)
    else next(home)

    if (urlSplited[urlSplited.length - 1] === 'camera' && !campaign?.gears?.control?.send_qrcode) next(home)
    else if (urlSplited[urlSplited.length - 1] === 'camera-photo' && !campaign?.gears?.control?.upload_image) next(home)
    else next()
}
