<template>
  <MainLayout :style="style">
    <div>
      <div class="top-title">
        <h1 v-if="campaign">{{ typeName[campaign.type] }}</h1>
        <h1 v-else>Meus status</h1>
      </div>
    </div>
    <div class="cupom-content promotion-container">
      <div class="left">
        <h2>Saldo</h2>
        <div class="balance">
          <input type="range" class="balance-slider" :min="0" :max="ticketValue" :value="currentBalance" disabled />
          <div class="colored-bar">
            <div class="left"></div>
            <div class="middle"></div>
            <div class="right"></div>
          </div>
          <div class="values">
            <div class="current-value">{{ currentBalance | toCurrency }}</div>
            <div class="ticket-value">{{ ticketValue | toCurrency }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_cupom.is_enable">
          <h2>Números da sorte</h2>
          <span class="tickets-amounts">{{ cupomList.length }}</span>
          <div class="ticket-list">
            <p style="text-align: center;"
              v-if="campaign.gears.type_cupom.max_tickets_per_customer <= cupomList.length">
              Número máximo de tickets
              atingido</p>
            <div v-for="cupom of cupomList" :key="cupom._id">
              <TicketItem :ticket="cupom" :type="'CUPOM'" @ticketUpdated="loadTickets()" @onPrint="openCupom(cupom)"
                @onExchange="openCupom(cupom)"></TicketItem>
            </div>
          </div>
        </div>

        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_gift.is_enable">
          <h2>Brinde</h2>
          <span class="tickets-amounts">{{ giftList.length }}</span>
          <div class="ticket-list">
            <p style="text-align: center;" v-if="campaign.gears.type_gift.max_gift_per_customer <= giftList.length">
              Número máximo de tickets
              atingido</p>
            <div v-for="gift of giftList" :key="gift._id">
              <TicketItem :ticket="gift" :type="'GIFT'" @ticketUpdated="loadTickets()" @onPrint="openCupom(gift)"
                @onExchange="openCupom(gift)"></TicketItem>
            </div>
          </div>
        </div>

        <div class="ticket-type" v-if="campaign && campaign.gears && campaign.gears.type_snapshot.is_enable">
          <h2>Brinde instantâneo</h2>
          <span class="tickets-amounts" v-if="campaign.gears.type_snapshot.type === 'SCRATCH_CARD'">{{
            interactedTickets
          }}/{{ snapshotList.length }}</span>
          <span class="tickets-amounts" v-else>{{ contemplatedTickets }}/{{ snapshotList.length }}</span>
          <!-- <button class="clean"> -->
          <font-awesome-icon icon="fas fa-sort" v-if="campaign.gears.type_snapshot.type === 'SCRATCH_CARD'"
            @click="sortSnapshot" style="margin-left: 12px;" />
          <!-- </button> -->
          <div class="ticket-list">
            <p style="text-align: center;"
              v-if="campaign.gears.type_snapshot.max_gift_per_customer == snapshotList.length">
              Número máximo de tickets
              atingido</p>
            <TicketItem v-for="snapshot of snapshotList" :key="snapshot._id" :ticket="snapshot" :type="'SNAPSHOT'"
              @ticketUpdated="loadTickets()"></TicketItem>
          </div>
          <div class="ticket-list-empty" v-if="snapshotList.length == 0">
            {{ listMessage }}
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import userMixin from "../mixins/user.mixin";
import TicketItem from "../components/ticket-item.vue";

export default {
  mixins: [CampaignMixin, userMixin],
  components: {
    MainLayout,
    TicketItem,
  },
  data() {
    return {
      listMessage: "Carregando ...",
      typeName: {
        CULTURAL: "Cultural", // Concurso cultural
        SNAPSHOT: "Instantâneo", // Instantâneo
        GIFT: "Brindes",  // Brindes
        CUPOM: "Cupons",  // Cupons
      },
      ticketValue: 0,
      userBalance: {
        balance_amount_free: 0,
        tickets_cupom_count: 0,
        tickets_gift_count: 0,
        tickets_snapshot_contemplated: 0,
        tickets_snapshot_count: 0,
      },
      currentBalance: 0,
      contemplatedTickets: 0,
      interactedTickets: 0,
      ticketList: [],
      cupomList: [],
      giftList: [],
      snapshotList: [],
      snapshotSortType: 'main'
    };
  },
  mounted: function () {
    this.loadTickets()
    this.loadBalance()
  },
  watch: {
    campaign(newValue) {
      this.ticketValue = this.campaign.gears.control.value
      console.log("Campaign", newValue);
    },
    // user(newValue) {
    //   this.userBalance = this.user.balance
    //   console.log("user", newValue);
    // },
  },
  methods: {
    loadTickets() {
      const self = this;

      this.getTickets().then((response) => {
        self.ticketList = response.ticket_list;
        this.listMessage = 'Você ainda não tem tickets'
        this.organizeTickets();
      });
    },
    openCupom(cupom) {
      this.$router.push(this.campaignHome + "/cupom/" + cupom._id, "_self");
    },
    organizeTickets() {
      this.cupomList = []
      this.giftList = []
      this.snapshotList = []

      for (let ticket of this.ticketList) {
        if (ticket.type == 'CUPOM') {
          this.cupomList.push(ticket)
        } else if (ticket.type == 'GIFT') {
          this.giftList.push(ticket)
        } else if (ticket.type == "SNAPSHOT") {
          this.snapshotList.push(ticket)
        }
      }
      // this.snapshotList.sort(function (a, b) { return b.contemplated - a.contemplated })
      this.contemplatedTickets = 0
      this.interactedTickets = 0
      this.snapshotList.forEach(item => {
        if (item.contemplated) this.contemplatedTickets++
        if (item.interacted) this.interactedTickets++
      })
      this.sortSnapshot(this.snapshotSortType, true)
    },
    sortSnapshot(type, isFromOrganize) {
      // console.log('entrou sortSnapshot', isFromOrganize, type)
      if (this.snapshotList.length == 0) return
      if (isFromOrganize && this.snapshotSortType === 'main') this.mainSortSnapshot()
      else if (isFromOrganize && this.snapshotSortType === 'date') this.dateSortSnapshot()
      else if (this.snapshotSortType === 'main' || type === 'main') this.dateSortSnapshot()
      else if (this.snapshotSortType === 'date' || type === 'date') this.mainSortSnapshot()
    },
    mainSortSnapshot() {
      // console.log('entrou MAIN')
      this.snapshotList.sort(function (a, b) { return b.contemplated - a.contemplated })
      this.snapshotList.sort(function (a, b) {
        if (b.status === 'EXCHANGED' && a.status !== 'EXCHANGED') {
          return -1;
        } else if (b.status !== 'EXCHANGED' && a.status === 'EXCHANGED') {
          return 1
        } else return 0
      })
      this.snapshotList.sort(function (a, b) { return a.interacted - b.interacted })
      this.snapshotSortType = 'main'
    },
    dateSortSnapshot() {
      // console.log('entrou DATE')
      this.snapshotList.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.snapshotSortType = 'date'
    },
    loadBalance() {
      const self = this
      const user = JSON.parse(localStorage.user);
      this.getUserBalance(user._id).then((response) => {
        self.userBalance = response.balance ? response.balance[0].balance : null

        self.currentBalance = self.userBalance.keep_residual_value ? self.userBalance.balance_with_residual : self.userBalance.balance_amount_free
      })
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.cupom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &>h1 {
    padding-top: 36vh;
  }

  h1.info {
    font-size: 20px;
    color: $primary-light;
  }
}

.status-content {
  padding: 20px;
  width: 100%;

  .box {
    background: #e8e8e8;
    padding: 15px;
    margin-bottom: 10px;

    border-radius: 10px;

    h3 {
      color: var(--primary);
      margin-bottom: 8px;
    }
  }

  .item-tatus {
    color: $text-color;
  }
}

.balance {
  position: relative;
  width: 100%;

  .values {
    position: absolute;
    top: 60px;
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .current-value {
      flex: 1;
      text-align: left;
      font-weight: bold;
    }

    .ticket-value {
      flex: 1;
      text-align: right;
    }
  }

  .colored-bar {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    z-index: 0;

    .left {
      background-color: #8dcd21;
      flex: 1;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .middle {
      background-color: #ffc00a;
      flex: 1;
    }

    .right {
      background-color: #ee2a7b;
      flex: 1;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .balance-slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 30px;
    /* Specified height */
    background: transparent;
    /* Grey background */
    outline: none;
    /* Remove outline */
    -webkit-transition: 0.2s;
    /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    border-radius: 25px;
    position: absolute;
    z-index: 1;
  }

  /* Mouse-over effects */
  .balance-slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .balance-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 12px;
    /* Set a specific slider handle width */
    height: 50px;
    /* Slider handle height */
    background: white;
    border: 1px solid black;
    /* Green background */
  }

  .balance-slider::-moz-range-thumb {
    width: 12px;
    height: 50px;
    background: white;
    border: 1px solid black;
  }
}

.promotion-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 80px;
  padding: 20px;
  gap: 40px;

  h2 {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary);
  }

  .left {
    width: calc(45% - 20px);
  }

  .right {
    width: calc(55% - 20px);
    order: 1;

    .ticket-type {
      h2 {
        display: inline-block;
      }

      .tickets-amounts {
        font-weight: bold;
        font-size: 20px;
        float: right;
        margin-bottom: 0px;
      }

      .ticket-list {
        display: flex;
        flex-flow: column wrap;
        gap: 10px;
      }
    }
  }

  h2 {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .promotion-container {
    .left {
      width: 100%;

      .balance {
        height: 70px;
      }
    }

    .right {
      width: 100%;
    }
  }
}
</style>