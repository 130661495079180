<template>
  <section>
    <div class="forgot-password-container">
      <form action="submit">
        <input type="text" placeholder="Informe seu Email cadastrado" v-model="$v.email.$model" :id="'email'" />

        <button type="button" class="primary" @click="forgot()">Enviar</button>

        <hr />

        <p class="question">Ainda não é cadastrado ?</p>

        <button type="button" class="primary_border" @click="register()">
          Criar conta
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import userMixin from "../mixins/user.mixin";

export default {
  mixins: [userMixin],
  data: function () {
    return {
      email: "",
      is_totem: false,
    };
  },
  validations: {
    email: { email, required },
  },
  mounted: function () {
    let path = encodeURI(window.location.pathname);
    this.is_totem = path.indexOf("totem") > -1;
  },
  methods: {
    forgot() {
      let path = encodeURI(window.location.pathname);
      var [base, totem, shopping, campaign] = path.split("/");
      console.log(base, totem);
      let data = {
        company: shopping,
        campaign,
        email: this.email,
      };
      this.forgotPassword(data);
    },
    register() {
      if (!this.is_totem) {
        window.open(
          `${this.shoppingSlug}/${this.campaignSlug}/user/register`,
          "_self"
        );
      } else {
        window.open(
          `totem/${this.shoppingSlug}/${this.campaignSlug}/user/register`,
          "_self"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    margin-top: 7vh;
    width: 80%;

    hr {
      margin: 6vh 0;
    }

    button {
      width: 160px;

      &.clean {
        font-size: 0.9rem;
      }
    }
  }
}
</style>