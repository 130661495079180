var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{style:(_vm.style)},[(_vm.campaign)?_c('div',{staticClass:"user-card-register-container"},[_c('div',{staticClass:"top-title"},[_c('h1',[_vm._v("Cadastrar cartão")])]),_c('form',[_c('ok-input',{attrs:{"model":_vm.card.number,"label":'Número do cartão',"mask":'#### #### #### ####',"placeholder":'0000 0000 0000 0000'},on:{"updated":function (x) {
            _vm.card.number = x;
          }}}),_c('ok-input',{attrs:{"model":_vm.card.holder_name,"label":'Nome completo',"placeholder":' '},on:{"updated":function (x) {
            _vm.card.holder_name = x;
          }}}),_c('div',{staticStyle:{"display":"flex"}},[_c('ok-input',{staticClass:"small-input",attrs:{"model":_vm.card.exp,"label":'Validade',"mask":'##/##',"placeholder":'MM/AA'},on:{"updated":function (x) {
              _vm.card.exp = x;
            }}}),_c('ok-input',{staticClass:"small-input",attrs:{"model":_vm.card.security_code,"label":'CVV',"placeholder":' '},on:{"updated":function (x) {
              _vm.card.security_code = x;
            }}})],1)],1),_c('form',{staticClass:"button-area",staticStyle:{"padding":"20px"}},[_c('button',{staticClass:"gray",staticStyle:{"width":"100%"},attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v(" Voltar ")]),_c('div',{staticStyle:{"width":"35px"}}),_c('button',{staticClass:"primary",staticStyle:{"width":"100%"},attrs:{"type":"button"},on:{"click":_vm.save}},[_vm._v(" Cadastrar ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }