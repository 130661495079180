<template>
  <MainLayout :style="style">
    <div class="user-edit-container">
      <div class="top-title">
        <h1>Criar Cadastro</h1>
      </div>

      <span>Informe seus dados abaixo</span>

      <form v-if="campaign && campaign.user_fields">
        <ok-input :model="user.name" @updated="(x) => {
          user.name = x;
        }
          " :label="'Nome*'" :id="'name'" :placeholder="' '">
        </ok-input>

        <ok-input :model="user.lastname" @updated="(x) => {
          user.lastname = x;
        }
          " :label="'Sobrenome*'" :id="'lastname'" :placeholder="' '">
        </ok-input>

        <ok-input v-if="campaign.user_fields.birthday !== 'INACTIVE'" :model="user.birthday" @updated="(x) => {
          user.birthday = x;
        }
          " :label="'Data de nascimento' + addAsterisk(campaign.user_fields.birthday)" :id="'birthday'"
          :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'" :isDate="true">
        </ok-input>

        <ok-input v-if="campaign.user_fields.cpf !== 'INACTIVE'" :model="user.document" @updated="(x) => {
          user.document = x;
        }
          " :label="'CPF' + addAsterisk(campaign.user_fields.cpf)" :id="'document'" :mask="'###.###.###-##'"
          :placeholder="'000.000.000-00'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.rg !== 'INACTIVE'" :model="user.document_rg" @updated="(x) => {
          user.document_rg = x;
        }
          " :label="'RG' + addAsterisk(campaign.user_fields.rg)" :id="'document-rg'" :placeholder="' '">
        </ok-input>

        <ok-input v-if="campaign.user_fields.email !== 'INACTIVE'" :model="user.email" @updated="(x) => {
          user.email = x;
        }
          " :label="'Email' + addAsterisk(campaign.user_fields.email)" :id="'email'" :placeholder="'nome@email.com'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.phone !== 'INACTIVE'" :model="user.phone" @updated="(x) => {
          user.phone = x;
        }
          " :label="'Telefone' + addAsterisk(campaign.user_fields.phone)" :id="'phone'" :mask="'(##) #.####.####'"
          :placeholder="'(00) 0.0000-0000'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.password !== 'INACTIVE'" :model="user.password" @updated="(x) => {
          user.password = x;
        }
          " :label="'Senha' + addAsterisk(campaign.user_fields.password)" :id="'password'" :type="'password'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.gender !== 'INACTIVE'" :model="user.gender" @updated="(x) => {
          user.gender = x;
        }
          " :label="'Gênero' + addAsterisk(campaign.user_fields.gender)" :id="'gender'" :options="genderOptions"
          :placeholder="'Gênero'">
        </ok-input>

      </form>

      <div v-else>Carregando campanha....</div>

      <form v-if="campaign && campaign.user_fields && campaign.user_fields.address !== 'INACTIVE'">
        <hr />
        <h2>Endereço</h2>

        <ok-input :model="address.zipcode" @updated="(x) => {
          address.zipcode = x;
        }
          " @onEnter="findAddress" @onBlur="findAddress" :label="'CEP' + addAsterisk(campaign.user_fields.address)"
          :id="'zipcode'" :mask="'##.###-###'" :placeholder="'00.000-000'">
        </ok-input>

        <ok-input :model="address.street" @updated="(x) => {
          address.street = x;
        }
          " :label="'Logradouro' + addAsterisk(campaign.user_fields.address)" :id="'street'" :placeholder="'Rua ..'">
        </ok-input>

        <ok-input :model="address.number" @updated="(x) => {
          address.number = x;
        }
          " :label="'número' + addAsterisk(campaign.user_fields.address)" :id="'number'" :placeholder="'0'">
        </ok-input>

        <ok-input :model="address.apto" @updated="(x) => {
          address.apto = x;
        }
          " :label="'Complemento'" :id="'apto'" :placeholder="'apto ...'">
        </ok-input>

        <ok-input :model="address.district" @updated="(x) => {
          address.district = x;
        }
          " :label="'Bairro' + addAsterisk(campaign.user_fields.address)" :id="'district'" :placeholder="' '">
        </ok-input>

        <ok-input :model="address.city" @updated="(x) => {
          address.city = x;
        }
          " :label="'Cidade' + addAsterisk(campaign.user_fields.address)" :id="'city'" :placeholder="' '">
        </ok-input>

        <ok-input :model="address.state" @updated="(x) => {
          address.state = x;
        }
          " :label="'Estado' + addAsterisk(campaign.user_fields.address)" :id="'state'" :placeholder="' '">
        </ok-input>
      </form>

      <form v-if="campaign && campaign.lgpd && user.lgpd">
        <div class="terms-conditions" v-if="campaign.lgpd.accept_emailContact">
          <label class="checkbox bounce">
            <input :id="'email-contact'" type="checkbox" v-model="user.lgpd.accept_emailContact" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </label>
          <span>Aceito receber informações por email. </span>
        </div>

        <div class="terms-conditions" v-if="campaign.lgpd.accept_phoneContact">
          <label class="checkbox bounce">
            <input :id="'phone-contact'" type="checkbox" v-model="user.lgpd.accept_phoneContact" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </label>
          <span>Aceito receber informações por SMS. </span>
        </div>

        <div class="terms-conditions" v-if="campaign.lgpd.accept_lgpd">
          <label class="checkbox bounce">
            <input :id="'accept-lgpd'" type="checkbox" v-model="user.lgpd.accept_lgpd" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </label>
          <span>Aceito compartilhar minhas informações. </span>
        </div>

        <!-- <div class="terms-conditions" v-if="campaign.lgpd.accept_terms">
          <label class="checkbox bounce">
            <input :id="'terms'" type="checkbox" v-model="userAgreeTerms" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </label>
          <span @click="openTerms()">Li e concordo com os
            <strong>termos de uso</strong>
            e com a
            <strong>politica de privacidade</strong>.
          </span>
        </div> -->

        <div class="rules-conditions" v-if="campaign.lgpd.accept_rules">
          <label class="checkbox bounce">
            <input :id="'rules'" type="checkbox" v-model="userAgreeRules" />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
            </svg>
          </label>
          <span @click="openRules()">Li e concordo com o
            <strong>regulamento</strong>
            da campanha.
          </span>
        </div>

        <br />
        <br />
        <button type="button" class="primary" @click="save" :disabled="!userAgree || isSending">
          Cadastrar
        </button>
      </form>
    </div>
    <!-- <popup-content v-if="campaign && campaign.terms" :content="campaign.terms" :title="'Termos e condições'"
      @closed="isOpenTerms = false" :isOpen="isOpenTerms"></popup-content> -->
    <!-- 
    <popup-content v-if="campaign && campaign" :content="campaign.rules" :title="'Regulamento'"
      @closed="isOpenRules = false" :isOpen="isOpenRules"></popup-content> -->

    <popup-content :content="campaign" :title="'Regulamento'" @closed="isOpenRules = false" :isOpen="isOpenRules">
    </popup-content>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";
import surveyMixin from "../mixins/survey.mixin";
import OkInput from "../components/ok-input.vue";
import PopupContent from "../components/popup-content.vue";

export default {
  mixins: [userMixin, campaignMixin, surveyMixin],
  components: {
    MainLayout,
    OkInput,
    PopupContent,
  },
  data() {
    return {
      isOpenRules: false,
      isOpenTerms: false,
      address: {
        zipcode: "",
        street: "",
        number: "",
        apto: "",
        district: "",
        state: "",
        complement: "",
        city: "",
        country: "",

      },
      userAgree: false,
      userAgreeTerms: false,
      userAgreeRules: false,
      genderOptions: [
        { name: "Masculino", value: "M" },
        { name: "Feminino", value: "F" },
        { name: "Outros", value: "O" },
        { name: "Não informar", value: "NI" },
      ],
    };
  },
  watch: {
    userAgreeTerms() {
      this.checkUserAgree();
    },
    userAgreeRules() {
      this.checkUserAgree();
    },
    user(newValue) {
      // console.log("User", newValue);
      if (newValue.address && newValue.address[0]) {
        this.address = newValue.address[0];
      }
    },
    campaign(newValue) {
      console.log("Campaign", newValue);
    },
  },
  methods: {
    checkUserAgree() {
      this.userAgree = true;
      if (this.campaign && this.campaign.lgpd.accept_rules) {
        if (!this.userAgreeRules) {
          this.userAgree = false;
        }
      }

      if (this.campaign && this.campaign.lgpd.accept_terms) {
        if (!this.userAgreeTerms) {
          this.userAgree = false;
        }
      }
    },
    openRules() {
      console.log("openRules");
      this.isOpenRules = true;
    },
    openTerms() {
      console.log("openTerms");
      this.isOpenTerms = true;
    },
    save() {
      let self = this;

      if (this.address.zipcode.length > 9) {
        this.address.zipcode = this.address.zipcode.replace(/\D/g, "");
        if (!this.user.address) this.user.address = [];
        this.user.address[0] = this.address;
      }

      const validateUserData = this.validateForm(this.campaign);
      if (!validateUserData.validated) {
        this.$swal({
          icon: "error",
          title: "",
          text: validateUserData.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return null;
      }

      this.user.lgpd.accept_rules = this.userAgreeRules
      this.user.lgpd.accept_terms = this.userAgreeTerms
      const userToSend = JSON.parse(JSON.stringify(this.user))

      this.isSending = true
      this.createUser({
        user: userToSend,
        shopping: this.campaign.company.slug,
        campaign: this.campaign.slug,
      }).then((response) => {
        console.log("Save user", response);
        self.isSending = false
        if (response && response != undefined) {
          let path = self.campaignHome
          if (self.showSuccess(self.campaign, 'AFTER_USER_REGISTER').includes('survey')) {
            self.isSending = true
            console.log('AUI ESTA ANTES DO LOADSURVEY', self.campaign, response)
            self.loadSurvey(self.campaign.survey[0]._id, response._id).then((res) => {

              self.isSending = false
              if (res) {
                localStorage.setItem('nextRoute', path)
                path = path + '/pesquisa'
              }
              self.$router.push(path)
            })
          } else {
            self.$router.push(path)
          }
        }
      });

    },
    findAddress() {
      let self = this;
      this.getAddress(this.address.zipcode).then((response) => {
        self.address.street = response.street;
        self.address.district = response.district;
        self.address.city = response.city;
        self.address.state = response.state;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.user-edit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;

  .top-title {
    margin-bottom: 40px;
  }

  span {
    text-align: left;
    width: 80%;
    max-width: 420px;
    margin-bottom: 15px;
  }

  // padding: 20px;

  form {
    width: 100%;
    padding: 20px;
    padding-top: 0px;

    .rules-conditions,
    .terms-conditions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        position: relative;
        top: 2vh;

        align-self: center;
        font-size: 0.95rem;
        font-weight: 500;

        strong {
          font-weight: 00;
        }
      }
    }

    button {
      margin-top: 1vh;

      &:disabled {
        background: #ccc !important;
        cursor: not-allowed;
      }
    }
  }
}
</style>