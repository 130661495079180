<template>
  <div class="painel-rating">
    <h2>Pesquisa</h2>
    <div class="card" v-if="(campaign && campaign.gears && campaign.gears.control.send_store) || campaign.gears.control.send_seller">
      <div class="description">
        <h2>Avaliação do atendimento</h2>
        <p style="margin-top: 6px">Avalie a loja e o vendedor que lhe atendeu.</p>
      </div>

      <div class="rating-box">
        <form v-if="campaign && campaign.gears && campaign.gears.control.send_store">
          <ok-input :model="send_store" @updated="
            (x) => {
              send_store = x;
            }
          " :options="storeListNames" @selected="getUsers()" :label="'Loja'" :placeholder="' '" :id="'store'">
          </ok-input>

          <div class="star-frame" v-if="campaign && campaign.gears && campaign.gears.control.rate_store">
            <i class="star" :class="{ 'active': rate_store > 0 }" @click="rate_store = 1"></i>
            <i class="star" :class="{ 'active': rate_store > 1 }" @click="rate_store = 2"></i>
            <i class="star" :class="{ 'active': rate_store > 2 }" @click="rate_store = 3"></i>
            <i class="star" :class="{ 'active': rate_store > 3 }" @click="rate_store = 4"></i>
            <i class="star" :class="{ 'active': rate_store > 4 }" @click="rate_store = 5"></i>
          </div>

        </form>
        <form v-if="campaign && campaign.gears && campaign.gears.control.send_seller">

          <ok-input :model="send_seller" @updated="
            (x) => {
              send_seller = x;
            }
          " :options="userListNames" :label="'Vendedor'" :placeholder="' '" :id="'seller'">
          </ok-input>

          <div class="star-frame" v-if="campaign && campaign.gears.control.rate_seller">
            <i class="star" :class="{ 'active': rate_seller > 0 }" @click="rate_seller = 1"></i>
            <i class="star" :class="{ 'active': rate_seller > 1 }" @click="rate_seller = 2"></i>
            <i class="star" :class="{ 'active': rate_seller > 2 }" @click="rate_seller = 3"></i>
            <i class="star" :class="{ 'active': rate_seller > 3 }" @click="rate_seller = 4"></i>
            <i class="star" :class="{ 'active': rate_seller > 4 }" @click="rate_seller = 5"></i>
          </div>
        </form>
      </div>
    </div>
    <div class="card survey" style="margin-top: 35px;" v-if="survey != null">
      <h2 class="survey-name">{{ survey.name }}</h2>
      <form onSubmit="return false;" v-if="answers && answers.length > 0">
        <div class="question" v-for="(question, index) of survey.questions" :key="question.title">
          <p class="title">{{ question.title }}</p>
          <div v-if="question.type == 'MULTIPLE'">
            <div class="radio-container" v-for="(option, optionIndex) of question.options" :key="option.title">
              <input type="radio" class="input-radio" :id="'question-' + index + '-option-' + optionIndex"
                :value="option.title" v-model="answers[index].answer_text" />
              <label :for="'question-' + index + '-option-' + optionIndex">{{ option.title }}</label>

              <div v-if="question.other_enable && question.options.length == optionIndex + 1">
                <ok-input :model="answers[index].answer_number" @updated="
                  (x) => {
                    answers[index].answer_number = x;
                  }
                " :label="'Resposta'" :placeholder="' '" :id="'other-answer'" style="margin-left: 20px;">
                </ok-input>
              </div>
            </div>
          </div>
          <div v-else>
            <ok-input v-if="question.answer_type == 'TEXT'" :model="answers[index].answer_text" @updated="
              (x) => {
                answers[index].answer_text = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" style="width: 80%;">
            </ok-input>
            <ok-input v-if="question.answer_type == 'NUMBER'" :model="answers[index].answer_number" @updated="
              (x) => {
                answers[index].answer_number = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :type="'number'"
              style="width: 80%;">
            </ok-input>
            <ok-input v-if="question.answer_type == 'DATE'" :model="answers[index].answer_date" @updated="
              (x) => {
                answers[index].answer_date = x;
              }
            " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :mask="'##/##/####'" :isDate="true"
              style="width: 80%;">
            </ok-input>
          </div>
        </div>
      </form>
    </div>
    <button class="confirm-btn" @click="send()">Confirmar</button>
  </div>
</template>


<script>
import CampaignMixin from "@/mixins/campaign.mixin.js";
import CompanyMixin from "@/mixins/company.mixin.js";
import UserMixin from "@/mixins/user.mixin.js";
import OkInput from "@/components/ok-input.vue";
import surveyMixin from "@/mixins/survey.mixin.js";

export default {
  mixins: [CampaignMixin, CompanyMixin, UserMixin, surveyMixin],
  components: {
    OkInput
  },
  props: {
    page: {
      type: String,
      default: "register",
      required: false
    },
  },
  data() {
    return {
      send_store: null,
      rate_store: null,
      send_seller: null,
      rate_seller: null,
      newSeller: '',
      addNewSelller: false,
      storeList: [],
      storeListNames: [],
      userList: [],
      userListNames: [],
      bill: {},
      survey: null,
      answers: [],
    };
  },
  mounted() {
    this.getStores()
    this.getUsers()

    const user = JSON.parse(localStorage.user)
    this.getSurvey(user)

  },
  methods: {
    getStores() {
      let self = this;

      this.listStores().then((response) => {
        self.storeList = response
        self.storeListNames = response.map(item => {
          let obj = {
            name: item.name_fancy,
            value: item._id,
          }
          return obj
        })

        if (localStorage.selectedStore && this.campaign.gears.control.send_store) this.send_store = localStorage.selectedStore
      })
    },
    getUsers() {
      const self = this
      const data = {
        shopping: localStorage.shoppingSlug,
        campaign: localStorage.campaignSlug,
      };

      if (this.send_store) {
        data.parent = this.send_store
      }

      this.listUsers(data, 'SALESMAN').then((response) => {
        self.userList = response.user_list
        self.userListNames = []
        for (let user of self.userList) {
          let name = user.name + ' ' + user.lastname
          if (user.salesman_code) name = user.salesman_code + ' - ' + name
          self.userListNames.push({
            name: name,
            value: user._id,
          });
        }
      })
    },

    getSurvey(user) {
      let self = this;
      let survey
      console.log(user, self.campaign)
      self.campaign.survey.forEach(element => {
        if (element.is_enable) {
          survey = element
          return
        }
      });
      if (!survey || survey.whenever != 'AFTER_INVOICE_REGISTER') return
      self.loadSurvey(survey._id, user._id).then((res) => {
        console.log({ survey, user: user._id, res })
        if (res) {
          self.survey = res
          self.prepareAnswers()
        } else {
          self.survey = null
        }
        console.log({ survey: self.survey, res })
      })
    },
    prepareAnswers() {
      this.answers = this.survey.questions.map(item => {
        console.log(item)
        let obj = {
          question: item._id,
          question_type: item.type,
          question_title: item.title,
          answer_type: item.answer_type,
          answer_text: "",
          answer_number: 0,
          answer_date: null,
          answer: '',
        }
        if (item.other_enable) item.options.push({ title: 'Outro' })
        return obj
      })
    },
    send() {
      if (this.survey != null) this.sendAnswer()
      else if (this.campaign.gears.control.send_seller || this.campaign.gears.control.send_store) this.evaluate()

    },
    sendAnswer() {
      const self = this
      const shopping = localStorage.shoppingSlug;
      const campaign = localStorage.campaignSlug;

      let answersCopy = JSON.parse(JSON.stringify(self.answers));
      const user = JSON.parse(localStorage.user)

      answersCopy.forEach(element => {
        if (element.question_type == 'MULTIPLE' && element.answer_number != 0) {
          if (element.answer_text == 'Outro') element.answer_text = element.answer_number
          element.answer_number = 0
        }
      });

      const data = {
        survey_answer: {
          company: shopping,
          campaign,
          user: user._id,
          survey: self.survey._id,
          answers: answersCopy,
        },
      }

      this.sendSurvey(data)
        .then((response) => {
          if (response && response != undefined) {
            self.$toasted.show('Pesquisa enviada com sucesso', { type: 'success' })
            if (self.campaign.gears.control.send_seller || self.campaign.gears.control.send_store) {
              self.evaluate()
            } else {

              window.open('totem' + self.campaignHome + '/painel', "_self")
            }
          }

        });
    },
    evaluate() {
      this.sendEvaluation(this.send_store, this.rate_store, this.send_seller, this.rate_seller)
        .then((response) => {
          if (response && response != undefined) {
            window.open('totem' + this.campaignHome + '/painel', "_self")
          }

        });
    }
  },
};
</script> 

<style lang="scss">
@import "@/scss/colors.scss";

.painel-rating {
  margin-bottom: 80px;

  h2 {
    color: var(--text-color);
    font-weight: bold;
    font-size: 16pt;
    margin-bottom: 20px;
  }

  .card {
    position: relative;
    background-color: var(--bg-color);
    box-shadow: $shadow-border;
    max-width: 100%;
    padding: 30px;
    margin: auto;
  }

  .survey {
    form {
      align-items: flex-start;
    }

    .question {
      margin-top: 35px;
      width: 100%;



      .title {
        color: #6E6E6E;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }

      .radio-container {
        display: flex;
        gap: 6px;
        align-items: center;

        .input-radio {
          width: 25px;
          height: 25px;
          accent-color: var(--primary);

        }

        label {
          color: #4B4B4B;
        }
      }

      .other-option {
        display: flex;
        gap: 25px;
      }
    }
  }

  .rating-box {
    display: flex;
    flex-flow: row wrap;
    gap: 25px;
    margin-top: 32px;

    >form {
      flex: 1;
      align-items: flex-end;
    }

    .star-frame {
      width: fit-content;
      height: 40px;

      .star {
        width: 25px;
        height: 25px;
        display: inline-block;
        border-radius: 50%;
        background: #ddd;
        cursor: pointer;
        margin: 0px 2px;

        &.active {
          background: var(--primary);
        }
      }
    }
  }

  .confirm-btn {
    background-color: var(--secundary);
    width: 200px;
    height: 50px;
    position: fixed;
    right: 30px;
    bottom: 100px;
  }

}
</style>