<template>
  <MainLayout :style="style">
    <div class="user-menu-container" v-if="campaign">
      <div class="top-title">
        <h1>Usuário</h1>
      </div>
      <ul class="user-menu-list">
        <li>
          <button class="menu-btn" @click="goTo(userEdit)">
            <span id="icon-menu-user" class="icon-svg">
              <img src="../assets/icons/user-menu-user.svg" alt="" srcset="" />
            </span>
            Meus dados
          </button>
        </li>
        <li v-if="campaign.gears && campaign.gears.control && campaign.gears.control.has_wallet">
          <button class="menu-btn" @click="goTo(creditCards)">
            <span id="icon-menu-cards" class="icon-svg">
              <img src="../assets/icons/user-menu-cards.svg" alt="" srcset="" />
            </span>
            Cartões de crédito
          </button>
        </li>
        <li v-if="campaign.gears && campaign.gears.control && campaign.gears.control.has_wallet">
          <button class="menu-btn" @click="goTo(notes)">
            <span id="icon-menu-history" class="icon-svg">
              <img src="../assets/icons/user-menu-cards.svg" alt="" srcset="" />
            </span>
            Histórico
          </button>
        </li>
        <li>
          <button class="menu-btn grey" type="button" @click="exit">
            <span id="icon-menu-exit" class="icon-svg">
              <img src="../assets/icons/user-menu-exit.svg" alt="" srcset="" />
            </span>
            Desconectar
          </button>
        </li>
      </ul>
      <p style="font-size: 13px; text-align: center;">{{ version }}</p>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";
// import OkInput from "../components/ok-input.vue";

export default {
  mixins: [userMixin, campaignMixin],
  components: {
    MainLayout,
    // OkInput,
  },
  data() {
    return {
      userEdit: "",
      creditCards: "",
      notes: "",
      address: {
        zipcode: "",
        street: "",
        number: "",
        apto: "",
        district: "",
        state: "",
        complement: "",
        city: "",
        country: "",
      },
      userAgree: false,
      genderOptions: [
        { name: "Masculino", value: "M" },
        { name: "Feminino", value: "F" },
        { name: "Outros", value: "O" },
        { name: "Não informar", value: "NI" },
      ],
      version: '',
    };
  },
  mounted() {
    // console.log(this.campaignHome);
    this.version = process.env.VUE_APP_VERSION
  },
  watch: {
    campaignHome(newValue) {
      this.userEdit = `${newValue}/user`;
      this.creditCards = `${newValue}/user/cards`;
      this.notes = `${newValue}/notas`
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    exit() {
      this.logout();
      // const shopping = localStorage.shoppingSlug;
      // const campaign = localStorage.campaignSlug;
      const url = this.campaignHome;
      window.open(url, "_self");
      // window.open("./user/login", "_self");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

#icon-menu-user {
  mask: url("../assets/icons/user-menu-user.svg");
}

#icon-menu-cards {
  mask: url("../assets/icons/user-menu-cards.svg");
}

#icon-menu-history {
  mask: url("../assets/icons/user-menu-history.svg");
}

#icon-menu-exit {
  mask: url("../assets/icons/user-menu-exit.svg");
  background: #ccc;
}

.user-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;

  ul.user-menu-list {
    list-style: none;
    width: 80%;
    margin-top: 25px;
    display: grid;
    margin: 2em;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      button {
        font-size: 8pt;

        .icon-svg {
          display: block;
          position: relative;
          height: 40%;
          background: var(--primary);
          mask-size: contain !important;
          mask-repeat: no-repeat !important;
          mask-position: center !important;
          margin-top: 10px;
          margin-bottom: 14px;

          img {
            height: 100%;
            opacity: 0;
          }
        }
      }
    }
  }

  .menu-btn {
    border: 2px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
    margin: 3px 0px;
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 140px;
    height: 130px;
    margin: auto;

    &.grey {
      border-color: #ccc;
      color: #ccc;
    }
  }

  .clean {
    width: 100%;
    height: 45px;
  }
}
</style>