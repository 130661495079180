<template>
  <MainLayout>
    <div class="billsucess-campaign-container" :style="style">
      <div class="top-title">
        <h1>Aponte a câmera para o QRcode da nota</h1>
      </div>
      <!-- <code v-if="device">{{ device.label }}</code> -->
      <div id="icon-success">
        <img src="../assets/icons/icon_success.svg" alt="" />
      </div>

      <h2>Sucesso! Pagamento Realizado.</h2>
      <h2>Mostre essa tela ao lojista para aprovação e finalização da compra.</h2>

      <br><br>

      <form class="rating-box" v-if="campaign && layout.send_seller">
        <h3>
          Gostou do atendimento ? <br />
          Informe o vendedor que lhe atendeu.
        </h3>
        <br />
        <ok-input :model="store" @updated="
          (x) => {
            store = x;
          }
        " :options="storeListNames" :label="'Selecione a loja'" :id="'store'" :placeholder="' '"></ok-input>

        <div class="star-frame" v-if="layout.rate_store">
          <i class="star" :class="{ 'active': store_value > 0 }" @click="store_value = 1"></i>
          <i class="star" :class="{ 'active': store_value > 1 }" @click="store_value = 2"></i>
          <i class="star" :class="{ 'active': store_value > 2 }" @click="store_value = 3"></i>
          <i class="star" :class="{ 'active': store_value > 3 }" @click="store_value = 4"></i>
          <i class="star" :class="{ 'active': store_value > 4 }" @click="store_value = 5"></i>
        </div>

        <ok-input :model="seller" @updated="
          (x) => {
            seller = x;
          }
        " :label="'Nome do atendente'" :id="'seller'" :placeholder="' '" style="margin-top: 20px;">
        </ok-input>

        <div class="star-frame" v-if="layout.rate_seller">
          <i class="star" :class="{ 'active': seller_value > 0 }" @click="seller_value = 1"></i>
          <i class="star" :class="{ 'active': seller_value > 1 }" @click="seller_value = 2"></i>
          <i class="star" :class="{ 'active': seller_value > 2 }" @click="seller_value = 3"></i>
          <i class="star" :class="{ 'active': seller_value > 3 }" @click="seller_value = 4"></i>
          <i class="star" :class="{ 'active': seller_value > 4 }" @click="seller_value = 5"></i>
        </div>

      </form>

      <div class="buttons-frame" v-if="campaign && layout.send_seller">
        <button class="primary_border" id="btn-back" @click="goToHome">Ir para home</button>
        <button class="primary" id="btn-send" @click="evaluate">Enviar indicação</button>
      </div>
      <div v-if="!campaign || !layout.send_seller">
        <button class="primary_border" id="btn-back" @click="goToHome">Ir para home</button>
      </div>



    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import OkInput from "../components/ok-input.vue";

export default {
  mixins: [CampaignMixin],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      store: "",
      store_value: 0,
      seller: "",
      seller_value: 0,
      storeList: [],
      storeListNames: [],
    };
  },
  mounted() {
    this.getStores();
  },
  methods: {
    // navigation

    goToHome() {
      const url = this.campaignHome;
      window.open(url, "_self");
    },
    getStores() {
      let self = this;

      this.getCampaignStores().then((response) => {
        for (var item of response) {
          self.storeListNames.push({
            name: item.name_fancy,
            value: item._id,
          });
        }
        self.storeList = response;
      });
    },

    evaluate() {
      if (!this.store || this.store == "") {
        this.$swal({
          icon: "error",
          title: "Informe a empresa que emitiu a nota fiscal.",
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return;
      }

      if (this.seller.length < 3) {
        this.$swal({
          icon: "error",
          title: "Informe o nome da pessoa que lhe atendeu.",
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return;
      }

      this.sendEvaluation(this.store, this.store_value, this.seller, this.seller_value)
        .then((response) => {
          console.log(response);
          // this.goToHome();
        });
    }
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.billsucess-campaign-container {
  position: relative;
  min-height: calc(100vh - 200px);
  margin-bottom: 150px;

  .star-frame {
    width: fit-content;
    height: 40px;

    .star {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 50%;
      background: #ddd;
      cursor: pointer;
      margin: 0px 2px;

      &.active {
        background: var(--primary);
      }
    }
  }

  .buttons-frame {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    #btn-back {
      // max-width: 120px;
    }

    #btn-send {
      //  max-width: 120px; 
    }
  }

  .rating-box {
    margin: auto;
    width: 90%;
    max-width: 420px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 70px;
  }

  #icon-success {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 40px;

    img {
      display: block;
    }
  }

  .top-title {
    h1 {
      font-size: 11pt !important;
    }
  }

  h2 {
    text-align: center;
    margin-top: 30px;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
    padding: 0px 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;

    .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
  }
}
</style>