<template>
  <MainLayout>
    <div class="note-view" v-if="campaign.gears && campaign.gears.control">
      <div class="top-title">
        <h1>Nota fiscal</h1>
      </div>

      <div class="note-content">
        <h2>Dados do cupom</h2>

        <hr style="margin: 20px 0" />

        <div class="left">
          <div v-if="order.image">
            <img :src="getImageThumbUrl(order.image)" alt="">

          </div>
          <div class="img-empty" v-else></div>
        </div>

        <div class="note-info right">
          <table>
            <tr>
              <td class="label">Número</td>
              <td>{{ order.code }}</td>
            </tr>
            <tr v-if="order.store">
              <td class="label">CNPJ</td>
              <td>{{ order.store.document | toCNPJ }}</td>
            </tr>
            <tr>
              <td class="label">Data</td>
              <td>{{ order.purchase_date | formatDate }}</td>
            </tr>
            <tr>
              <td class="label">Valor</td>
              <td>{{ order.price | toCurrency }}</td>
            </tr>
            <tr>
              <td class="label">Status</td>
              <td>{{ orderStatus[order.status] }}</td>
            </tr>
            <tr>
              <td class="label">Pagt.</td>
              <td>{{ paymentTypes[order.payment_type] }}</td>
            </tr>
            <tr>
              <td class="label">Obs</td>
              <td>{{ order.error_message }}</td>
            </tr>
          </table>
        </div>
      </div>


      <center>
        <button class="primary" @click="historyBack">Voltar</button>
      </center>


    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import OrderMixin from "../mixins/order.mixin.js";
import userMixin from "../mixins/user.mixin";

import "../filters/toCurrency";

export default {
  mixins: [CampaignMixin, userMixin, OrderMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      tab: "bill",
      order: {
        company: null,
        store: null,
        campaign: null,
        user: null,
        image: null,
        user_document: '',
        payment_type: null,
        type: 'INVOICE',
        code: '',
        qr_code: '',
        image_url: '',
        number: '',
        status: 'ACTIVE',
        price: 0,
        amount: 0,
        installments: 1,
        is_consumed: false,
        error_message: '',
        purchase_date: null,
        created_at: null,
        updated_at: null,
      },
      orderStatus: {
        NULL: null,
        ABANDONED: "Abandonado",
        AWAIT: 'Esperando',
        AWAIT_PAYMENT: 'Esperando Pagamento',
        PAID: 'Pago',
        PROCESSING: 'Processando',
        FINISH: 'Finalizado',
        SEND: "Enviado",
        CANCEL: "Cancelado",
        DENIED: "Reprovado"
      },
      listMessage: "Carregando",
      listMessagePayment: "Carregando",
    };
  },
  mounted: function () {
    let self = this;
    var id = this.$route.params.id;
    this.getBill(id).then(function (response) {
      console.log(response)
      self.order = response.order;
    })
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors.scss";

.note-view {
  padding-bottom: 150px;
}

.note-content {
  height: 100%;
  padding: 20px 10px 0px 20px;
  margin-bottom: 0px;

  justify-content: space-evenly;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 50px;
  padding: 20px;
  gap: 40px;

  .image {
    width: 80%;
    max-width: 420px;
    min-height: 400px;
    background: var(--bg-color-dark);
    display: block;
    margin: auto;
  }

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }

  .note-info {
    background: var(--bg-color-dark);
    padding: 50px 30px;

    table {
      width: 100%;

      td {
        padding: 10px 0px;
        text-align: left;

        &.label {
          font-weight: bold;
          color: var(--primary);
        }
      }
    }
  }
}

.left {
  width: calc(45% - 20px);
  padding: 0px 10px;

  img {
    width: 100%;
  }

  .img-empty {
    width: 100%;
    aspect-ratio: 3/4;
    max-width: 450px;
    background-color: $bg-color-dark;
  }
}

.right {
  width: calc(55% - 20px);
}

@media (max-width: 768px) {
  .note-content {
    padding: 20px 0px !important;

    .left {
      width: 100%;
    }

    .right {
      width: 100%;
    }
  }
}
</style>