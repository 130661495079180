<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import navigationMixin from './mixins/navigation.mixin'

export default {
  name: "App",
  // mixins: [navigationMixin],
  // components: {

  // },
};
</script>

<style>
@import "./scss/reset.scss";
@import "./scss/global.scss";
</style>
