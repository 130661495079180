import axios from 'axios';
// const FormData = require('form-data');

var orderMixin = {
    data: function () {
        return {
            bill: {},
            orderStatus: {
                DONE: 'Aprovada',
                AWAIT_ORDER: 'Em análise',
                CANCEL: 'Estornada',
                DENIED: 'Recusada',
            },
            paymentTypes: {
                VISA_CREDIT: 'Crédito - Visa',
                VISA_DEBIT: 'Débito - Visa',
                MASTER_CREDIT: 'Crédito - Mastercard',
                MASTER_DEBIT: 'Débito - Mastercard',
                AMEX_CREDIT: 'Crédito - Amex',
                ELO_CREDIT: 'Crédito - Elo',
                ELO_DEBIT: 'Débito - Elo',
                AURA_CREDIT: 'Crédito - Aura',
                AURA_DEBIT: 'Débito - Aura',
                JBC_CREDIT: 'Crédito - JBC',
                JBC_DEBIT: 'Débito - JBC',
                DINERS_CREDIT: 'Crédito - Dinners',
                DINERS_DEBIT: 'Débito - Dinners',
                DISCOVER_CREDIT: 'Crédito - Discover',
                DISCOVER_DEBIT: 'Débito - Discover',
                HIPERCARD_CREDIT: 'Crédito - Hipercard',
                HIPERCARD_DEBIT: 'Débito - Hipercard',
                HIPER_CREDIT: 'Crédito - Hiper',
                HIPER_DEBIT: 'Débito - Hiper',
                MONEY: 'Dinheiro',
                CHECK: 'Cheque',
                NULL: null
            }
        }
    },
    computed: {
        // $api: () => $api
    },
    methods: {
        // uploadPhoto(imageData) {
        //     var formData = new FormData();
        //     const filename = this.makeFileName()
        //     var fileData = this.dataURLtoFile(imageData, filename);
        //     formData.append('my_field', 'my value');
        //     formData.append('my_buffer', new Buffer(10));
        //     formData.append("file", fileData);

        //     return axios.post(this.api.base_url + "/media/image", formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     })
        //         .then(function (response) {
        //             // console.log(response);
        //             const dataResponse = response.data.data;
        //             // console.log(dataResponse);
        //             return dataResponse;
        //         }).catch((err) => {
        //             console.log(err.message);
        //             self.showError(err);
        //             return err;
        //         })
        // },
        // sendBillPhoto(imageId) {
        //     let self = this;
        //     if (!this.api) return null;

        //     const data = {
        //         invoice: {
        //             image: imageId,
        //         },
        //         campaign: this.campaignSlug,
        //         shopping: this.shoppingSlug
        //     }
        //     return axios.post(this.api.base_url + "/invoice/", data).then((res) => {
        //         const dataResponse = res.data.data;
        //         // console.log(dataResponse);
        //         return dataResponse;
        //     }).catch((err) => {
        //         // console.log(err.message);
        //         self.showError(err);
        //         return err;
        //     })
        // },
        // sendBillQRCode(qrcode) {
        //     // console.log("Campaign mixin - function sendBillQRCode");
        //     let self = this;
        //     if (!this.api) return null;

        //     const data = {
        //         invoice: {
        //             qrcodeText: qrcode,
        //         },
        //         campaign: this.campaignSlug,
        //         shopping: this.shoppingSlug
        //     }
        //     return axios.post(this.api.base_url + "/invoice/", data).then((res) => {
        //         const dataResponse = res.data.data;
        //         // console.log(dataResponse);
        //         return dataResponse;
        //     }).catch((err) => {
        //         // console.log(err.message);
        //         return self.showErrorAndReturn(err);
        //     })
        // },
        sendBillPayment(store_code, installments, price, cardId) {
            let self = this;
            if (!this.api) return null;

            const data = {
                invoice: {
                    store_code: store_code,
                    installments: installments,
                    price: price,
                    card: cardId,
                },
                campaign: this.campaignSlug,
                shopping: this.shoppingSlug
            }
            return axios.post(this.api.base_url + "/invoice/payment", data).then((res) => {
                const dataResponse = res.data.data;
                return dataResponse;
            }).catch((err) => {
                return self.showErrorAndReturn(err);
            })
        },
        listBillsPayment() {
            const self = this;
            const shopping = this.shoppingSlug;
            const campaign = this.campaignSlug;

            const path = `/invoice/payment?shopping=${shopping}&campaign=${campaign}`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const invoice_list = response.data.data.invoice_list;
                if (invoice_list) {
                    return invoice_list.data;
                } else {
                    return [];
                }

            }).catch(function (error) {
                self.showError(error);
            });
        },
        createBill(bill) {
            const self = this;
            let billToSend = JSON.parse(JSON.stringify(bill));
            // if (billToSend.user.document) billToSend.user.document = billToSend.user.document.replace(/\D/g, "");

			if (billToSend.invoice?.price) {
				billToSend.invoice.price = parseFloat(billToSend.invoice.price)
			}

            const path = `/invoice`;
            // const path = `/invoice/monitor?company=${shopping}&campaign=${campaign}`;
            return axios.post(this.api.base_url + path, billToSend).then(function (response) {
                const responseData = response.data;
                return responseData

            }).catch(function (error) {
                self.showError(error);
                return null
            });

        },
        validateBillForm(bill) {
            console.log('validateBillForm', bill)
            if (!bill.code) {
                return {
                    validated: false,
                    message: "Por favor insira o número da nota"
                };
            }
            if (bill.code.length < 3) {
                return {
                    validated: false,
                    message: "Número da nota inválido."
                };
            }
            if (!bill.store) {
                return {
                    validated: false,
                    message: "Por favor selecione uma loja"
                };
            }
            if (!bill.purchase_date) {
                return {
                    validated: false,
                    message: "Por favor insira a data da compra"
                };
            }
            if (!bill.price) {
                return {
                    validated: false,
                    message: "Por favor insira o valor da compra"
                };
            }

            return {
                validated: true
            };

        },
        // sendEvaluation(store, store_value, seller, seller_value) {
        //     let self = this;
        //     if (!this.api) return null;

        //     const data = {
        //         campaign: this.campaign._id,
        //         shopping: this.campaign.company._id,
        //         evaluate: {
        //             store: store,
        //             seller_name: seller,
        //             store_value: store_value,
        //             seller_value: seller_value
        //         }
        //     }

        //     return axios.put(this.api.base_url + "/invoice/evaluate", data).then((res) => {
        //         const dataResponse = res.data.data;
        //         // console.log(dataResponse);
        //         this.$swal({
        //             icon: "success",
        //             title: "Avaliação registrada com sucesso!",
        //             // footer: '<a href>Why do I have this issue?</a>'
        //         });
        //         return dataResponse;
        //     }).catch((err) => {
        //         // console.log(err.message);
        //         return self.showErrorAndReturn(err);
        //     })
        // },
    }
}

export default orderMixin;