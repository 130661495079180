import axios from 'axios';
// import moment from 'moment';

var userMixin = {
    data: function () {
        return {
            user: {
                lgpd: {
                    accept_emailContact: false
                },
                name: '',
                lastname: '',
                birthday: '',
                document: '',
                document_rg: '',
                email: '',
                phone: '',
                type: 'CUSTOMER',
                password: '',
                address: [
                    {
                        zipcode: '',
                        street: '',
                        number: '',
                        apto: '',
                        district: '',
                        state: '',
                        complement: '',
                        city: '',
                        country: ''
                    }
                ],
            },
            auth: {},
            requiredFields: ['REQUIRED', 'LOGIN'],
            isCPFDisabled: false,
        }
    },
    mounted() {
        if (localStorage.token != null) {
            this.load();
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        },
        login(loginData) {
            const self = this;
            this.getCleanUser(loginData.user);

            return axios.post(this.api.base_url + "/auth/login", {
                email: loginData.user.email,
                document: loginData.user.document,
                phone: loginData.user.phone,
                password: loginData.user.password,
                birthday: loginData.user.birthday,
                campaign: loginData.campaign,
                shopping: loginData.shopping
            }).then(function (response) {
                let token = response.data.data.auth.token;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
                self.user = response.data.data.user;
                return self.user;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        forgotPassword(data) {
            const self = this;

            axios.post(this.api.base_url + "/auth/forgot-password", data).then(function (response) {
                let responseData = response.data

                self.$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    // footer: '<a href>Why do I have this issue?</a>'
                });
            }).catch(function (error) {
                self.showError(error)
            });
        },
        resetPassword(data) {
            const self = this;

            axios.post(this.api.base_url + "/auth/reset-password", data).then(function (response) {
                let responseData = response.data

                self.$swal({
                    icon: "success",
                    title: "Sucesso",
                    text: responseData.message,
                    // footer: '<a href>Why do I have this issue?</a>'
                });
            }).catch(function (error) {
                self.showError(error)
            });
        },
        load() {
            const self = this;
            return axios.get(this.api.base_url + "/auth/load").then(function (response) {
                self.user = response.data.data.user;
                console.log({ user: self.user })
                if (self.user.document) self.isCPFDisabled = true

                localStorage.setItem('user', JSON.stringify(response.data.data.user));
                return self.user;
            }).catch(function (error) {
                localStorage.removeItem('token');
                let path = encodeURI(window.location.pathname);
                let pathSplited = path.split("/")
                let shopping, campaign, totem = ''
                if (pathSplited[1] == 'totem') {
                    shopping = pathSplited[2]
                    campaign = pathSplited[3]
                    totem = 'totem/'
                } else {
                    shopping = pathSplited[1]
                    campaign = pathSplited[2]
                }
                window.open(`${totem}${shopping}/${campaign}/user/login`, "_self")
                self.showError(error);
            });
        },
        getUserBalance(userId) {
            const self = this

            const campaign = localStorage.campaignSlug
            const shopping = localStorage.shoppingSlug

            const query = `?company=${shopping}&campaign=${campaign}&user=${userId}`

            return axios.get(this.api.base_url + '/user/balance' + query).then(function (response) {
                const responseData = response.data.data;
                return responseData;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        getAddress(zipcode) {
            let self = this;

            if (zipcode.length < 9) return null

            // if (zipcode == localStorage.getItem("zipcode")) return;

            // localStorage.setItem("zipcode", self.postAddress.zipcode);
            const url = `${this.api.webservices}/zipcode?zipcode=${zipcode.replace(/\D/g, '')}`;

            return this.axios
                .get(url)
                .then(function (response) {
                    let responseData = response.data.data;
                    const address = {
                        city: responseData.localidade,
                        street: responseData.logradouro,
                        state: responseData.uf,
                        district: responseData.bairro,
                    }
                    return address;
                })
                .catch(function (error) {
                    self.output = error;
                    self.showError(error);
                });
        },
        listUsers(campaignData, type) {
            const shopping = campaignData.shopping;
            const campaign = campaignData.campaign;
            // const store = campaignData.parent;
            const status = 'FREE'

            const path = `/user?company=${shopping}&campaign=${campaign}&type=${type}&status=${status}`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const responseData = response.data.data;
                return responseData;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        createUser(userData) {
            const self = this;

            let userCopy = JSON.parse(JSON.stringify(userData.user))
            this.getCleanUser(userCopy);
            return axios.post(this.api.base_url + '/user/new', {
                user: userCopy,
                campaign: userData.campaign,
                shopping: userData.shopping,
            }).then((response) => {
                let token = response.data.data.auth.token;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
                self.user = response.data.data.user;
                return self.user;
            })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        // console.log("Response", error.response.data);
                        // console.log("Response", error.response.status);
                        // console.log("Response", error.response.headers);

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("Request", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log("showError 0");
                    self.showError(error);
                    // return null;
                });
        },
        updateUser(userData) {
            const self = this;

            let userCopy = JSON.parse(JSON.stringify(userData.user))
            this.getCleanUser(userCopy);
            if (userCopy.password) delete userCopy.password

            return axios.put(this.api.base_url + '/user/' + userData.user._id, {
                user: userCopy,
                campaign: userData.campaign,
                shopping: userData.shopping,
            }).then((response) => {
                self.user = response.data.data.user;
                return self.user;
            })
                .catch(function (error) {
                    self.showError(error);
                    return null;
                });

        },
        createUserCard(card) {
            const self = this;

            return axios.post(this.api.base_url + '/user/' + this.user._id + '/card', {
                card: card,
            }).then((response) => {
                return response;
            })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        // console.log("Response", error.response.data);
                        // console.log("Response", error.response.status);
                        // console.log("Response", error.response.headers);

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("Request", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log("showError 0");
                    self.showError(error);
                    // return null;
                });
        },
        removeCard(card) {
            const self = this;

            return axios.delete(this.api.base_url + '/user/' + this.user._id + '/card/' + card._id)
                .then((response) => {
                    return response;
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        // console.log("Response", error.response.data);
                        // console.log("Response", error.response.status);
                        // console.log("Response", error.response.headers);

                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("Request", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log("showError 0");
                    self.showError(error);
                    // return null;
                });
        },
        listCards() {
            const self = this;

            const path = `/user/${this.user._id}/card`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const card_list = response.data.data.card_list;
                return card_list;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        getBill(id) {
            const self = this;

            const path = `/invoice/monitor/${id}`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const responseData = response.data.data;
                return responseData;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        listBills(campaignData) {
            const self = this;
            const shopping = campaignData.shopping;
            const campaign = campaignData.campaign;
            const user = campaignData.user;

            const path = `/invoice?shopping=${shopping}&campaign=${campaign}&user=${user}`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const invoice_list = response.data.data.invoice_list;
                if (invoice_list) {
                    return invoice_list;
                } else {
                    return [];
                }

            }).catch(function (error) {
                self.showError(error);
            });
        },
        getTickets() {
            let self = this;
            let user = JSON.parse(localStorage.user);
            const shoppingSlug = localStorage.shoppingSlug
            const campaignSlug = localStorage.campaignSlug
            let path = `/ticket?shopping=${shoppingSlug}&campaign=${campaignSlug}&user=${user._id}`

            return this.axios
                .get(this.api.base_url + path)
                .then(function (response) {
                    let responseData = response.data.data;
                    return responseData;
                })
                .catch(function (error) {
                    self.output = error;
                    return null;
                    // self.showError(error);
                });
        },
        getTicket(id) {
            let self = this;

            let path = `/ticket/${id}`
            return this.axios
                .get(this.api.base_url + path)
                .then(function (response) {
                    let responseData = response.data.data;
                    return responseData;
                })
                .catch(function (error) {
                    self.output = error;
                    return null;
                    // self.showError(error);
                });
        },
        listCoupons(campaignData) {
            const self = this;
            const shopping = campaignData.shopping;
            const campaign = campaignData.campaign;

            const path = `/invoice/coupons?shopping=${shopping}&campaign=${campaign}`;
            return axios.get(this.api.base_url + path).then(function (response) {
                const responseData = response.data.data;
                // console.log(responseData);
                // const invoice_list = response.data.data.invoice_list;
                return responseData.status;
            }).catch(function (error) {
                self.showError(error);
            });
        },
        getCleanUser(data) {
            if (data.document) data.document = data.document.replace(/\D/g, "")
            if (data.document_rg) data.document_rg = data.document_rg.replace(/\D/g, "")
            if (data.phone) data.phone = data.phone.replace(/\D/g, "")
        },
        getBrand(card) { // 2307448914461113
            var ccNum = card.number;
            var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
            //   var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
            var mastercardRegEx2 = /^(?:5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12})$/;
            var eloRegEx = /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/;
            var amexpRegEx = /^(?:3[47][0-9]{13})$/;
            var hiperRegEx = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
            var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
            var isValid = false;

            //Bandeira do cartão (Visa / Master / Amex / Elo / Aura / JCB / Diners / Discover / Hipercard / Hiper).

            if (visaRegEx.test(ccNum)) {
                isValid = true;
                card.brand = "Visa";
            } else if (mastercardRegEx2.test(ccNum)) {
                isValid = true;
                card.brand = "Master";
            } else if (amexpRegEx.test(ccNum)) {
                isValid = true;
                card.brand = "Amex";
            } else if (eloRegEx.test(ccNum)) {
                isValid = true;
                card.brand = "Elo";
            } else if (hiperRegEx.test(ccNum)) {
                isValid = true;
                card.brand = "Hipercard";
            } else if (discovRegEx.test(ccNum)) {
                isValid = true;
                card.brand = "Discover";
            }

            if (!isValid) {
                this.card.brand = null;
                // card.brand = 'Master';
            }
        },
        addAsterisk(field) {
            if (!field) return ''
            let value = ''
            this.requiredFields.includes(field) ? value = '*' : value = ''
            return value
        },
        validateForm(campaign, user, isEdit) {
            if (!campaign) return false;
            if (!campaign.user_fields) return false;

            let userToValidate = user ? user : this.user

            if (!userToValidate.name) {
                return {
                    validated: false,
                    message: "Por favor insira seu nome"
                };
            }
            if (userToValidate.name.length < 3) {
                return {
                    validated: false,
                    message: "Não é permitido nome com menos de 3 letras."
                };
            }

            if (!userToValidate.lastname) {
                return {
                    validated: false,
                    message: "Por favor insira seu sobrenome"
                };
            }
            if (userToValidate.lastname.length < 3) {
                return {
                    validated: false,
                    message: "Não é permitido sobrenome com menos de 3 letras."
                };
            }

            if (this.requiredFields.includes(campaign.user_fields.birthday)) {
                if (!userToValidate.birthday) {
                    return {
                        validated: false,
                        message: "Por favor insira sua data de nascimento"
                    };
                }

                if (userToValidate.birthday.length < 10 || userToValidate.birthday === 'Data inválida') {
                    return {
                        validated: false,
                        message: "Data de nascimento inválida."
                    };
                }
                var age = this.calculateAge(userToValidate.birthday);
                if (age < 18 || age > 120) {
                    return {
                        validated: false,
                        message: "Data de nascimento inválida."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.cpf)) {
                if (!userToValidate.document) {
                    return {
                        validated: false,
                        message: "Por favor insira seu CPF."
                    };
                }
                if (userToValidate.document.length < 11) {
                    return {
                        validated: false,
                        message: "CPF inválido."
                    };
                }
                if (!this.isValidCPF(userToValidate.document)) {
                    return {
                        validated: false,
                        message: "CPF inválido."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.rg)) {
                if (!userToValidate.document_rg) {
                    return {
                        validated: false,
                        message: "Por favor insira seu RG."
                    };
                }
                if (userToValidate.document_rg.length < 5) {
                    return {
                        validated: false,
                        message: "RG inválido."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.gender)) {
                if (!userToValidate.gender) {
                    return {
                        validated: false,
                        message: "Por favor insira seu gênero."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.email)) {
                if (!userToValidate.email) {
                    return {
                        validated: false,
                        message: "Por favor insira seu e-mail."
                    };
                }
                if (userToValidate.email.length < 5) {
                    return {
                        validated: false,
                        message: "E-mail inválido."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.phone)) {
                if (!userToValidate.phone) {
                    return {
                        validated: false,
                        message: "Por favor insira seu telefone."
                    };
                }
                if (userToValidate.phone.length < 5) {
                    return {
                        validated: false,
                        message: "Telefone inválido."
                    };
                }
            }
            if (this.requiredFields.includes(campaign.user_fields.password) && !isEdit) {
                if (!userToValidate.password) {
                    return {
                        validated: false,
                        message: "Por favor insira sua senha."
                    };
                }
                if (userToValidate.password.length < 5) {
                    return {
                        validated: false,
                        message: "Sua senha deve ter 6 ou mais letras (e/ou números)."
                    };
                }
            }

            if (this.requiredFields.includes(campaign.user_fields.address)) {
                if (!userToValidate.address) {
                    return {
                        validated: false,
                        message: "Por favor digite o CEP."
                    };
                }
                var addressValidated = this.validateAddressForm(userToValidate.address[0])
                if (!addressValidated.validated) return addressValidated;
            }

            return {
                validated: true
            };

        },
        validateCardForm(card) {
            if (!card) return false;

            if (!card.holder_name) {
                return {
                    validated: false,
                    message: "Por favor insira o nome que está no cartão"
                };
            }

            if (!card.number) {
                return {
                    validated: false,
                    message: "Por favor insira o número do cartão"
                };
            }

            if (card.number.length < 16) {
                return {
                    validated: false,
                    message: "Número do cartão inválido."
                };
            }

            if (!card.exp) {
                return {
                    validated: false,
                    message: "Por favor insira a validade"
                };
            }

            if (!card.security_code) {
                return {
                    validated: false,
                    message: "Por favor insira o CVV"
                };
            }

            if (card.security_code.length < 3) {
                return {
                    validated: false,
                    message: "CVV inválido."
                };
            }

            return {
                validated: true
            };

        },
        validateAddressForm(address) {

            console.log(address.zipcode)
            console.log(address.zipcode.length)
            let addressCopy = JSON.parse(JSON.stringify(address))
            addressCopy.zipcode = this.removeMask(addressCopy.zipcode)

            if (addressCopy.zipcode.length != 8) {
                return {
                    validated: false,
                    message: "CEP inválido."
                };
            }

            if (addressCopy.street.length < 3) {
                return {
                    validated: false,
                    message: "Logradouro inválido."
                };
            }

            if (addressCopy.number.length < 1 || addressCopy.number.length > 10) {
                return {
                    validated: false,
                    message: "Número da casa/edifício inválido."
                };
            }

            // if (addressCopy.complement.length < 1 ) {
            //     return false;
            // }

            // if (addressCopy.apto.length > 10) {
            // 	return {
            // 		validated: false,
            // 		message: "Número do apartamento inválido."
            // 	};
            // }

            // if (addressCopy.district.length < 3 ) {
            //     return false;
            // }

            if (addressCopy.city.length < 3) {
                return {
                    validated: false,
                    message: "Nome da cidade inválida."
                };
            }

            if (addressCopy.state.length != 2) {
                return {
                    validated: false,
                    message: "Informe a sigla do seu estado (RJ, SP, MG, BA ...)."
                };
            }
            console.log("validateAddressForm 10");

            return {
                validated: true
            };

        },
        isValidCPF(cpf) {
            if (typeof cpf !== 'string') return false
            cpf = cpf.replace(/[^\d]+/g, '')
            if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
            cpf = cpf.split('')
            const validator = cpf
                .filter((digit, index, array) => index >= array.length - 2 && digit)
                .map(el => +el)
            const toValidate = pop => cpf
                .filter((digit, index, array) => index < array.length - pop && digit)
                .map(el => +el)
            const rest = (count, pop) => (toValidate(pop)
                .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10
            return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
        },
        calculateAge(birthdayString) { // birthday is a date
            var birthday = new Date(birthdayString)
            var ageDifMs = Date.now() - birthday.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
        showError(error) {
            console.log("showError 1");
            console.log(error);
            if (error.response) {
                console.log("Response", error.response.data);
                console.log("Response", error.response.status);
                console.log("Response", error.response.headers);

                var answer = error.response.data;
                console.log(answer);
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: answer.message,
                    // footer: '<a href>Why do I have this issue?</a>'
                });
            }
        },
    }
};

export default userMixin;