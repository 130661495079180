<template>
  <layout class="home-totem">
    <div class="page-left" @click="keyboardSide = 'hide'">
      <div class="container">
        <header>
          <a :href="home">
            <img class="logo" v-if="campaign && campaign.company && campaign.company.image"
              :src="getImageUrl(campaign.company.image)" alt="" />
            <img v-else class="logo" src="~@/assets/images/logo-big.png" alt="" />
          </a>
          <div class="campaign-info">
            <h3>
              <span class="highlight">Validade: </span>
              <b>{{ moment(campaign.date_end).add(1, "days").format("LL") }}</b>
            </h3>
          </div>
        </header>

        <div class="col">
          <div class="campaign-banner" v-if="campaign.layout">
            <img class="mobile-only" :src="getImageUrl(campaign.layout.banner)" v-if="campaign.layout.banner"
              alt="banner" />
            <img class="desktop-only" :src="getImageUrl(campaign.layout.web_banner)" v-if="campaign.layout.web_banner"
              alt="banner web" />
            <!-- <img src="../assets/images/banner.png" alt="banner" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="page-right" style="padding-top: 45px" @click="keyboardSide = 'hide'">
      <div v-if="subpage == 'regulamento'">
        <h3>Regulamento</h3>
        <!-- <p>
            {{campaign.rules_file.url}}
          </p> -->

        <iframe v-if="campaign.rules_file"
          :src="`https://docs.google.com/gview?url=${campaign.rules_file.url}&embedded=true`"
          style="width: 100%; height: 70vh; margin-top: 30px" frameborder="0"></iframe>
      </div>

      <div v-else-if="subpage == 'lojas'">
        <store-list />
      </div>

      <div v-else-if="subpage == 'esqueci-senha'" class="card">
        <form-forgot />
      </div>

      <div v-else class="card">

        <h3 style="text-align: center;">Informe seus dados abaixo</h3>
        <form action="submit" v-if="campaign && campaign.user_fields" @click.stop="">

          <ok-input v-if="campaign.user_fields.cpf === 'LOGIN'" :model="user.document" @updated="(x) => {
            user.document = x;
          }
            " :id="'document'" :label="'CPF*'" :mask="'###.###.###-##'" :placeholder="'000.000.000-00'"
            @onFocus="onFocus('document', 'left')">
          </ok-input>

          <ok-input v-if="campaign.user_fields.email == 'LOGIN'" :model="user.email" @updated="(x) => {
            user.email = x;
          }
            " :id="'email'" :label="'Email'" :placeholder="'nome@email.com'" @onFocus="onFocus('email', 'left')">
          </ok-input>

          <ok-input v-if="campaign.user_fields.phone == 'LOGIN'" :model="user.phone" @updated="(x) => {
            user.phone = x;
          }
            " :id="'phone'" :label="'Telefone*'" :mask="'(##) #.####.####'" :placeholder="'(00) 0.0000-0000'"
            @onFocus="onFocus('phone', 'left')">
          </ok-input>

          <ok-input v-if="campaign.user_fields.password === 'LOGIN'" :model="user.password" @updated="(x) => {
            user.password = x;
          }
            " :label="'Senha'" :id="'password'" :type="'password'" @onFocus="onFocus('password', 'left')">
          </ok-input>
          <!-- 
          <ok-input v-else :model="user.birthday" @updated="(x) => {
            user.birthday = x;
          }
            " :id="'birthday'" :label="'Data de nascimento'" :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'"
            :isDate="true" @onFocus="onFocus('birthday', 'full')">
          </ok-input> -->

          <div class="input-frame" v-if="campaign.user_fields.birthday == 'LOGIN'">
            <input id="birthday" name="birthday" placeholder="DD/MM/AAAA" v-model="user.birthday"
              v-on:focus="onFocus('birthday', 'left')" v-mask="'##/##/####'" autocomplete="off" />
            <label for="birthday"><span>Data de nascimento*</span></label>
          </div>

          <div class="buttons">
            <button type="button" class="clean" style="margin-right: 8px;"
              v-if="campaign.user_fields.password === 'LOGIN'" @click="goToForgotPassword()">
              Esqueci a senha
            </button>
            <button type="button" class="primary" @click="submit()">Entrar</button>
          </div>

          <hr>

          <p class="question">
            Ainda não é cadastrado ?
          </p>


          <button type="button" class="primary_border" @click="register()">Cadastre-se</button>
        </form>
      </div>
      <p class="version" v-if="subpage === ''">{{ version }}</p>
    </div>
    <VirtualKeyboard @onKeyPress="onKeyPress" :inputId="editVar" :side="keyboardSide" />
  </layout>
</template>


<script >
import Layout from "../layout/base";
import CampaignMixin from "@/mixins/campaign.mixin.js";
import UserMixin from "@/mixins/user.mixin.js";
// import FormLogin from "@/components/form-login.vue";
import FormForgot from "@/components/form-forgot-password.vue";
import StoreList from "./home-store-list.vue";
import OkInput from "@/components/ok-input.vue";
import VirtualKeyboard from "@/components/virtual-keyboard.vue";

export default {
  mixins: [CampaignMixin, UserMixin],
  components: {
    Layout,
    // FormLogin,
    FormForgot,
    StoreList,
    OkInput,
    VirtualKeyboard,
  },
  data() {
    return {
      home: "",
      subpage: "",
      keyboardSide: 'hide',
      editVar: '',
      version: '',
    };
  },
  watch: {
    campaignHome(newValue) {
      this.home = `totem${newValue}`;
    },

    $route() {
      var hash = window.location.hash.substr(1);
      console.log(hash);
      this.subpage = hash;
    },
  },
  mounted: function () {
    this.logout()
    var hash = window.location.hash.substr(1);
    this.subpage = hash;

    this.version = process.env.VUE_APP_VERSION
  },
  methods: {
    submit() {
      const self = this;
      const loginData = {
        user: JSON.parse(JSON.stringify(this.user)),
      };

      if (loginData.user.birthday) loginData.user.birthday = this.stringToDate(loginData.user.birthday)
      if (this.$route.params.campanha) {
        loginData.shopping = this.$route.params.shopping;
        loginData.campaign = this.$route.params.campanha;
      }

      this.login(loginData).then((response) => {
        console.log("login page", response);
        if (response != undefined) {
          self.goToHome();
        }
      });
    },
    goToHome() {
      window.open(
        `totem/${this.shoppingSlug}/${this.campaignSlug}/painel`,
        "_self"
      );
    },
    goToForgotPassword() {
      window.open(
        `totem/${this.shoppingSlug}/${this.campaignSlug}#esqueci-senha`,
        "_self"
      );
    },
    register() {
      window.open(
        `totem/${this.shoppingSlug}/${this.campaignSlug}/user/register`,
        "_self"
      );
    },
    onKeyPress(button) {
      console.log("onKeyPress", button, this.editVar)
      if (button === '{bksp}') {
        const newValue = this.user[this.editVar].slice(0, -1)
        this.user[this.editVar] = newValue
      } else if (button === '{space}') {
        this.user[this.editVar] += ' '
      } else if (button === '{tab}' || button === '{lock}' || button === '{shift}' || button === '{enter}') {
        return
      } else {
        console.log({ keyboardSide: this.keyboardSide, editVar: this.editVar, user: this.user, user2: this.user[this.editVar] })
        this.user[this.editVar] = this.insertOnObj(this.user[this.editVar], button)
      }
    },
    onFocus(input, side) {
      this.editVar = input;
      this.keyboardSide = side
      this.$forceUpdate();
      console.log("Focus", this.editVar);
    }
  },
};
</script>
<style  lang="scss" >
@import "~@/scss/colors.scss";

.home-totem {

  .page-left {
    grid-area: left;
    padding: 35px;
    background-color: var(--bg-color);
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .page-right {
    position: relative;
    grid-area: right;
    padding: 35px;
    background-color: var(--bg-color-dark);
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    form {
      max-width: initial;
      margin-top: 20px;
      padding: 0px 35px;

      .buttons {
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        padding: 10px 0px;

        button {
          margin-top: 10px;
        }
      }

      button {
        width: 160px;

        &.clean {
          font-size: 0.9rem;
        }
      }
    }

    .version {
      position: absolute;
      bottom: 0px;
      text-align: center;
      font-size: 13px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  header {
    img.logo {
      // padding: 10px;
      height: 40px;
    }

    .campaign-info {
      width: 300px;
      float: right;
      text-align: right;
      margin-top: 10px;

      .highlight {
        color: var(--primary);
        font-weight: bold;
      }
    }
  }

  .campaign-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
      // max-height: 40vh;
    }

    button {
      bottom: 15px;
    }
  }

  .card {
    position: initial;
    background-color: var(--bg-color);
    box-shadow: $shadow-border;
    max-width: 80%;
    padding: 50px 20px;
    margin: auto;

  }

  // .virtual-keyboard {
  //   width: 100%;
  //   margin-left: 0px;
  // }
}
</style>