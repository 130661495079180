import axios from "axios";
const FormData = require("form-data");

var campaignMixin = {
  data: function () {
    return {
      campaign: {},
      bill: {},
      style: {},
      layout: {
        buttonHome_show: false,
        bottomLogin_show: false,
        question_show: false,
        question_text: "",
        accept_terms: true,
        accept_rules: true,
        accept_email: true,
      },
      paymentMethods: [
        { name: "Dinheiro", value: "MONEY" },
        { name: "Débito", value: "DEBIT" },
        { name: "Crédito", value: "CREDIT" },
        { name: "PIX", value: "PIX" },
        // { name: 'Nenhuma', value: null },
        // { name: 'Crédito - Visa', value: 'VISA_CREDIT' },
        // { name: 'Débito - Visa', value: 'VISA_DEBIT' },
        // { name: 'Crédito - Mastercard', value: 'MASTER_CREDIT' },
        // { name: 'Débito - Mastercard', value: 'MASTER_DEBIT' },
        // { name: 'Crédito - Amex', value: 'AMEX_CREDIT' },
        // { name: 'Crédito - Elo', value: 'ELO_CREDIT' },
        // { name: 'Débito - Elo', value: 'ELO_DEBIT' },
        // { name: 'Crédito - Dinners', value: 'DINERS_CREDIT' },
        // { name: 'Débito - Dinners', value: 'DINERS_DEBIT' },
        // { name: 'Crédito - Discover', value: 'DISCOVER_CREDIT' },
        // { name: 'Débito - Discover', value: 'DISCOVER_DEBIT' },
        // { name: 'Crédito - Hipercard', value: 'HIPERCARD_CREDIT' },
        // { name: 'Débito - Hipercard', value: 'HIPERCARD_DEBIT' },
        // { name: 'Crédito - Hiper', value: 'HIPER_CREDIT' },
        // { name: 'Débito - Hiper', value: 'HIPER_DEBIT' },
        // { name: 'Crédito - Aura', value: 'AURA_CREDIT' },
        // { name: 'Débito - Aura', value: 'AURA_DEBIT' },
        // { name: 'Crédito - JBC', value: 'JBC_CREDIT' },
        // { name: 'Débito - JBC', value: 'JBC_DEBIT' },
        // { name: 'Dinheiro', value: 'MONEY' },
        // { name: 'Cheque', value: 'CHECK' },
      ],
      flagsOptions: [
        { name: "Visa", value: "VISA" },
        { name: "Mastercard", value: "MASTER" },
        { name: "Amex", value: "AMEX" },
        { name: "Elo", value: "ELO" },
        { name: "Aura", value: "AURA" },
        { name: "JBC", value: "JBC" },
        { name: "Diners", value: "DINERS" },
        { name: "Discover", value: "DISCOVER" },
        { name: "Hipercard", value: "HIPERCARD" },
        { name: "Hiper", value: "HIPER" },
        // { name: 'Nenhuma', value: null },
      ],
      paymentTypes: {
        MONEY: "Dinheiro",
        DEBIT: "Débito",
        CREDIT: "Crédito",
        PIX: "PIX",
        null: "Nenhuma",
      },
      flagTypes: {
        VISA: "Visa",
        MASTER: "Mastercard",
        AMEX: "Amex",
        ELO: "Elo",
        AURA: "Aura",
        JBC: "JBC",
        DINERS: "Diners",
        DISCOVER: "Discover",
        HIPERCARD: "Hipercard",
        HIPER: "Hiper",
      },
    };
  },
  computed: {
    // $api: () => $api
  },
  mounted() {
    if (localStorage.campanha) {
      var loaded = JSON.parse(localStorage.campanha);
      if (
        loaded.slug == this.campaignSlug &&
        loaded.company.slug == this.shoppingSlug
      ) {
        this.campaign = loaded;
        this.setStyle(this.campaign);
        this.setLayout(this.campaign);
      }
    }
    this.loadCampaign();
  },
  methods: {
    setCampaign(campaign) {
      var refresh = false;
      if (campaign.slug != this.campaign.slug) {
        refresh = true;
      }
      // if (campaign.slug == this.campaignSlug && campaign.company.slug == this.shoppingSlug) {
      //     console.log("setCampaign 2")
      //     this.campaign = campaign;
      //     this.setStyle(this.campaign);
      //     this.setLayout(this.campaign);
      //     this.$forceUpdate();

      //     if (refresh) {
      //         console.log("setCampaign 3")
      //         location.reload();
      //     }
      // }

      this.campaign = campaign;
      this.setStyle(campaign);
      this.setLayout(campaign);
      this.$forceUpdate();
      if (refresh) {
        console.log("setCampaign 3");
        location.reload();
      }
    },
    loadCampaign() {
      let self = this;
      if (!this.api) return null;
      let endpoint =
        "/company/" + this.shoppingSlug + "/campaign/" + this.campaignSlug;
      let campaignName = `${this.shoppingSlug}__${this.campaignSlug}`;
      let now = new Date();
      let nowTime = now.getTime();

      if (localStorage[campaignName]) {
        if (nowTime - localStorage[campaignName] < 1000) {
          return;
        }
      }
      localStorage.setItem(campaignName, nowTime);
      axios
        .get(this.api.base_url + endpoint)
        .then((res) => {
          const campaign = res.data.data.campaign;
          // localStorage.removeItem(campaignName);

          self.setCampaign(JSON.parse(JSON.stringify(campaign)));
          localStorage.setItem("campanha", JSON.stringify(campaign));
          self.$forceUpdate();

          return campaign;
        })
        .catch((err) => {
          console.log(err.message);
          return err;
        });
    },
    getCampaignStores() {
      let self = this;
      return this.axios
        .get(this.api.base_url + "/company?parent=" + this.campaign.company._id)
        .then(function (response) {
          self.companyList = [];
          let responseData = response.data.data.company_list;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          return null;
          // self.showError(error);
        });
    },
    uploadPhoto(imageData) {
      var formData = new FormData();
      const filename = this.makeFileName();
      var fileData = this.dataURLtoFile(imageData, filename);
      // console.log("uploadPhoto", filename);
      // console.log("uploadPhoto", fileData);
      // formData.append('my_field', 'my value');
      // formData.append('my_buffer', new Buffer(10));
      // formData.append("image", fileData);
      formData.append("file", fileData);
      // console.log("uploadPhoto", formData);
      const path = `/media/image?company=${localStorage.shoppingSlug}&campaign=${localStorage.campaignSlug}`;
      return axios
        .post(this.api.base_url + path, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          // console.log(response);
          const dataResponse = response.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          console.log(err.message);
          self.showError(err);
          return err;
        });
    },
    sendBillPhoto(imageId) {
      // console.log("Campaign mixin - function sendBillPhoto");
      let self = this;
      if (!this.api) return null;

      const data = {
        invoice: {
          image: imageId,
        },
        campaign: this.campaignSlug,
        shopping: this.shoppingSlug,
      };
      return axios
        .post(this.api.base_url + "/invoice/", data)
        .then((res) => {
          const dataResponse = res.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          // console.log(err.message);
          self.showError(err);
          return err;
        });
    },
    sendBillQRCode(qrcode) {
      // console.log("Campaign mixin - function sendBillQRCode");
      let self = this;
      if (!this.api) return null;
      let user = null;

      if (localStorage.user) {
        user = JSON.parse(localStorage.user);
        user = user._id;
      }

      const data = {
        invoice: {
          qr_code: qrcode,
          // quizz_answer: quizz_answer,
          user: user,
        },
        campaign: this.campaignSlug,
        shopping: this.shoppingSlug,
      };
      return axios
        .post(this.api.base_url + "/invoice/qrcode", data)
        .then((res) => {
          const dataResponse = res.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          // console.log(err.message);
          return self.showErrorAndReturn(err);
        });
    },
    sendEvaluation(store, store_value, seller, seller_value) {
      let self = this;
      if (!this.api) return null;

      const user = JSON.parse(localStorage.user);

      const data = {
        evaluate: {
          campaign: this.campaign._id,
          company: this.campaign.company._id,
          user: user._id,
          send_store: store,
          send_seller: seller,
          rate_store: store_value,
          rate_seller: seller_value,
        },
      };

      return axios
        .post(this.api.base_url + "/evaluate", data)
        .then((res) => {
          const dataResponse = res.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          // console.log(err.message);
          return self.showErrorAndReturn(err);
        });
    },
    showSuccess(campaign, whenever) {
      let stringToReturn = "";
      if (
        campaign.survey &&
        campaign.survey.length > 0 &&
        campaign.survey[0].is_enable &&
        campaign.survey[0].whenever != "NEVER" &&
        campaign.survey[0].whenever == whenever
      )
        stringToReturn = "survey";
      // console.log('showSuccess 1', stringToReturn, campaign.survey)
      if (
        campaign.gears.control &&
        (campaign.gears.control.rate_seller ||
          campaign.gears.control.rate_store)
      )
        stringToReturn =
          stringToReturn == "" ? "quizz" : stringToReturn + "_quizz";
      // console.log('showSuccess 2', stringToReturn, campaign.gears.control)

      return stringToReturn;
    },
    updateTicketNumber(ticket, number) {
      let self = this;

      let ticketCopy = JSON.parse(JSON.stringify(ticket));
      let query = `?shopping=${ticketCopy.company.slug}&campaign=${ticketCopy.campaign.slug}`;
      ticketCopy.number = number;
      return axios
        .put(this.api.base_url + "/ticket/" + ticket._id + query, {
          ticket: ticketCopy,
        })
        .then((res) => {
          const dataResponse = res.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          // console.log(err.message);
          self.showError(err);
          return null;
        });
    },
    updateTicketInteracted(ticket) {
      let self = this;

      let ticketCopy = JSON.parse(JSON.stringify(ticket));
      ticketCopy.interacted = true;
      let query = `?shopping=${ticketCopy.company.slug}&campaign=${ticketCopy.campaign.slug}`;

      return axios
        .put(this.api.base_url + "/ticket/" + ticket._id + query, {
          ticket: ticketCopy,
        })
        .then((res) => {
          const dataResponse = res.data.data;
          // console.log(dataResponse);
          return dataResponse;
        })
        .catch((err) => {
          // console.log(err.message);
          self.showError(err);
          return null;
        });
    },
    listProducts() {
      let self = this;
      const campaignSlug = this.campaign.slug;
      const shoppingSlug = this.campaign.company.slug;

      let endpoint = `/product?shopping=${shoppingSlug}&campaign=${campaignSlug}`;

      return this.axios
        .get(this.api.base_url + endpoint)
        .then(function (response) {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          return null;
          // self.showError(error);
        });
    },
    getProduct(productId) {
      let self = this;
      let endpoint = `/product/${productId}`;

      return this.axios
        .get(this.api.base_url + endpoint)
        .then(function (response) {
          let responseData = response.data.data;
          return responseData;
        })
        .catch(function (error) {
          self.output = error;
          return null;
          // self.showError(error);
        });
    },
    setStyle(campaign) {
      if (
        campaign.layout &&
        campaign.layout.colors &&
        campaign.layout.colors.main
      ) {
        this.style = {
          "--primary": campaign.layout.colors.main,
          "--primary-dark": campaign.layout.colors.main_dark,
          "--primary-light": campaign.layout.colors.main_light,

          "--secundary": campaign.layout.colors.accent,
          "--secundary-dark": campaign.layout.colors.accent_dark,
          "--secundary-light": campaign.layout.colors.accent_light,

          "--bg-color": campaign.layout.colors.background,
          "--bg-color-light": campaign.layout.colors.background_light,
          "--bg-color-dark": campaign.layout.colors.background_dark,

          "--color-alert-disable": campaign.layout.colors.alert_disabled,
          "--color-alert-error": campaign.layout.colors.alert_error,
          "--color-alert-warning": campaign.layout.colors.alert_warning,
        };
      }
    },
    setLayout(campaign) {
      if (!campaign) return;
      if (!campaign.layout) return;
      if (campaign.layout.buttonHome_show == undefined) return;
      this.layout = campaign.layout;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // console.log("dataURLtoFile ok");
      return new File([u8arr], filename, { type: mime });
    },
    makeFileName() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text + ".png";
    },
    addZeros(number, numberMax) {
      const numString = number.toString();
      const numMaxString = numberMax.toString();
      const numZeros = numMaxString.length - numString.length;
      if (numZeros <= 0) {
        return numString;
      }
      const zeros = "0".repeat(numZeros);
      return zeros + numString;
    },
    showError(error) {
      if (error.response) {
        var answer = error.response.data;
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    },
    showErrorAndReturn(error) {
      if (error.response) {
        var answer = error.response.data;
        return this.$swal({
          icon: "error",
          title: "Oops...",
          text: answer.message,
          // footer: '<a href>Why do I have this issue?</a>'
        }).then(() => {
          /* Read more about isConfirmed, isDenied below */
          return null;
        });
      }
    },
  },
};

export default campaignMixin;
