<template>
  <MainLayout :style="style">
    <div class="user-card-container" v-if="campaign">
      <div class="top-title">
        <h1>Cartões cadastrados</h1>
      </div>

      <div class="card-list">
        <div
          class=""
          style="margin: 0px 5px"
          v-for="card in cardList"
          :key="card.id"
        >
          <div class="card">
            <h4>**** **** **** {{ card.last_four }}</h4>
            <p>
              {{ card.holder_name }} <br />
              Vencimento: {{ card.exp_month }}/{{ card.exp_year }}
            </p>
            <span class="white-button-bg"></span>
            <span
              id="icon-remove-card"
              @click="remove(card)"
              class="icon-svg"
            >
              <img src="../assets/icons/remove-card.svg" alt="" srcset="" />
            </span>
            <!-- <button class="btn-x" @click="removeCard(card)">X</button> -->
          </div>
        </div>
        <div v-if="cardList && cardList.length == 0">
          <p style="text-align: center">{{ noCardMessage }}</p>
        </div>
      </div>
      <div>
        <button class="primary_border" @click="goTo(cardRegister)" :disabled="isLoading">
          adicionar cartão
        </button>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";

export default {
  mixins: [userMixin, campaignMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      noCardMessage: "Carregando cartões...",
      cardRegister: "",
      card: {
        number: "",
        holder_name: "",
        security_code: "",
        exp: "",
        exp_month: 0,
        exp_year: 0,
        brand: "",
      },
      cardList: [
        // {holder_name: 'Edgar Imai', last_four: 1234, exp_month: 10, exp_year: 25},
        // {holder_name: 'Edgar Imai', last_four: 9876, exp_month: 12, exp_year: 26},
      ],
      isLoading: true,
    };
  },
  watch: {
    user(newValue) {
      if (newValue._id) {
        this.listUserCards();
      }
    },
    campaign(newValue) {
      console.log("Campaign", newValue);
    },
    campaignHome(newValue) {
      this.cardRegister = `${newValue}/user/cards/register`;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    listUserCards() {
      let self = this;
      this.listCards()
        .then((response) => {
          self.listMessage = "Você não possui cartões";
          if (response) {
            // console.log('aqui lista cartao', response)
            self.cardList = response;
            if (response.length == 0) {
              self.noCardMessage = "Nenhum cartão cadastrado";
            }
          }
          self.isLoading = false;
        })
        .catch(function () {
          self.listMessage = "Você não possui cartões";
          self.isLoading = false;
        });
    },
    remove(card) {
      let self = this;
      this.removeCard(card).then(() => {
        self.listUserCards();
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

#icon-remove-card {
  mask: url("../assets/icons/remove-card.svg");
}

.user-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;

  .button-area {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
  }

  button {
    display: block;
    width: 120px;
    height: auto;
    padding: 10px 15px;
    margin: auto;
    font-size: 8pt;

    &.clean {
      background: transparent;
      font-weight: 100;
      border: 1px solid $span-color;
      color: $span-color;
    }
  }

  .card-list {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 20px;
    justify-content: center;

    .card {
      position: relative;
      width: 100%;
      background: #ddd;
      border: 1px solid #ccc;
      border-radius: 15px;
      padding: 30px;

      span.white-button-bg {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 12px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: white;
      }

      span.icon-svg {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        mask-size: contain !important;
        mask-repeat: no-repeat !important;
        mask-position: center !important;

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          background: var(--primary);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .user-card-container {
    margin-bottom: 60px;
    .card-list {
      display: flex;
      flex-flow: column wrap;

      .card {
        width: 100%;
        padding: 30px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>