import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).add(3, 'hours').format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    // return moment(String(value)).format('DD/MM/YYYY HH:mm')
    let date = new Date(value)
    let options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
    };
    return new Intl.DateTimeFormat('default', options).format(date)
  }
});

