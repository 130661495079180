<template>
  <layout class="painel">
    <div class="page-left" :class="{ 'dark': leftpage == 'profile' }" @click="keyboardSide = 'hide'">
      <div class="container">
        <header style="height: 70px;">
          <a :href="home">
            <img class="logo" v-if="campaign && campaign.company && campaign.company.image"
              :src="getImageUrl(campaign.company.image)" alt="" />
            <img v-else class="logo" src="~@/assets/images/logo-big.png" alt="" />
          </a>
          <div class="campaign-info" v-if="leftpage !== 'profile'">

            <div class="balance">
              <input type="range" class="balance-slider" :min="0" :max="ticketValue" :value="currentBalance" disabled />
              <div class="colored-bar">
                <div class="left"></div>
                <div class="middle"></div>
                <div class="right"></div>
              </div>
              <div class="values">
                <div class="current-value">{{ currentBalance | toCurrency }}</div>
                <div class="ticket-value">{{ ticketValue | toCurrency }}</div>
              </div>
            </div>
          </div>
        </header>
        <div>
          <bill-list v-if="leftpage == 'notas'" @onSelected="(x) => onBillSelected(x)" />
          <coupon-list v-if="leftpage == 'cupons'" @onSelected="(x) => onCouponSelected(x)" />

          <div class="success" v-if="leftpage == 'sucesso'">
            <div id="icon-success">
              <img src="@/assets/icons/icon_success.svg" alt="" />
            </div>

            <h2>Leitura realizada com sucesso.</h2>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-right" style="padding-top: 45px" v-if="rightpage == 'bill'" @click="keyboardSide = 'hide'">
      <h2 class="nfe-title">Cadastrar nota fiscal</h2>

      <div class="card thin">
        <form-nfe-hidden v-if="page == 'scan'"></form-nfe-hidden>
        <camera-qrcode v-if="page == 'qrcode'" />
        <camera-photo @goToRegister="openRegister" v-if="page == 'photo'" />
        <!-- <form-nfe @goToPhoto="openPhoto" :image="image" v-if="page == 'register'" /> -->

        <div class="bill-register-container" v-if="page == 'register'">
          <h2>Cadastro de nota fiscal</h2>

          <hr style="margin: 20px 0px">
          <span>
            Informe os dados da nota fiscal
          </span>
          <br /><br />
          <form @click.stop="">
            <ok-input :model="newBill.code" @updated="(x) => {
              newBill.code = x;
            }
              " :label="'Número da nota*'" :placeholder="' '" :id="'code'" @onFocus="onFocus('code', 'left')">
            </ok-input>

            <div @click="keyboardSide = 'hide'" style="width: 100%;">
              <ok-input :model="newBill.store" @updated="(x) => {
                newBill.store = x;
              }
                " :label="'Loja*'" :placeholder="' '" :options="storeListNames" :id="'store'"
                @onFocus="onFocus('store', 'left')">
              </ok-input>
            </div>

            <div @click="keyboardSide = 'hide'" style="width: 100%;">
              <ok-input :model="newBill.payment_type" @updated="(x) => {
                newBill.payment_type = x;
              }
                " :label="'Forma de pagamento*'" :placeholder="' '" :options="paymentMethods" :id="'payment-type'"
                @selected="checkPaymentType" @onFocus="onFocus('payment_type', 'left')">
              </ok-input>
            </div>

            <!-- TODO se for colocar de volta, seguir padrao do desktop, alterar para aceleradores -->
            <!-- <ok-input :model="newBill.card_flag" @updated="
              (x) => {
                newBill.card_flag = x;
              }
            " v-if="showFlags" :label="'Bandeira*'" :placeholder="' '" :options="flagsOptions" :id="'payment-type'">
            </ok-input> -->

            <!-- <ok-input :model="newBill.purchase_date" @updated="
              (x) => {
                newBill.purchase_date = x;
              }
            " :label="'Data da compra*'" :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'" class="half-input"
              :isDate="true" :id="'purchase-date'" @onFocus="onFocus('purchase_date', 'left')">
            </ok-input> -->

            <div class="input-frame half-input">
              <input id="purchase_date" name="purchase_date" placeholder="DD/MM/AAAA" v-model="newBill.purchase_date"
                v-on:focus="onFocus('purchase_date', 'left')" v-mask="'##/##/####'" autocomplete="off" />
              <label for="purchase_date"><span>Data da compra*</span></label>
            </div>

            <div class="input-frame half-input" @click="onFocus('price', 'left')">

              <money id="price" name="price" placeholder=" " v-model="newBill.price">
              </money>
              <label for="price"><span>Valor da compra*</span></label>
            </div>

            <!-- <ok-input :model="newBill.price" @updated="(x) => {
            newBill.price = x;
          }
            " :label="'Valor da compra*'" :isCurrency="true" :placeholder="' '" class="half-input" :id="'price'"
            @onFocus="onFocus('price', 'left')">
          </ok-input> -->
            <button type="button" class="primary" @click="backToPhoto()">Cancelar</button>
            <button type="button" class="secundary left-space" @click="save()" :disabled="isSending">Confirmar</button>
          </form>
        </div>
        <div class="camera-bottom" v-if="page == 'qrcode'">
          <p>Problemas no QRCode ?</p>
          <button @click="toggleCameraType()">Enviar foto da nota fiscal</button>
        </div>
        <div class="camera-bottom photo" v-if="page == 'photo'">
          <button @click="openQRCode()" v-if="campaign.gears.control.send_qrcode">Voltar</button>
        </div>
      </div>
    </div>

    <div class="page-right" style="padding-top: 45px" v-if="rightpage == 'coupon'" @click="keyboardSide = 'hide'">
      <h2 class="nfe-title">Dados do cupom</h2>
      <div v-if="cupom" class="card wide">
        <div class="qrcode">
          <center>
            <qrcode-vue :value="cupom._id" :size="150"></qrcode-vue>
          </center>
        </div>
        <div class="info">
          <table>
            <tr>
              <td class="label">Número</td>
              <td>{{ campaign.gears.type_cupom.is_enable ? addZeros(cupom.number, campaign.gears.type_cupom.final_value) :
                cupom.number }}</td>
            </tr>
            <tr>
              <td class="label">Criação</td>
              <td v-if="cupom.created_at">{{ cupom.created_at | formatDateTime }}</td>
            </tr>
            <tr>
              <td class="label">Troca</td>
              <td v-if="cupom.status == 'EXCHANGED'">{{ cupom.updated_at | formatDateTime }}</td>
              <td v-else-if="cupom.status == undefined"></td>
              <td v-else>-</td>
            </tr>
            <tr>
              <td class="label">Status</td>
              <td>{{ ticketStatus[cupom.status] }}</td>
            </tr>
          </table>
        </div>
        <div class="clean"></div>
        <div class="back">
          <button class="back-btn" @click="backToRegister">Voltar</button>
        </div>
      </div>
    </div>

    <div class="page-right" v-if="rightpage == 'sucesso'" @click="keyboardSide = 'hide'">
      <rating />
    </div>

    <div class="page-right" style="padding-top: 45px" v-if="rightpage == 'nfe'" @click="keyboardSide = 'hide'">
      <h2 class="nfe-title">Dados da Nota Fiscal</h2>
      <div class="card wide">
        <div class="image" :class="{ 'clean-bg': order.image }">
          <div v-if="order.image">
            <img style="width: 100%;" :src="getImageThumbUrl(order.image)" alt="" />
          </div>
          <div class="img-empty" v-else></div>
        </div>
        <div class="info">
          <table>
            <tr>
              <td class="label">Número</td>
              <td>{{ order.code }}</td>
            </tr>
            <tr v-if="order.store">
              <td class="label">CNPJ</td>
              <td>{{ order.store.document | toCNPJ }}</td>
            </tr>
            <tr>
              <td class="label">Data</td>
              <td>{{ order.purchase_date | formatDate }}</td>
            </tr>
            <tr>
              <td class="label">Valor</td>
              <td>{{ order.price | toCurrency }}</td>
            </tr>
            <tr>
              <td class="label">Status</td>
              <td>{{ orderStatus[order.status] }}</td>
            </tr>
            <tr>
              <td class="label">Pagt.</td>
              <td>{{ paymentTypes[order.payment_type] }}</td>
            </tr>
            <tr>
              <td class="label">Obs</td>
              <td>{{ order.error_message }}</td>
            </tr>
          </table>
        </div>
        <div class="clean"></div>
        <div class="back">
          <button class="back-btn" @click="backToRegister">Voltar</button>
        </div>
      </div>
    </div>
    <VirtualKeyboard @onKeyPress="onKeyPress" :inputId="editVar" :side="keyboardSide" />
  </layout>
</template>


<script >
import Layout from "../layout/base";
import CampaignMixin from "@/mixins/campaign.mixin.js";
import UserMixin from "@/mixins/user.mixin.js";
import CompanyMixin from "@/mixins/company.mixin";
import OrderMixin from "@/mixins/order.mixin";
import BillList from "./painel-bill-list.vue";
import CouponList from "./painel-coupon-list.vue";
// import SendBill from "./painel-send-bill.vue";
import Rating from "./painel-rating.vue";
import QrcodeVue from 'qrcode.vue'
import CameraQrcode from "@/components/camera-qrcode.vue";
import CameraPhoto from "@/components/camera-photo.vue";
// import FormNfe from "@/components/form-nfe.vue";
import FormNfeHidden from "@/components/form-nfe-hidden.vue";
import OkInput from "@/components/ok-input.vue";
import VirtualKeyboard from "@/components/virtual-keyboard.vue";
// import FormUser from "../../../components/form-user.vue";

export default {
  mixins: [CampaignMixin, UserMixin, CompanyMixin, OrderMixin],
  components: {
    Layout,
    BillList,
    CouponList,
    // SendBill,
    Rating,
    QrcodeVue,
    CameraQrcode,
    CameraPhoto,
    // FormNfe,
    FormNfeHidden,
    OkInput,
    VirtualKeyboard,
    // FormUser,
  },
  data() {
    return {
      customer: {

      },
      home: "",
      leftpage: "notas",
      rightpage: "bill",
      cupom: null,
      order: null,
      ticketValue: 0,
      currentBalance: 0,
      orderStatus: {
        NULL: null,
        ABANDONED: "Abandonado",
        AWAIT: 'Esperando',
        AWAIT_PAYMENT: 'Esperando Pagamento',
        PAID: 'Pago',
        PROCESSING: 'Processando',
        FINISH: 'Finalizado',
        SEND: "Enviado",
        CANCEL: "Cancelado",
        DENIED: "Reprovado"
      },
      ticketStatus: {
        ACTIVE: 'Ativo',
        PRINTED: 'Impresso',
        EXCHANGED: 'Trocado',
        CANCELED: 'Cancelado',
        CANCEL: 'Cancelado'
      },

      image: null,
      code: "",
      waiting: false,
      newBill: {
        code: '',
        store: '',
        payment_type: '',
        price: '',
        purchase_date: '',
        quizz_answer: ''
      },
      slugCampaign: '',
      shoppingName: '',
      storeList: [],
      storeListNames: [],
      isOpenQuestion: false,
      showFlags: false,
      savedPhoto: null,
      keyboardSide: 'hide',
      editVar: '',
      page: '',
    };
  },
  watch: {
    campaign(newValue) {
      this.ticketValue = this.campaign.gears.control.value
      console.log("Campaign", newValue);
    },
    campaignHome(newValue) {
      this.home = `totem${newValue}`;
    },

    $route() {
      var hash = window.location.hash.substr(1);
      this.leftpage = hash;

      if (hash === 'profile') this.rightpage = hash
      else if (hash === 'sucesso') this.rightpage = hash
      else this.rightpage = 'bill'
      if (this.leftpage === "") this.leftpage = "notas";
      this.loadBalance()
    },
  },
  mounted: function () {
    var hash = window.location.hash.substr(1);
    this.leftpage = hash;

    if (hash === 'profile') this.rightpage = hash
    else if (hash === 'sucesso') {
      this.rightpage = hash
      this.leftpage = hash
    }
    else this.rightpage = 'bill'
    if (this.leftpage === "") this.leftpage = "notas"
    this.loadBalance()

    if (this.campaign.gears.control.scan_qrcode) this.page = 'scan'
    else if (this.campaign.gears.control.send_qrcode) this.page = 'qrcode'
    else if (this.campaign.gears.control.upload_image) this.page = 'photo'
    else if (this.campaign.gears.control.allow_customer_send_NFdata) this.page = 'register'
    // this.page = 'register' //TODO remover apos testes

    this.getStores()
    this.savedPhoto = this.image
  },
  methods: {
    onBillSelected(item) {
      this.rightpage = "nfe";
      console.log("Bill: ", item);
      this.order = item;
    },
    onCouponSelected(item) {
      this.rightpage = "coupon";
      console.log("Coupon: ", item);
      this.cupom = item;
    },
    onProfileSelected(item) {
      this.rightpage = "profile";
      this.leftpage = 'profile'
      console.log("profile: ", item);
      this.cupom = item;
    },
    backToRegister() {
      this.rightpage = "bill";
    },
    loadBalance() {
      const self = this
      const user = JSON.parse(localStorage.user);
      this.getUserBalance(user._id).then((response) => {
        self.userBalance = response.balance ? response.balance[0].balance : null

        self.currentBalance = self.userBalance.keep_residual_value ? self.userBalance.balance_with_residual : self.userBalance.balance_amount_free
      })
    },
    checkUserAgree() {
      this.userAgree = true;
      if (this.campaign && this.campaign.lgpd.accept_rules) {
        if (!this.userAgreeRules) {
          this.userAgree = false;
        }
      }

      if (this.campaign && this.campaign.lgpd.accept_terms) {
        if (!this.userAgreeTerms) {
          this.userAgree = false;
        }
      }
    },

    toggleCameraType() {
      if (this.page == "qrcode") this.page = "photo";
      else if (this.page == "photo") this.page = "register";
      else this.page = "qrcode";
    },
    openQRCode() {
      if (this.campaign.gears.control.send_qrcode) this.page = "qrcode"
    },
    openRegister(img) {
      this.image = img
      this.page = 'register'
    },
    openPhoto() {
      this.page = 'photo'
    },
    save() {
      const self = this
      const shopping = localStorage.shoppingSlug
      const campaign = localStorage.campaignSlug
      const user = JSON.parse(localStorage.user)
      const billCopy = JSON.parse(JSON.stringify(this.newBill))

      if (billCopy.purchase_date) billCopy.purchase_date = this.stringToDate(billCopy.purchase_date)

      const validateBillData = this.validateBillForm(billCopy);
      if (!validateBillData.validated) {
        this.$swal({
          icon: "error",
          title: "",
          text: validateBillData.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return null;
      }

      billCopy.user = user._id
      billCopy.company = shopping
      billCopy.campaign = campaign

      // const question = "question-" + this.campaign.company.slug + '-' + this.campaign.slug
      // if (localStorage[question]) billCopy.quizz_answer = localStorage[question]

      // if (billCopy.payment_type != 'CREDIT') billCopy.card_flag = null
      if (this.savedPhoto != null) billCopy.image = this.savedPhoto._id
      this.isSending = true
      this.keyboardSide = 'hide'
      this.createBill({
        invoice: billCopy,
        shopping: shopping,
        campaign: campaign,
      }).then((response) => {
        self.isSending = false
        if (response && response != undefined) {
          localStorage.setItem('selectedStore', billCopy.store)
          let path = this.campaignHome + '/sucesso'
          if (this.$route.fullPath.indexOf('/totem/') == -1) window.open(`${path}`, "_self")
          else window.open(`totem${this.campaignHome}/painel#sucesso`, "_self")
        }
      }, (error) => {
        console.log(error)
        self.isSending = false
      })
    },
    backToPhoto() {
      this.$emit("goToPhoto")
    },
    getStores() {
      let self = this;

      this.listStores().then((response) => {
        self.storeList = response
        self.storeListNames = response.map(item => {
          let obj = {
            name: item.name_fancy,
            value: item._id,
          }
          return obj
        })
      })
    },
    checkPaymentType() {
      if (this.newBill.payment_type === 'CREDIT') this.showFlags = true
      else this.showFlags = false
    },

    isSelected(selectedOption) {
      if (this.newBill.quizz_answer == selectedOption) return true
      else return false
    },
    selectOption(option) {
      this.newBill.quizz_answer = option
    },
    onKeyPress(button) {
      console.log("onKeyPress", button, this.editVar)
      if (button === '{bksp}') {
        if (this.editVar === 'price') {
          let numString = this.newBill.price.toString()
          numString = numString.replace(/\./g, '')
          numString = numString.slice(0, -1)
          const splitedString = this.splitString(numString)
          numString = splitedString[0] + '.' + splitedString[1]
          let result = parseFloat(numString)
          this.newBill[this.editVar] = result
        } else {
          const newValue = this.newBill[this.editVar].slice(0, -1)
          this.newBill[this.editVar] = newValue
        }
      } else if (button === '{space}') {
        this.newBill[this.editVar] += ' '
      } else if (button === '{tab}' || button === '{lock}' || button === '{shift}' || button === '{enter}') {
        return
      } else {
        console.log({ keyboardSide: this.keyboardSide, editVar: this.editVar, code: this.newBill.code, price: this.newBill.price })
        // console.log('preço antes', this.newBill.price)
        const isMoney = this.editVar === 'price' ? true : false
        this.newBill[this.editVar] = this.insertOnObj(this.newBill[this.editVar], button, isMoney)
        // console.log('preço depois', this.newBill.price)
      }
    },
    onFocus(input, side) {
      this.editVar = input;
      this.keyboardSide = side
      this.$forceUpdate();
      console.log("Focus", this.editVar);
    },
    splitString(string) {
      var lastCaracters = string.substring(string.length - 2);
      var firstsCaracters = string.substring(0, string.length - 2);
      return [firstsCaracters, lastCaracters];
    }
  },
};
</script>


<style  lang="scss" >
@import "~@/scss/colors.scss";

.painel {

  h2.nfe-title {
    color: var(--text-color);
    font-weight: 400;
    font-size: 16pt;
    margin-bottom: 20px;
  }

  .page-left {
    grid-area: left;
    padding: 35px;
    padding-bottom: 0px;
    background-color: var(--bg-color);
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &.dark {
      background-color: var(--bg-color-dark);
    }

    .rules-conditions,
    .terms-conditions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        position: relative;
        top: 2vh;

        align-self: center;
        font-size: 0.95rem;
        font-weight: 500;

        strong {
          font-weight: 00;
        }
      }
    }

    form {
      padding: 0px 15px;
    }

    .success {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-top: 100px;

      #icon-success {
        border: 1px solid #dbd8d8;
        border-radius: 50%;
        width: 160px;

        img {
          width: 160px;
          padding: 37px;
        }
      }

      h2 {
        margin-top: 20px;
        font-size: 24px;
      }

      p {
        color: $span-color;
        width: 70%;
      }
    }
  }

  .page-right {
    grid-area: right;
    padding: 35px;
    padding-bottom: 0px;
    background-color: var(--bg-color-dark);
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .bill-register-container {
      width: 100%;
      padding: 45px 35px;

      h2 {
        color: var(--primary);
        font-weight: bold;
        font-size: 18px;
      }


      hr {
        border-color: $bg-color;
      }

      form {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        column-gap: 10px;

        button {
          margin-top: 1vh;

          &:disabled {
            background: #ccc !important;
            cursor: not-allowed;
          }
        }

        .half-input {
          width: calc(50% - 5px);
        }
      }

      .form-buttons {
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 10px;

        button {
          float: right;
          margin-left: 10px;
          border-radius: 5px;
        }
      }

    }
  }

  header {
    img.logo {
      // padding: 10px;
      height: 40px;
    }

    .campaign-info {
      width: 300px;
      float: right;
      text-align: right;
      margin-top: 10px;
    }
  }

  .campaign-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
      // max-height: 40vh;
    }

    button {
      bottom: 15px;
    }
  }

  .card {
    position: relative;
    background-color: var(--bg-color);
    box-shadow: $shadow-border;
    max-width: 80%;
    padding: 50px 20px;
    margin: auto;

    &.wide {
      max-width: 100%;

      display: grid;
      // grid-template-rows: 7.2vh 1fr 7vh;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        "left right"
        "clean back";

      .image {
        grid-area: left;
      }

      .info {
        grid-area: right;
      }

      .back {
        grid-area: back;

        .back-btn {
          border-radius: 0px;
          width: 230px;
          float: right;
          background-color: var(--secundary);
        }
      }
    }

    &.thin {
      max-width: 90%;
      padding: 0;
    }

    .image {
      width: 80%;
      max-width: 420px;
      min-height: 400px;
      background: var(--bg-color-dark);
      display: block;
      margin: auto;

      &.clean-bg {
        background: transparent
      }
    }


    .info {
      background: var(--bg-color-dark);
      padding: 10px 10px;
      font-size: 10pt !important;

      table {
        width: 100%;

        td {
          padding: 10px 0px;
          text-align: left;

          &.label {
            font-weight: bold;
            color: var(--primary);
          }
        }
      }
    }
  }

  .balance {
    position: relative;
    width: 100%;

    .values {
      position: absolute;
      top: 38px;
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      .current-value {
        flex: 1;
        text-align: left;
        font-weight: bold;
      }

      .ticket-value {
        flex: 1;
        text-align: right;
      }
    }

    .colored-bar {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      height: 20px;
      position: absolute;
      top: 0;
      z-index: 0;

      .left {
        background-color: #8dcd21;
        flex: 1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .middle {
        background-color: #ffc00a;
        flex: 1;
      }

      .right {
        background-color: #ee2a7b;
        flex: 1;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .balance-slider {
      -webkit-appearance: none;
      /* Override default CSS styles */
      appearance: none;
      width: 100%;
      /* Full-width */
      height: 20px;
      /* Specified height */
      background: transparent;
      /* Grey background */
      outline: none;
      /* Remove outline */
      -webkit-transition: 0.2s;
      /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;
      border-radius: 25px;
      position: absolute;
      left: 0;
      z-index: 1;
    }

    /* Mouse-over effects */
    .balance-slider:hover {
      opacity: 1;
      /* Fully shown on mouse-over */
    }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .balance-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      /* Override default look */
      appearance: none;
      width: 12px;
      /* Set a specific slider handle width */
      height: 50px;
      /* Slider handle height */
      background: white;
      border: 1px solid black;
      /* Green background */
    }

    .balance-slider::-moz-range-thumb {
      width: 12px;
      height: 50px;
      background: white;
      border: 1px solid black;
    }
  }

  .camera-bottom {
    position: absolute;
    bottom: 0px;
    background: #000000;
    width: 100%;
    height: 52px;

    &.photo {
      button {
        float: left;

        margin-right: 0px;
        margin-left: 10px;
        width: 45%;
      }
    }

    p {
      width: 210px;
      float: left;
      color: #ffaa00;
      display: block;
      padding: 18px 15px;
    }

    button {
      padding: 8px;
      background-color: gray;
      box-shadow: none;
      float: right;
      border: none;
      width: auto;
      margin: 0;
      height: 100%;
      border-radius: 0px;
      margin-top: 7px;
      margin-right: 5px;
      height: 36px;

      &:hover {
        background-color: gray;
      }
    }

  }
}
</style>

