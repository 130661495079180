<template>
  <MainLayout>
    <div class="top-title">
      <h1>Alterar Senha</h1>
    </div>

    <div class="reset-password-container">
      <form action="submit">
        <input type="text" placeholder="Informe seu Email cadastrado" v-model="$v.email.$model" :id="'email'" />
        <input type="password" placeholder="Digite sua nova senha" v-model="$v.password.$model" :id="'password'" />
        <input type="password" placeholder="Confirme sua nova senha" v-model="$v.confirm.$model" :id="'confirm'" />

        <button type="button" class="primary" @click="reset()">
          Alterar
        </button>

        <hr />

        <p class="question">Ainda não é cadastrado ?</p>

        <button type="button" class="primary_border" @click="register()">
          Criar conta
        </button>
      </form>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import { required, email } from "vuelidate/lib/validators";
import userMixin from "../mixins/user.mixin";

export default {
  mixins: [userMixin],
  components: { MainLayout },
  data: function () {
    return {
      email: "",
      password: "",
      confirm: "",
      token: "",
    };
  },
  validations: {
    email: { email, required },
    password: { required },
    confirm: { required },
    token: ''
  },
  mounted() {
    const id = this.$route.params.id
    this.token = id
  },
  methods: {
    reset() {
      let path = encodeURI(window.location.pathname);
      var [base, shopping, campaign] = path.split("/");
      console.log(base);
      let data = {
        company: shopping,
        campaign,
        email: this.email,
        password: this.password,
        confirm: this.confirm,
        token: this.token,
      }
      this.resetPassword(data)
    },
    register() {
      let path = encodeURI(window.location.pathname);
      var [base, shopping, campaign] = path.split("/");
      console.log(base);
      window.open(`${shopping}/${campaign}/user/register`, "_self");
    },
  },
};
</script>

<style lang="scss">
.reset-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    margin-top: 7vh;
    width: 80%;

    hr {
      margin: 6vh 0;
    }
  }
}
</style>