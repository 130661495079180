<template>
  <section class="camera-layout-container" :style="style">
    <Header :title="title"  />
    <main >

      <section>
        <slot></slot>
      </section>
    </main>
    <footer>
      <CookieLaw theme="base">
        <div slot="message">
          Este site usa cookies para garantir que você obtenha a melhor
          experiência em nosso site.
        </div>
        <div slot="buttonText" buttonText="Aceito">
          Aceito
        </div>
      </CookieLaw>
    </footer>
  </section>
</template>

<script>
import Header from "../components/header";
// import SideMenu from "../components/side-menu";
import CookieLaw from "vue-cookie-law";
import CampaignMixin from "../mixins/campaign.mixin.js";

export default {
  mixins: [CampaignMixin],
  // mixins: [sessionMixin],
  data: function () {
        return {
            logged: false
        }
    },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    
  },
  components: {
    Header,
    // SideMenu,
    CookieLaw,
    // CompanyMenu,
  },
  mounted () {
    if (localStorage.token) {
      this.logged = true;
    }
  },
  watch: {
    'localStorage.token' (newValue) {
      console.log("Watch token ", newValue);
      if (newValue) {
      this.logged = true;
    }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/global.scss";
@import "../scss/colors.scss";
@import "../scss/form.scss";
@import "../scss/vue-select.scss";

.camera-layout-container {
  height: 100vh;
  display: grid;
  // grid-template-rows: 7.2vh 1fr 7vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'main'
    'footer';
  
  main {
    grid-area: main;
    // padding: 15px 5px 10px 5px;
    height: 100%;
    // display: grid;
    // grid-template-columns: minmax(150px, 15%) 1fr;
    padding: 0;
    margin: 0;
    overflow-x: scroll;

      .top-title {
        // color:  var(--primary);
        background-color:  var(--primary);

        h1 {
          text-align: center;
        }
      }

      button.primary {
        position: relative;
        background-color:  var(--primary);
      }

      button.primary:hover{
        background-color:  var(--primary-dark);
      }

      button.primary_border {
        position: relative;
        color:  var(--primary);
        border-color: var(--primary);
        background: transparent;
        border: 1px solid
      }

      button.primary_border:hover{
        background-color:  var(--primary-dark);
        border-color: var(--primary-dark);
      }

  }

  footer{
    grid-area: footer;
  }


  .Cookie {
    border-top: 1px solid $primary;

    .Cookie__button {
      background: $primary;
    }
  }
}

@media only screen and (max-width: 800px) {
  .camera-layout-container {
    main {
      display: block;
    }
  }
}
</style>
