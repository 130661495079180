<template>
  <footer :style="style">
    <div v-if="type == 'home'">
      <button class="footer-button" @click="goToHome('lojas')">
        Lojas participantes
      </button>
      <button class="footer-button" @click="goToHome('regulamento')">
        Regulamento
      </button>
      <button class="footer-button align-right" v-if="this.$route.hash !== ''" @click="goToHome('')">
        Voltar
      </button>
      <button class="footer-button keyboard-btn align-right" :class="{ 'keyboard-enabled': showKeyboard }"
        @click="toogleVirtualKeyboard" v-else>
        <i class="fas fa-keyboard"></i>
      </button>
    </div>

    <div v-if="type == 'register'">
      <button class="footer-button" @click="goBack('')">
        Voltar
      </button>

      <button class="footer-button align-right" @click="saveUser(false)" :disabled="isSending">
        Salvar
      </button>
    </div>

    <div v-if="type == 'painel'">
      <button class="footer-button" @click="goToPainel('notas')">
        Notas fiscais
      </button>
      <button class="footer-button" @click="goToPainel('cupons')">
        Cupons
      </button>
      <button class="footer-button" @click="goToProfile()">
        Meus Dados
      </button>

      <button class="footer-button align-right" @click="exit()">
        Sair
      </button>
      <button class="footer-button align-right darker"
        v-if="this.$route.path === `/totem${this.campaignHome}/painel/profile`" @click="saveUser(true)"
        :disabled="isSending">
        Salvar
      </button>
    </div>

  </footer>
</template>

<script>
import CampaignMixin from "@/mixins/campaign.mixin.js";
import UserMixin from "@/mixins/user.mixin.js";

export default {
  mixins: [CampaignMixin, UserMixin],
  //   name: "Footer",
  data: function () {
    return {
      type: "home",
      localUser: {}
    }
  },
  // watch: {
  //   $route (to, from){
  //     console.log(to);
  //     console.log(from);
  //   }
  // },
  mounted: function () {
    // console.log(this.campaignHome);
    let path = encodeURI(window.location.pathname);
    console.log('aui esta this.$route', this.$route)
    if (path.indexOf('user/register') > -1) this.type = 'register';
    else if (path.indexOf('painel') > -1) this.type = 'painel';
  },
  methods: {
    goToHome(hash) {
      window.open(`totem${this.campaignHome}#${hash}`, "_self");
      // this.$forceUpdate();
    },
    goToPainel(hash) {
      window.open(`totem${this.campaignHome}/painel#${hash}`, "_self");
      // this.$forceUpdate();
    },
    goToProfile() {
      window.open(`totem${this.campaignHome}/painel/profile`, "_self");
      // this.$forceUpdate();
    },
    goBack() {
      window.history.back();
    },
    exit() {
      this.logout();
      const url = `totem${this.campaignHome}`;
      window.open(url, "_self");
    },
    getStoredUser() {
      let address
      if (!localStorage.newUser) return null

      this.localUser = JSON.parse(localStorage.newUser)

      if (localStorage.newAddress) {
        address = JSON.parse(localStorage.newAddress)
        this.localUser.address[0] = address
      }

      return { user: this.localUser, shopping: this.campaign.company.slug, campaign: this.campaign.slug }
    },
    saveUser(isEdit) {
      const self = this;
      let data = self.getStoredUser()
      if (!data) return
      if (this.campaign && this.campaign.lgpd.accept_rules && !data.user.lgpd.accept_rules) {
        self.$swal({
          icon: "error",
          title: "Atenção",
          text: 'É obrigatório aceitar o regulamento da campanha',
          // footer: '<a href>Why do I have this issue?</a>'
        })
        return
      }

      if (isEdit) {
        for (let address of data.user?.address) {
          address.zipcode = address.zipcode.replace(/\D/g, "");
        }
      }

      const validateUserData = this.validateForm(this.campaign, data.user, isEdit);
      if (!validateUserData.validated) {
        this.$swal({
          icon: "error",
          title: "",
          text: validateUserData.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return null;
      }
      if (data.user.birthday) data.user.birthday = this.stringToDate(data.user.birthday)
      this.isSending = true
      if (isEdit) {
        self.updateUser(data).then((response) => {
          self.isSending = false
          if (response && response != undefined) {
            window.open(`totem${self.campaignHome}/painel`, "_self")
          }
        }, (error) => {
          console.log(error)
          self.isSending = false
        })
      } else {
        self.createUser(data).then(response => {
          self.isSending = false
          if (response) {
            window.open(`totem${self.campaignHome}/painel`, "_self")
          }
        }, (error) => {
          console.log(error)
          self.isSending = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/scss/colors.scss";

footer {
  // height: 78px;
  background: var(--primary);
  padding: 0px 15px;


  .footer-button {
    width: 200px;
    height: 50px;
    margin: 10px 5px;
    background: #00000011;
    color: #fff;
    border: none;
    cursor: pointer;

    &.align-right {
      float: right;
      background: #00000044;
    }

    &.darker {
      background: #00000080;
    }
  }

  .keyboard-btn {
    border: none;
    width: 50px;
    height: 50px;
    font-size: 27px;

    &.keyboard-enabled {
      // background-color: var(--secundary);
      background: #00000085;
    }
  }
}
</style>