<template>
  <section>
    <div class="camera-qrcode" :style="style">
      <div v-if="readQrcode">
        <div class="camera-content">
          <div class="button-area">
            <img class="qrcode-frame" src="../assets/icons/qrcode_frame.svg" alt="" />
          </div>
        </div>

        <div class="screen" v-if="readQrcode">
          <qrcode-stream d="qrcode-stream" @decode="onDecode" v-if="readQrcode">
          </qrcode-stream>
        </div>

      </div>

      <div v-if="process">
        <h2>Processando sua nota...</h2>
      </div>
    </div>

  </section>
</template>

<script>
import CampaignMixin from "../mixins/campaign.mixin.js";
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  // name: "BillQrCode",
  mixins: [CampaignMixin],
  components: {
    QrcodeStream
  },
  data() {
    return {
      photo: null,
      QRCode: "",
      readQrcode: true,
      process: false,
      isOpenQuestion: false
    };
  },
  mounted() {

  },
  methods: {
    openSuccess() {
      window.open(this.campaignHome + "/sucesso", "_self");
    },
    onDecode(decodedString) {
      if (decodedString.trim() != "") {
        let self = this;
        this.QRCode = decodedString;
        this.process = true;
        this.takePhoto = false;
        this.readQrcode = false;

        const question = "question-" + this.campaign.company.slug + '-' + this.campaign.slug
        let answer
        if (localStorage[question]) answer = localStorage[question]

        this.sendBillQRCode(decodedString, answer).then((response) => {
          console.log(response);
          self.process = false;
          self.QRCode = "";

          if (response) {
            self.openSuccess();
            self.sucess = true;
          } else {
            if (self.campaign.gears.control.upload_image) {
              self.openCamera();
              self.takePhoto = true;
            } else {
              self.QRCode = decodedString;
              self.process = false;
              self.readQrcode = true;
              self.sucess = false;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" >
@import "~@/scss/colors.scss";

.camera-qrcode {
  position: relative;
  //   min-height: calc(100vh - 100px);
  width: 100%;
  //   aspect-ratio: 9 / 10;
  height: 70vh;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .top-title {
    position: relative;
    z-index: 100;

    h1 {
      font-size: 11pt !important;
    }
  }

  .camera-content {
    position: relative;
    z-index: 100;

    .qrcode-frame {


      position: relative;
      height: 70%;
      margin: auto;
      margin-top: 20%;
    }

    .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
  }

  h2 {
    text-align: center;
    margin-top: 20%;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;


    video {
      position: absolute;
      height: auto;
      width: 100%;
    }
  }

}

.qrcode-stream-camera,
.qrcode-stream-pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}

@media only screen and (max-width: 800px) {
  .camera-qrcode {
    position: relative;
    min-height: calc(100vh - 100px);

    .camera-content {
      .qrcode-frame {
        position: absolute;
        width: 70%;
        height: auto;
        // max-height: 70%;
        margin: 15%;
      }
    }

    .screen {
      height: 100vh !important;
      position: fixed !important;
      top: 0px;

      .qrcode-frame {
        max-height: 100vh;
      }
    }
  }
}
</style>