<template>
  <MainLayout>
    <div class="qrcode-buy-container" :style="style">
      <div class="top-title">
        <h1>Aponte a câmera para o QRcode da nota</h1>
      </div>
      
      <div class="cameras-content">
        <div class="camera">
          <img
            class="qrcode-frame"
            src="../assets/icons/qrcode_frame.svg"
            alt=""
          />
        </div>
      </div>

    
      <div class="screen" v-if="readQrcode">
        <qrcode-stream d="qrcode-stream" @decode="onDecode" v-if="readQrcode">
        </qrcode-stream>
      </div>

      <div v-if="process">
        <h2>Processando sua nota...</h2>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/camera";
import CampaignMixin from "../mixins/campaign.mixin.js";
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  // name: "BillQrCode",
  mixins: [CampaignMixin],
  components: {
    MainLayout,
    QrcodeStream
  },
  data() {
    return {
      photo: null,
      QRCode: "",
      readQrcode: true,
      process: false,
      isOpenQuestion: false
    };
  },
  mounted() {
    //this.$refs.rootElement.requestFullscreen();
    if (this.campaign) {
      if (this.campaign.layout.question_show) {
        this.isOpenQuestion = true;
      }
      var token = "question-" + this.campaign.company.slug +'-'+ this.campaign.slug;
      console.log(token);

      if (localStorage[token]) {
        console.log("passed");
        this.isOpenQuestion = false;
      }
    }

    console.log("isOpenQuestion", this.isOpenQuestion);
  },
  methods: {
    openPayment(store) {
      // window.open(this.campaignHome + "/confirmar-compra", "_self");
      this.$router.push({name: 'confirmOrder', params: {store: store}}) 
    },
    onDecode(decodedString) {
      if (decodedString.trim() != "") {
        // let self = this;
        console.log('decodedString', decodedString);
        let decodedObject = JSON.parse(decodedString);
        console.log('decodedObject', decodedObject);
        
        this.QRCode = decodedObject;
        this.process = true;
        this.takePhoto = false;
        this.readQrcode = false;

        this.openPayment(decodedObject);
        
      }
    },
  },
};
</script>

<style lang="scss" >
@import "../scss/colors.scss";

.qrcode-buy-container {
  position: relative;
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .top-title {
    position: relative;
    z-index: 100;

    h1 {
      font-size: 11pt !important;
    }
  }

  .cameras-content{
    position: relative;
    z-index: 100;

    .camera{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }
    
    .qrcode-frame {
      position: relative;
      height: 70%;
      margin: auto;
      margin-top: 5%;
    }

  }

  h2 {
    text-align: center;
    margin-top: 20%;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;


    video {
      position: absolute;
      height: auto;
      width: 100%;
    }
  }

  .footer-content {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;

    flex-direction: row;
    padding: 10px 0px;
  }
}

.qrcode-stream-camera,
.qrcode-stream-pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}

@media only screen and (max-width: 800px) {
  .qrcode-buy-container {
    position: relative;
    min-height: calc(100vh - 100px);

    .camera-content{
      .qrcode-frame{
        position: absolute;
        width: 70%;
        height: auto;
        // max-height: 70%;
        margin: 15%;
      }
    }

    .screen {
      height: 100vh !important;
      position: fixed !important;
      top: 0px;

      .qrcode-frame {
        max-height: 100vh;
      }
    }
  }
}
</style>