var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',[_c('div',{staticClass:"top-title"},[_c('h1',[_vm._v("Pesquisa")])]),(_vm.survey != null)?_c('div',{staticClass:"survey-view"},[_c('h2',{staticClass:"survey-name"},[_vm._v(_vm._s(_vm.survey.name))]),(_vm.answers && _vm.answers.length > 0)?_c('form',{attrs:{"onSubmit":"return false;"}},[_vm._l((_vm.survey.questions),function(question,index){return _c('div',{key:question.title,staticClass:"question"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(question.title))]),(question.type == 'MULTIPLE')?_c('div',_vm._l((question.options),function(option,optionIndex){return _c('div',{key:option.title,staticClass:"radio-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.answers[index].answer_text),expression:"answers[index].answer_text"}],staticClass:"input-radio",attrs:{"type":"radio","id":'question-' + index + '-option-' + optionIndex},domProps:{"value":option.title,"checked":_vm._q(_vm.answers[index].answer_text,option.title)},on:{"change":function($event){return _vm.$set(_vm.answers[index], "answer_text", option.title)}}}),_c('label',{attrs:{"for":'question-' + index + '-option-' + optionIndex}},[_vm._v(_vm._s(option.title))]),(question.other_enable && question.options.length == optionIndex + 1)?_c('div',[_c('ok-input',{staticStyle:{"margin-left":"20px"},attrs:{"model":_vm.answers[index].answer_number,"label":'Resposta',"placeholder":' ',"id":'other-answer'},on:{"updated":function (x) {
                  _vm.answers[index].answer_number = x;
                }}})],1):_vm._e()])}),0):_c('div',[(question.answer_type == 'TEXT')?_c('ok-input',{staticStyle:{"width":"80%"},attrs:{"model":_vm.answers[index].answer_text,"label":'Resposta',"placeholder":' ',"id":'answer-' + (index + 1)},on:{"updated":function (x) {
              _vm.answers[index].answer_text = x;
            }}}):_vm._e(),(question.answer_type == 'NUMBER')?_c('ok-input',{staticStyle:{"width":"80%"},attrs:{"model":_vm.answers[index].answer_number,"label":'Resposta',"placeholder":' ',"id":'answer-' + (index + 1),"type":'number'},on:{"updated":function (x) {
              _vm.answers[index].answer_number = x;
            }}}):_vm._e(),(question.answer_type == 'DATE')?_c('ok-input',{staticStyle:{"width":"80%"},attrs:{"model":_vm.answers[index].answer_date,"label":'Resposta',"placeholder":' ',"id":'answer-' + (index + 1),"mask":'##/##/####',"isDate":true},on:{"updated":function (x) {
              _vm.answers[index].answer_date = x;
            }}}):_vm._e()],1)])}),_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('button',{staticClass:"primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.sendAnswer()}}},[_vm._v("Confirmar")])])],2):_vm._e()]):_c('div',[_vm._v(" Não há pesquisa para ser respondida ")])])}
var staticRenderFns = []

export { render, staticRenderFns }