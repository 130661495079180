<template>
  <div class="order">
    <div class="icon">
      <img v-if="order.status == 'DONE'" src="../assets/icons/check_approved.svg" alt="" />
      <img v-if="order.status == 'DENIED'" src="../assets/icons/check_deny.svg" alt="" />
      <img v-if="order.status == 'CANCEL'" src="../assets/icons/check_deny.svg" alt="" />
      <img v-if="order.status == 'AWAIT_ORDER'" src="../assets/icons/check_await.svg" alt="" />
    </div>
    <div class="order-content">
      <div class="top-content">
        <div>
          <p v-if="order.store">
            <strong v-if="order.store.name">{{
                order.store.name
            }}</strong>
            <strong v-if="order.store.name == null && order.status == 'AWAIT_ORDER'">Nota em análise para
              aprovação</strong>
            <strong v-if="order.store.name == null && order.status == 'DENIED'">Loja não identificada</strong>
            <br />
            {{ order.purchase_date | formatDate }}
          </p>
          <p v-else>
            <strong>Loja não identificada</strong>
          </p>
        </div>
        <div>
          <p class="value">
            {{ order.price | toCurrency }} <br />
            <span v-if="order.status == 'DONE'">Aprovada</span>
            <span v-if="order.status == 'DENIED'">Não provada</span>
            <span v-if="order.status == 'AWAIT_ORDER'">Em análise</span>
            <span v-if="order.status == 'CANCEL'">Etornada</span>
          </p>
        </div>
      </div>

      <hr />
      <div class="bottom-content">
        <div v-if="order.code">#{{ order.code }}</div>
      </div>
      <!-- <p v-if="order.status == 'DENIED'">{{order.motivoErro}}</p> -->
      <p v-if="order.status == 'DENIED' || order.status == 'CANCEL'">
        <span v-if="order.error_message">
          {{ order.error_message }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requests: 0,
    };
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";
@import "../scss/form.scss";

.order {
  width: 100%;
  background-color: var(--bg-color-dark);

  padding: 0px 15px;
  padding-left: 20px;
  margin-bottom: 2.5vh;

  border-radius: 20px;

  div.order-content {
    height: 100%;
    position: relative;
    bottom: 3vh;
    text-align: initial;

    p {
      color: $span-color;
      font-size: 9pt;
      margin-top: 8px;
      line-height: 14pt;

      &.date {
        font-size: 9pt;
      }

      &.value {
        text-align: right;
      }
    }

    div.top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0px;
      font-size: 9pt;

      p {
        margin-top: 0.5vh;

        color: $span-color;
      }

      strong {
        font-family: "Roboto";
        font-weight: 900;
        letter-spacing: 0.06rem;
      }

      div>p:first-child {
        margin: 0;
      }
    }

    hr {
      width: 100%;
      margin: 5px 0px;
    }

    div.bottom-content {
      margin-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 9pt;
      color: $span-color;
    }
  }

  .icon {
    display: grid;
    place-items: center;

    position: relative;
    bottom: 16px;
    right: 4vh;
    height: 40px;
    width: 40px;

    border-radius: 100%;
    // background-color: $green;
  }
}
</style>