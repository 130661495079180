<template>
  <MainLayout :style="style">
    <div class="order-confirm-container" v-if="campaign">
      <div class="top-title">
        <h1>Confirmar compra</h1>
      </div>
      <h2>
        {{ order.store }}
      </h2>

      <form>

        <!-- <ok-input
          :model="order.price"
          @updated="
            (x) => {
              order.price = x;
            }
          "
          :type ="'number'"
          :label="'Valor à pagar'"
          :placeholder="' '"
          class="input-price"
          :disabled="isLoading"
        >
        </ok-input> -->

        <money v-model="order.price"></money>

        <ok-input :model="order.installments" @updated="
          (x) => {
            order.installments = x;
          }
        " :label="'Parcelas'" :id="'installments'" :options="installmentsOptions" :placeholder="' '"
          class="input-installment" :disabled="isLoading">
        </ok-input>

        <ok-input :model="order.card" @updated="
          (x) => {
            order.card = x;
          }
        " :label="'Cartão de crédito'" :id="'card'" :options="cardOptions" :placeholder="' '" :disabled="isLoading">
        </ok-input>


      </form>
      <button class="clean" @click="addCardPage()" :disabled="isLoading">Adicionar cartão</button>

      <form class="button-area" style="padding: 20px">
        <button type="button" class="gray" style="width: 100%" @click="goBack" :disabled="isLoading">
          Voltar
        </button>
        <div style="width: 35px"></div>
        <button type="button" class="primary" style="width: 100%" @click="pay()" :disabled="isLoading">
          Comprar
        </button>
      </form>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import orderMixin from "../mixins/order.mixin";
import campaignMixin from "../mixins/campaign.mixin";
import OkInput from "../components/ok-input.vue";

export default {
  mixins: [userMixin, campaignMixin, orderMixin],
  props: ["store"],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      order: {
        store: "",
        storeId: null,
        price: null,
        installments: "1"
      },
      cardList: [],
      selectedCard: {},
      cardOptions: [],
      installmentsOptions: [
        { name: "1x", value: "1" },
        { name: "2x", value: "2" },
        { name: "3x", value: "3" },
        { name: "4x", value: "4" },
        { name: "5x", value: "5" },
        { name: "6x", value: "6" },
      ],
      isLoading: true,
    };
  },
  mounted() {
    if (this.store && this.store != "") {
      this.order.store = this.store.name;
      this.order.storeId = this.store.id;
    }
    // this.loadCards();
  },
  watch: {
    user(newValue) {
      if (newValue._id) {
        this.loadCards();
      }
    },
    campaign(newValue) {
      console.log("Campaign", newValue);
    },
  },
  methods: {
    selectCard(card) {
      this.selectedCard = card;
      // console.log("this.selectedCard", this.selectedCard);
    },
    loadCards() {
      let self = this;
      this.listCards()
        .then((response) => {
          self.listMessage = "Você não possui cartões";
          if (response) {
            // console.log('aqui lista cartao', response)
            for (const card of response) {
              self.cardOptions.push({
                name: "**** **** **** " + card.last_four + " - " + card.brand,
                value: card._id
              });

            }
            if (response.length > 0) {
              self.order.card = response[0]._id;
            }
            self.cardList = response;
          }
          self.isLoading = false;
        })
        .catch(function () {
          self.listMessage = "Você não possui cartões";
          self.isLoading = false;
        });
    },
    pay() {
      let self = this;

      for (const card of this.cardList) {
        if (card._id == this.order.card) {
          this.selectedCard = card;
          break;
        }
      }

      const cardId = this.selectedCard._id;

      this.sendBillPayment(this.order.storeId, this.order.installments, this.order.price, cardId)
        .then(function (response) {
          console.log(response);
          if (response) {
            window.open(self.campaignHome + "/pagamento-sucesso", "_self");
          }
        });
    },
    addCardPage() {
      window.open(this.campaignHome + "/user/cards/register", "_self");
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

// .input-price {
//   width: 60%;
//   float: left;
// }

// .input-installment {
//   width: 40%;
//   float: left;
// }

.order-confirm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;

  h2 {
    color: var(--primary);
    text-align: left;
    width: 80%;
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  .button-area {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
  }

  button.clean {
    background: transparent;
    font-weight: 400;
    color: var(--primary);
    font-size: 10pt;
  }

  button.gray {
    background: #c3c3c3;
    // color: #4d4d4d;
  }

  form {
    width: 100%;
    margin-top: 20px;
    // padding-top: 3vh;
    padding: 20px;
    position: relative;
    display: block;

    .input-frame {
      font-size: 15pt;
      margin-top: 20px;
    }

    button {
      display: block;
      width: 120px;
      height: auto;
      padding: 10px 15px;
      margin: auto;

      &.clean {
        background: transparent;
        color: $text-color;
      }
    }
  }

  .small-input {
    width: 300px;
  }

  .card-list {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 20px;
    justify-content: center;

    .card {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 15px;
      padding: 30px;
      cursor: pointer;
    }

    .card-selected {
      border: 2px solid #ccc;
    }
  }


}

@media only screen and (max-width: 600px) {
  .order-confirm-container {
    margin-bottom: 60px;

    .card-list {
      display: flex;
      flex-flow: column wrap;
      padding-top: 0px;

      .card {
        width: 100%;
        padding: 30px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>