var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{style:(_vm.style)},[_c('div',{staticClass:"bill-register"},[_c('div',{staticClass:"top-title"},[_c('h1',[_vm._v("Pagamento")])]),_c('div',{staticClass:"bill-register-container"},[_c('h2',[_vm._v("Cadastro de nota fiscal")]),_c('hr'),_c('span',{staticClass:"sub-title"},[_vm._v("Informe os dados da nota fiscal")]),_c('form',[_c('ok-input',{attrs:{"model":_vm.newBill.code,"label":'Número da nota*',"placeholder":' ',"id":'code'},on:{"updated":function (x) {
          _vm.newBill.code = x;
        }}}),_c('ok-input',{attrs:{"model":_vm.newBill.store,"label":'Loja*',"placeholder":' ',"options":_vm.storeListNames,"id":'store'},on:{"updated":function (x) {
          _vm.newBill.store = x;
        }}}),_c('ok-input',{attrs:{"model":_vm.newBill.payment_type,"label":'Forma de pagamento*',"placeholder":' ',"options":_vm.paymentMethods,"id":'payment-type'},on:{"updated":function (x) {
          _vm.newBill.payment_type = x;
        },"selected":_vm.checkPaymentType}}),_c('ok-input',{staticClass:"half-input",attrs:{"model":_vm.newBill.purchase_date,"label":'Data da compra*',"mask":'##/##/####',"placeholder":'DD/MM/AAAA',"isDate":true,"id":'purchase-date'},on:{"updated":function (x) {
          _vm.newBill.purchase_date = x;
        }}}),_c('ok-input',{staticClass:"half-input",attrs:{"model":_vm.newBill.price,"label":'Valor da compra*',"isCurrency":true,"placeholder":' ',"id":'price'},on:{"updated":function (x) {
          _vm.newBill.price = x;
        }}}),_c('button',{staticClass:"primary",attrs:{"type":"button"}},[_vm._v("Cancelar")]),_c('button',{staticClass:"secundary left-space",attrs:{"type":"button","disabled":_vm.isSending},on:{"click":function($event){return _vm.save()}}},[_vm._v("Confirmar")])],1)])]),_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }