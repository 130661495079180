import axios from 'axios';
// const FormData = require('form-data');

var companyMixin = {
  data: function () {
    return {
    }
  },
  computed: {
    // $api: () => $api
  },
  methods: {
    listCompanies(campaignData) {
      const self = this;
      const shopping = campaignData.shopping;
      const campaign = campaignData.campaign;

      const path = `/company?shopping=${shopping}&campaign=${campaign}`;
      return axios.get(this.api.base_url + path).then(function (response) {
        const responseData = response.data.data;
        return responseData

      }).catch(function (error) {
        self.showError(error);
      });
    },
    listStores() {
      const self = this;
      const shopping = localStorage.shoppingSlug;
      const campaign = localStorage.campaignSlug;

      const path = `/company?shopping=${shopping}&campaign=${campaign}&type=STORE`;
      return axios.get(this.api.base_url + path).then(function (response) {
        const responseData = response.data.data.company_list;
        return responseData

      }).catch(function (error) {
        self.showError(error);
      });
    },
    listShoppings() {
      const self = this;

      const path = `/company?type=SHOPPING`;
      return axios.get(this.api.base_url + path).then(function (response) {
        const responseData = response.data.data;
        return responseData

      }).catch(function (error) {
        self.showError(error);
      });
    },
  }
}

export default companyMixin;