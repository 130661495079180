<template>
  <div v-if="isOpen">
    <div class="popup-bg" @click="closePopup" :style="style"></div>
    <div class="popup-area">
      <section class="popup" @click="doNothing">
        <header>
          <h2>{{ title }}</h2>
        </header>

        <p v-if="pdfUrl && !pdfLoaded" style="margin-top: 8px; text-align: center;">Carregando regulamento...</p>

        <iframe ref="pdfViewer" v-if="pdfUrl" :src="pdfUrl" style="width: 100%; height: 70vh;" frameborder="0"
          v-on:load="checkIfPdfIsLoaded"></iframe>
        <div v-else class="content" v-html="content.rules"></div>

        <footer>
          <button class="primary" @click="closePopup">fechar</button>
        </footer>
      </section>
    </div>
  </div>
</template>


<script>
import CampaignMixin from "../mixins/campaign.mixin.js";
// import moment from "moment";

export default {
  mixins: [CampaignMixin],
  name: "popupContent",
  components: {},
  data: function () {
    return {
      okModel: "",
      pdfUrl: '',
      pdfLoaded: false,
    };
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "text",
    },
    content: {
      type: Object,
      required: false,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    if (this.campaign?.rules_file?.url) {
      this.pdfUrl = `https://docs.google.com/gview?url=${this.campaign.rules_file.url}&embedded=true`
    }
  },
  watch: {
    isOpen(newValue) {
      if (newValue && this.pdfUrl) {

        setTimeout(() => {
          this.loadPdf()
        }, 1000)
      } else if (!newValue) this.pdfLoaded = false
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
      //   this.isOpen = false;
    },
    doNothing() { },
    loadPdf() {
      if (this.pdfLoaded) {
        clearTimeout(timeoutPdf)
        return
      }

      console.log("pdfLoaded", this.pdfLoaded)
      const iframe = this.$refs.pdfViewer;
      iframe.src = this.pdfUrl;

      if (iframe) {
        const iframeDoc = iframe.contentDocument;

        if (iframeDoc?.body?.innerHTML.trim() === '') {
          console.log('O conteúdo do iframe está vazio')
          this.pdfLoaded = false
        } else this.pdfLoaded = true
      }

      const timeoutPdf = setTimeout(this.loadPdf, 1000)
    },
    checkIfPdfIsLoaded() {
      const iframe = this.$refs.pdfViewer
      if (iframe.contentWindow !== null) {
        this.pdfLoaded = true
        console.log("PDF carregado")
      }
    },
  },
};
</script>


<style  lang="scss" scoped>
@import "../scss/colors.scss";

.popup-bg,
.popup-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.popup {
  margin: auto;
  margin-top: 25px;
  width: 90%;
  height: 85vh;
  max-width: 840px;
  background: white;
  padding: 25px 15px;
  border-radius: 8px;
  z-index: 1001;
  top: 0;

  header {
    border-bottom: 1px solid $span-color;
    margin-bottom: 10px;
    padding-bottom: 10px;

    h2 {
      background: transparent;
      margin-bottom: 10px;
    }
  }

  .pdf-view {
    width: 100%;
    height: 50vh;
  }

  .content {
    height: 65vh;
    color: $text-color;
    overflow-x: scroll;
  }

  footer {
    border-top: 1px solid $span-color;
    margin-top: 10px;
    padding-top: 10px;

    button {
      width: auto;
      height: auto;
      padding: 10px 20px;
      // margin-top: 10px;
    }
  }
}
</style>