<template>
  <MainLayout>
    <div class="store-list" v-if="campaign.gears && campaign.gears.control">
      <div class="top-title" v-if="!campaign.gears.control.has_wallet">
        <h1>Lojas participantes</h1>
      </div>

      <div class="store-content container">
        <form class="search">
          <ok-input :model="searchText" @updated="
            (x) => {
              searchText = x;
            }
          " :label="'Buscar'" :id="'searchText'">
          </ok-input>
        </form>

        <h1 class="info" v-if="storeList && storeList.length == 0">
          {{ listMessage }}
        </h1>

        <div v-if="storeList && storeList.length > 0">
          <div v-for="item in filteredProducts" :key="item._id" class="store-item">
            <h3>{{ item.name_fancy }}</h3>
            <p>CNPJ: {{ item.document | toCNPJ }}</p>
            <button @click="sendMessage(item.phone)" v-if="item.phone">
              <i class="fa-brands fa-whatsapp"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import CompanyMixin from "../mixins/company.mixin.js";
import OrderMixin from "../mixins/order.mixin.js";
import userMixin from "../mixins/user.mixin";
import OkInput from "../components/ok-input.vue";

import "../filters/toCurrency";

export default {
  mixins: [CampaignMixin, CompanyMixin, userMixin, OrderMixin],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      searchText: "",
      storeList: [],
      listMessage: "Carregando",
      listMessagePayment: "Carregando",
    };
  },
  mounted: function () {
    let self = this;

    this.listStores()
      .then((response) => {
        self.listMessage = "Lojas não encontradas";
        console.log(response);
        if (response) {
          self.storeList = response;
        }
      })
      .catch(function () {
        self.listMessage = "Lojas não encontradas";
      });
  },
  computed: {
    filteredProducts: function () {
      var searchClean = this.searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      return this.storeList.filter(function (item) {
        var textClean = item.name_fancy
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        return textClean.indexOf(searchClean) !== -1;
      });
    },
  },
  methods: {
    openOrder(order) {
      this.$router.push(
        this.campaignHome + "/nota-fiscal/" + order._id,
        "_self"
      );
    },
    sendMessage(phone) {
      var phoneClean = phone.replace(/\D/g, "");
      window.open(`https://wa.me/whatsappphonenumber?text=${phoneClean}`, '_blank')
    }
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.store-list {
  position: relative;
  margin-bottom: 150px;

  .search {
    padding: 0 20px;
  }
}

.store-content {
  height: 100%;
  padding: 20px 10px 0px 20px;
  margin-bottom: 50px;

  text-align: center;
  align-items: center;
  justify-content: space-evenly;

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }

  .store-item {
    text-align: left;
    margin: 20px;
    background: var(--bg-color-light);
    border-radius: 15px;
    padding: 20px;
    position: relative;

    h3 {
      font-weight: bold;
      margin-bottom: 5px;
    }

    button {
      background: transparent;
      color: var(--text-color);
      font-size: 20pt;
      position: absolute;
      right: 0px;
      margin: 20px;
      top: 0px;
      width: auto;

      &:hover {
        color: var(--secundary);
      }
    }
  }
}
</style>