<template>
  <section :style="style">
    <div class="bill-register">
      <div class="bill-register-container">
        <h2>Cadastro de nota fiscal</h2>

        <hr style="margin: 20px 0px" />
        <div>  São 3 passos simples: </div><br>
        <div style="padding-left: 30px;">
          <ul>
          <li>Clique no botão "Ler nota fiscal"</li>
          <li>Coloque sua nota no leitor </li>
          <li>Você receberá uma mensagem de confirmação.</li>
        </ul>
        </div><br>
        <br /><br />
        <h2 v-if="!prepared && !process">Quando estiver pronto(a) clique no botão abaixo.</h2>
        
        <h2 v-if="prepared && !process">Coloque sua nota sobre o leitor.</h2>
        <div v-if="prepared && !process" style="padding-left: 30px;">
          <ul>
            <li>Em torno de 5cm do leitor.</li>
            <li>Você vai ouvir um Bip quando a leitura for feita.</li>
          </ul>
      </div>
        
        <h2 v-if="process">Processando sua nota...</h2>
        <form onsubmit="return false;">
          <!-- <input id="qrcode" type="text" v-on:blur="setBlur" v-model="qrcode"/> -->
          <input id="qrcode" type="text" style="opacity: 0;height: 0;" v-on:blur="setBlur" v-model="qrcode" autocomplete="off"/>
         
          <div v-if="!prepared ">
            <button type="button" style="width: 250px" id="qrCodeBtn" class="primary" @click="lerQRCode()">
              Ler nota fiscal
            </button>
          </div>


          <br>
          
        </form>

      </div>
        <div id="errorMessage">  Se tiver algum problema entre em contato com o SAC do shopping. </div>
    </div>
    
  </section>
</template>

<script>
import orderMixin from "@/mixins/order.mixin";
import campaignMixin from "@/mixins/campaign.mixin";
import companyMixin from "@/mixins/company.mixin";

export default {
  mixins: [orderMixin, companyMixin, campaignMixin],
  components: {
    // WebCam,
  },
  data() {
    return {
      newBill: {
        quizz_answer: "",
      },
      slugCampaign: "",
      shoppingName: "",
      qrcode: "",
      waiting: false,
      process: false,
      prepared: false
    };
  },
  props: {
    image: {
      type: String,
      default: null,
      required: false,
    },
  },
  mounted() {},
  watch: {
    qrcode() {
      let self = this;
      if (!this.waiting) {
        this.waiting = true;
        console.log("wait");
        setTimeout(() => {
          self.waiting = false;
          self.sendCodeHidden();
        }, 2000);
      }
    },
  },
  methods: {
    setBlur() {
      this.prepared = false
    },
    lerQRCode() {
      const textField = document.getElementById("qrcode");
      textField.focus();
      this.qrcode = "";
      this.prepared = true
    },
    cleanQRCode() {
      const textField = document.getElementById("qrCodeBtn");
      textField.focus();
      this.qrcode = "";
    },
    sendCodeHidden2() {
      console.log("Send QRCode");
      console.log(this.qrcode)
    },
    sendCodeHidden() {
      const self = this;
      if(this.qrcode.length < 6) {
        this.cleanQRCode()
        return;
      }

      this.process = true;
      this.sendBillQRCode(this.qrcode).then((response) => {
        self.cleanQRCode()

        if (response) {
          self.openSuccess();
          self.sucess = true;
        } else {
          self.process = false;
          self.readQrcode = true;
          self.sucess = false;
        }
      });

      this.qrcode = "";
    },
    backToPhoto() {
      // this.$emit("goToPhoto");
    },
    selectOption(option) {
      this.newBill.quizz_answer = option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/colors.scss";
// @import "~@/scss/form.scss";

.bill-register {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  column-gap: 40px;
  min-height: 70vh;
    position: relative;

  span {
    text-align: left;
    width: 80%;
    max-width: 420px;
    margin-bottom: 15px;
  }

  #errorMessage{
      position: absolute;
      bottom: 0px;
      padding: 20px;
    }
  // padding: 20px;
  .bill-register-container {
    padding: 50px 20px;

    h2 {
      color: var(--primary);
      font-weight: bold;
      font-size: 18px;
    }

    hr {
      border-color: $bg-color;
    }


    form {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      column-gap: 10px;

      button {
        margin-top: 1vh;

        &:disabled {
          background: #ccc !important;
          cursor: not-allowed;
        }
      }

      .half-input {
        width: calc(50% - 5px);
      }
    }

    .form-buttons {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 10px;

      button {
        float: right;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
  }
}
</style>
