var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{style:(_vm.style)},[_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"top-title"},[_c('h1',[_vm._v("Acessar Conta")])]),_c('span',[_vm._v("Informe seus dados abaixo")]),(_vm.campaign && _vm.campaign.user_fields)?_c('form',{attrs:{"action":"submit"}},[(_vm.campaign.user_fields.cpf === 'LOGIN')?_c('ok-input',{attrs:{"model":_vm.user.document,"id":'document',"label":'CPF*',"mask":'###.###.###-##',"placeholder":'000.000.000-00'},on:{"updated":function (x) {
          _vm.user.document = x;
        }}}):_vm._e(),(_vm.campaign.user_fields.email == 'LOGIN')?_c('ok-input',{attrs:{"model":_vm.user.email,"id":'email',"label":'Email',"placeholder":'nome@email.com'},on:{"updated":function (x) {
          _vm.user.email = x;
        }}}):_vm._e(),(_vm.campaign.user_fields.phone == 'LOGIN')?_c('ok-input',{attrs:{"model":_vm.user.phone,"id":'phone',"label":'Telefone*',"mask":'(##) #.####.####',"placeholder":'(00) 0.0000-0000'},on:{"updated":function (x) {
          _vm.user.phone = x;
        }}}):_vm._e(),(_vm.campaign.user_fields.password == 'LOGIN')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],attrs:{"type":"password","id":'password',"placeholder":"Password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}):_c('ok-input',{attrs:{"model":_vm.user.birthday,"id":'birthday',"label":'Data de nascimento',"mask":'##/##/####',"placeholder":'DD/MM/AAAA',"isDate":true},on:{"updated":function (x) {
          _vm.user.birthday = x;
        }}}),_c('div',{staticClass:"buttons"},[(_vm.campaign.user_fields.password !== 'INACTIVE')?_c('button',{staticClass:"clean",staticStyle:{"margin-right":"8px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.goToForgotPassword()}}},[_vm._v(" Esqueci a senha ")]):_vm._e(),_c('button',{staticClass:"primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Entrar")])]),_c('hr'),_c('p',{staticClass:"question"},[_vm._v(" Ainda não é cadastrado ? ")]),_c('button',{staticClass:"primary_border",attrs:{"type":"button"},on:{"click":function($event){return _vm.register()}}},[_vm._v("Cadastre-se")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }