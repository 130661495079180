<template>
  <MainLayout :style="style">
    <div class="login-container">
      <div class="top-title">
        <h1>Acessar Conta</h1>
      </div>


      <!-- <strong>Participe das nossas promoções</strong> -->
      <span>Informe seus dados abaixo</span>


      <form action="submit" v-if="campaign && campaign.user_fields">

        <ok-input v-if="campaign.user_fields.cpf === 'LOGIN'" :model="user.document" @updated="
          (x) => {
            user.document = x;
          }
        " :id="'document'" :label="'CPF*'" :mask="'###.###.###-##'" :placeholder="'000.000.000-00'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.email == 'LOGIN'" :model="user.email" @updated="
          (x) => {
            user.email = x;
          }
        " :id="'email'" :label="'Email'" :placeholder="'nome@email.com'">
        </ok-input>

        <ok-input v-if="campaign.user_fields.phone == 'LOGIN'" :model="user.phone" @updated="
          (x) => {
            user.phone = x;
          }
        " :id="'phone'" :label="'Telefone*'" :mask="'(##) #.####.####'" :placeholder="'(00) 0.0000-0000'">
        </ok-input>

        <input v-if="campaign.user_fields.password == 'LOGIN'" type="password" :id="'password'" placeholder="Password"
          v-model="user.password" />

        <ok-input v-else :model="user.birthday" @updated="
          (x) => {
            user.birthday = x;
          }
        " :id="'birthday'" :label="'Data de nascimento'" :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'"
          :isDate="true">
        </ok-input>

        <div class="buttons">
          <button type="button" class="clean" style="margin-right: 8px;"
            v-if="campaign.user_fields.password !== 'INACTIVE'" @click="goToForgotPassword()">
            Esqueci a senha
          </button>
          <button type="button" class="primary" @click="submit()">Entrar</button>
        </div>

        <hr>

        <p class="question">
          Ainda não é cadastrado ?
        </p>


        <button type="button" class="primary_border" @click="register()">Cadastre-se</button>
      </form>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";
import OkInput from "../components/ok-input.vue";

export default {
  mixins: [userMixin, campaignMixin],
  components: {
    MainLayout,
    OkInput
  },
  data: function () {
    return {

    };
  },
  mounted() {
    this.logout()
  },
  methods: {
    submit() {
      const self = this;
      const loginData = {
        user: JSON.parse(JSON.stringify(this.user))
      }

      if (this.$route.params.campanha) {
        loginData.shopping = this.$route.params.shopping;
        loginData.campaign = this.$route.params.campanha;
      }

      this.login(loginData).then((response) => {
        console.log("login page", response);
        if (response != undefined) {
          window.open(self.campaignHome, "_self");
        }
      })
    },
    goToForgotPassword() {
      let path = encodeURI(window.location.pathname);
      var [base, shopping, campaign] = path.split("/");
      console.log(base);
      window.open(`${shopping}/${campaign}/user/forgot-password`, "_self");

    },
    register() {
      // window.open("../user/register", "_self");
      let path = encodeURI(window.location.pathname);
      var [base, shopping, campaign] = path.split("/");
      console.log(base);
      window.open(`${shopping}/${campaign}/user/register`, "_self");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    width: 80%;
    max-width: 420px;
  }

  .top-title {
    margin-bottom: 40px;
  }

  span {

    text-align: left;
    width: 80%;
    max-width: 420px;
    margin-bottom: 15px;
  }

  p.question {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

hr {
  width: 100%;
  margin: 20px 0px;
}


.buttons {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  padding: 20px 10px;

  button {

    &.clean {
      font-size: 0.9rem;
    }
  }
}
</style>