import axios from 'axios';

var surveyMixin = {
  data: function () {
    return {
    }
  },
  computed: {
    // $api: () => $api
  },
  methods: {
    sendSurvey(data) {
      let self = this;
      if (!this.api) return null;

      return axios.post(this.api.base_url + "/survey/answer", data).then((res) => {
        const dataResponse = res.data.data;
        return dataResponse;
      }).catch((err) => {
        return self.showErrorAndReturn(err);
      })
    },
    loadSurvey(id, userId) {
      const self = this;

      const path = `/survey/${id}`;
      const query = `?enable=true&user=${userId}`

      return axios.get(this.api.base_url + path + query).then(function (response) {
        const survey = response.data.data.survey;
        return survey;

      }).catch(function (error) {
        self.showError(error);
      });
    },
  }
}

export default surveyMixin;