<template>
  <MainLayout  :style="style">
    <div class="user-card-register-container" v-if="campaign">
      <div class="top-title">
        <h1>Cadastrar cartão</h1>
      </div>
      
      <form>
        <ok-input
          :model="card.number"
          @updated="
            (x) => {
              card.number = x;
            }
          "
          :label="'Número do cartão'"
          :mask="'#### #### #### ####'"
          :placeholder="'0000 0000 0000 0000'"
        >
        </ok-input>

        <ok-input
          :model="card.holder_name"
          @updated="
            (x) => {
              card.holder_name = x;
            }
          "
          :label="'Nome completo'"
          :placeholder="' '"
        >
        </ok-input>

        <div style="display: flex;">
          <ok-input
            :model="card.exp"
            @updated="
              (x) => {
                card.exp = x;
              }
            "
            :label="'Validade'"
            :mask="'##/##'"
            :placeholder="'MM/AA'"
            class="small-input"
          >
          </ok-input>

          <ok-input
            :model="card.security_code"
            @updated="
              (x) => {
                card.security_code = x;
              }
            "
            :label="'CVV'"
            :placeholder="' '"
            class="small-input"
          >
          </ok-input>
        </div>

      </form>

      <form class="button-area" style="padding: 20px">
        <button type="button" class="gray" style="width: 100%" @click="goBack">
          Voltar
        </button>
        <div style="width: 35px"></div>
        <button type="button" class="primary" style="width: 100%" @click="save">
          Cadastrar
        </button>
      </form>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import userMixin from "../mixins/user.mixin";
import campaignMixin from "../mixins/campaign.mixin";
import OkInput from "../components/ok-input.vue";

export default {
  mixins: [userMixin, campaignMixin],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      card: {
        number: '',
        holder_name: '',
        security_code: '',
        exp: '',
        exp_month: 0,
        exp_year: 0,
        brand: '',
        card_type: 'CREDIT',
      },
      cardList: [
        {holder_name: 'Edgar Imai', last_four: 1234, exp_month: 10, exp_year: 25},
        {holder_name: 'Edgar Imai', last_four: 9876, exp_month: 12, exp_year: 26},
      ],
    };
  },
  watch: {
    user(newValue) {
      if (newValue.cardList) {
        this.cardList = newValue.cardList;
      }
    },
    campaign(newValue) {
      console.log("Campaign", newValue);
    },
  },
  methods: {
    save() {
      let self = this;
      
      let card = JSON.parse(JSON.stringify(this.card));
      let exp = card.exp.split("/");
      if (!exp[1]) return;

      card.exp_month = exp[0];
      card.exp_year = exp[1];

      card.number = card.number.replace(/ /g, "");
      card.card_type = 'credit'

      this.getBrand(card);
      
      const validateUserCardData = this.validateCardForm(card);
      if(!validateUserCardData.validated ) {
        this.$swal({
          icon: "error",
          title: "",
          text: validateUserCardData.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return null;
      }

      this.createUserCard(card).then((response) => {
        console.log("Card registred", response);
        if(response && response != undefined) {
          window.open(self.campaignHome + '/user/cards', "_self");
        }
      });
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.user-card-register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;

  .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
  }

  button.gray {
    background: #c3c3c3;
    // color: #4d4d4d;
  }

  form {
    width: 100%;
    // padding-top: 3vh;
    padding: 20px;

    button {
      display: block;
      width: 120px;
      height: auto;
      padding: 10px 15px;
      margin: auto;

    }
  }

  .small-input{
    width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .user-card-register-container {
    margin-bottom: 60px;
    
    .small-input{
      width: 100%;
    }
  }
}
</style>