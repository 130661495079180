<template>
  <MainLayout>
    <div class="campaign-container" v-if="campaign" :style="style">
      <div class="top-title"></div>

      <div class="container">
        <div class="col">
          <div class="campaign-banner" v-if="campaign.layout">
            <img class="mobile-only" :src="getImageThumbUrl(campaign.layout.banner)" v-if="campaign.layout.banner"
              alt="banner" />
            <img class="desktop-only" :src="getImageUrl(campaign.layout.web_banner)" v-if="campaign.layout.web_banner"
              alt="banner web" />
            <!-- <img src="../assets/images/banner.png" alt="banner" /> -->
          </div>

          <ul class="buttons">
            <li class="primary desktop-only">
              <button @click="goToStoreList()" class="btn-secundary" type="button">
                Lojas participantes
              </button>
            </li>

            <li class="primary desktop-only">
              <button @click="isOpenRules = true" class="btn-secundary" type="button">
                Regulamento
              </button>
            </li>

            <li v-if="isLogged()">
              <button class="primary" @click="goToLogin()" type="button">
                Participar
              </button>
            </li>

            <!-- <button class="primary" v-else @click="goToCamera()" type="button">
              Enviar Nota
            </button> -->
          </ul>
        </div>

        <div class="col">
          <div class="campaign-info">
            <h1>
              Validade:
              <b>{{ moment(campaign.date_end).add(1, "days").format("LL") }}</b>
            </h1>
            <article v-html="campaign.description"></article>

            <!-- <div class="buttons">
              <a @click="isOpenRules = true">
                <strong>Ver Regulamento</strong>
              </a>
            </div> -->
          </div>
        </div>

        <div class="col" v-if="giftList && giftList.length > 0">
          <div id="gift-list">
            <h2>Brindes / Prêmios</h2>
            <div class="gift-row">
              <div class="gift-item" v-for="item of giftList" :key="item._id">
                <div class="image-box" v-if="item.images[0]">
                  <img :src="getImageThumbUrl(item.images[0])" alt="" />
                </div>
                <div class="info">
                  <h3>{{ item.name }}</h3>
                  <div class="extra" v-if="item.extra_price > 0">
                    + {{ item.extra | toCurrency }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col" v-if="
        campaign.layout &&
        campaign.layout.steps &&
        campaign.layout.steps.length > 0
      ">
        <div id="steps-content">
          <div class="container">
            <h2>Como participar</h2>
            <div v-for="(item, index) of campaign.layout.steps" :key="item._id" class="step-item">
              <div class="index">{{ index }}</div>
              <div class="icon">
                <img :src="getImageThumbUrl(item.icon)" alt="" />
              </div>
              <div class="content">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col mobile-only">
        <div id="store-button" @click="goToStoreList">
          <div class="container">
            <img src="/img/store-white.png" alt="" />
            <p>Lojas participantes</p>
          </div>
        </div>
      </div>

      <div class="col" v-if="campaign.faq && campaign.faq.length > 0">
        <div id="faq-content" class="container">
          <h2>Perguntas frequentes</h2>

          <div v-for="item of campaign.faq" :key="item._id" class="faq-item">
            <details>
              <summary>
                {{ item.question }}
              </summary>
              <p v-html="item.answer"></p>
            </details>
          </div>
        </div>
      </div>

      <button id="btn-rule" class="primary full mobile-only" @click="isOpenRules = true">
        Regulamento
      </button>

      <popup-content :content="campaign" :title="'Regulamento'" @closed="isOpenRules = false" :isOpen="isOpenRules">
      </popup-content>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import PopupContent from "../components/popup-content.vue";

export default {
  mixins: [CampaignMixin],
  components: {
    MainLayout,
    PopupContent,
  },
  data: function () {
    return {
      style: {},
      isOpenRules: false,
      isOpenQuestion: false,
      userLogin: "",
      userEdit: "",
      giftList: [],
    };
  },
  watch: {
    campaign(newValue) {
      // console.log("Campanha - Atualizada " + newValue);
      this.userLogin = `${newValue}/user/login`;
      this.userEdit = `${newValue}/user`;
      // console.log("campanha identificada");
      this.loadProductList();
      this.$forceUpdate();
    },
  },
  mounted() {
    //this.$refs.rootElement.requestFullscreen();
  },
  methods: {
    isLoaded() {
      // console.log("isLoaded", localStorage.token);
      return localStorage.token != undefined ? false : false;
    },
    isLogged() {
      if (!localStorage.token) {
        return true;
      } else {
        return false;
      }
    },
    loadProductList() {
      let self = this;
      this.listProducts().then(function (answer) {
        if (answer) self.giftList = answer.product_list;
      });
    },
    goToStoreList() {
      window.open(this.campaignHome + "/lojas", "_self");
    },
    goToLogin() {
      window.open(this.campaignHome + "/user/login", "_self");
    },
    goToCamera() {
      window.open(this.campaignHome + "/camera", "_self");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.campaign-container {
  position: relative;
  overflow-y: scroll;
  padding-bottom: 80px;
  display: block;

  // .row {
  //   display: grid;
  //   // grid-template-columns: minmax(150px, 15%) 1fr;
  //   grid-template-columns: minmax(250px, 40%) 1fr;
  // }

  .top-title {
    height: 3px;

    h1 {
      font-size: 9pt;
    }
  }

  h2 {
    margin: 20px 20px;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 50px;
  }

  ul.buttons {
    list-style: none;

    li {
      margin: auto;
      display: inline-block;

      button {
        margin: 0;
        max-width: 320px;
      }
    }
  }

  .campaign-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    margin-top: 0;

    img {
      width: 100%;
      height: auto;
      // max-height: 40vh;
    }

    button {
      bottom: 15px;
    }
  }

  .campaign-info {
    // height: 25vh;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 100px;

    // border: 1px solid #ccc;
    border-radius: 15px;
    padding: 30px;
    margin: 20px;

    h1 {
      font-size: 1rem;
      font-weight: 500;

      b {
        color: var(--primary);
      }
    }

    article {
      margin-top: 3vh;
      width: 100%;
      // min-height: 20vh;
      margin-bottom: 50px;
      text-align: justify;
      line-height: 1.5rem;
      font-size: 1.2rem;
      font-weight: 400;
      color: $text-color;
    }

    a {
      align-self: flex-end;
    }

    strong {
      align-self: flex-end;
      font-size: 0.9rem;
      font-weight: 800;
      color: var(--primary);
    }
  }

  #gift-list {
    margin-bottom: 50px;

    h2 {
      // margin: 20px;
    }

    .gift-row {
      width: auto;
      height: 310px;
      padding: 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .gift-item {
      background: var(--bg-color-light);
      display: inline-block;
      position: relative;
      width: 300px;
      aspect-ratio: 1 / 1;
      margin-right: 5px;

      .image-box {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }

      .info {
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 10px;
        background: var(--secundary);

        h3 {
          font-size: 13pt;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }

  #steps-content {
    margin-top: 20px;
    background: var(--bg-color-light);
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
    overflow: auto;

    h2 {
      color: var(--secundary);
      margin-bottom: 30px;
      // margin: 20px 20px;
      // text-align: center;
      // font-size: 1.2rem;
    }

    .step-item {
      position: relative;
      padding-top: 40px;
      margin-top: 20px;
      width: 33%;
      min-width: 320px;
      float: left;

      .icon {
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -27px;

        width: 55px;
        height: 55px;

        img {
          height: 100%;
          width: auto;
        }
      }

      .index {
        position: absolute;
        left: 10%;
        margin-left: -20px;
        width: 36px;
        height: 36px;
        top: 60px;

        background: var(--secundary);
        border-radius: 50%;
        color: #fff;
        text-align: center;
        padding-top: 9px;
        font-size: 14pt;
        font-weight: bold;
      }

      .content {
        background: var(--bg-color);
        border-radius: 15px;
        width: 80%;
        display: block;
        margin: auto;
        padding-right: 20px;
        padding-left: 40px;
        padding-bottom: 40px;
        padding-top: 32px;
      }
    }
  }

  #store-button {
    cursor: pointer;
    background: var(--secundary);
    padding: 20px;
    position: relative;
    min-height: 75px;

    &:hover {
      background: var(--secundary-dark);
    }

    img {
      position: absolute;
    }

    p {
      color: white;
      margin-left: 70px;
      font-size: 16pt;
      font-weight: bold;
      padding-top: 7px;
    }
  }

  #faq-content {
    padding-top: 50px;
    padding-bottom: 50px;


    h2 {
      // margin: 20px;
    }

    details {
      margin: 20px;
      background: var(--bg-color-light);
      // border: 1px solid #ccc !important;
      border-radius: 15px;
      padding: 20px;
      // box-shadow: 0 1px 4px -3px #000000;

      summary {
        // padding: 15px 10px 5px 15px;
        // font-weight: bold;
        margin-bottom: 10px;
        list-style: none;

        &::-webkit-details-marker,
        &::marker {
          display: none;
        }
      }

      p {
        font-size: 9pt;
      }
    }
  }

  #btn-rule {
    width: 100%;
    border-radius: 0;
    padding: 20px;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .campaign-container {

    h2 {
      text-align: left;
      font-size: 1.2rem;
    }

    .row {
      display: block !important;
    }

    .campaign-banner {
      margin: 0px !important;
    }

    .campaign-info {
      padding: 0 10vw !important;
      border-radius: 0px !important;
      border: none !important;
      margin: 0px !important;

      h1 {
        font-size: 0.7rem;
      }

      article {
        font-size: 0.9rem;
      }
    }

    #steps-content {
      padding-bottom: 80px;

      .step-item {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>