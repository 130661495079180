import Vue from 'vue'
import './plugins/main'
import uuid from "device-uuid";
import routes from './router/index.routes'
import './mixins/navigation.mixin'

import './middlewares/interceptor'

import './filters/calculateAge'
import './filters/formatDate'
import './filters/replace'
import './filters/strLimit'
import './filters/toCpf'
import './filters/toCurrency'


import './mixins/global.mixin'

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: routes,
  // template: template,
  beforeCreate: function () {
    if (!localStorage.device) {
      let device = uuid.DeviceUUID().get();
      localStorage.setItem('device', device);
    }
  },

}).$mount('#app')
