<template>
  <section>
    <div class="totem-store-list" v-if="campaign.gears && campaign.gears.control">
      <div class="totem-store-content container">
        <h3 style="display: initial">Lojas participantes</h3>
        <form class="search" style="margin-top: 0px;">
          <ok-input :model="searchText" @updated="(x) => {
            searchText = x;
          }
            " :label="'Buscar'" :id="'searchText'">
          </ok-input>
        </form>

        <h1 class="info" v-if="storeList && storeList.length == 0">
          {{ listMessage }}
        </h1>

        <div class="store-list-items" v-if="storeList && storeList.length > 0">
          <div v-for="item in filteredProducts" :key="item._id" class="store-item">
            <h3>{{ item.name_fancy }}</h3>
            <p>CNPJ: {{ item.document | toCNPJ }}</p>
            <button @click="sendMessage(item.phone)" v-if="item.phone">
              <i class="fa-brands fa-whatsapp"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CampaignMixin from "../../../mixins/campaign.mixin.js";
import CompanyMixin from "../../../mixins/company.mixin.js";
import OkInput from "../../../components/ok-input.vue";

export default {
  mixins: [CampaignMixin, CompanyMixin],
  components: {
    OkInput,
  },
  data() {
    return {
      searchText: "",
      storeList: [],
      listMessage: "Carregando",
    };
  },
  mounted: function () {
    let self = this;

    this.listStores()
      .then((response) => {
        self.listMessage = "Lojas não encontradas";
        console.log(response);
        if (response) {
          self.storeList = response;
        }
      })
      .catch(function () {
        self.listMessage = "Lojas não encontradas";
      });
  },
  computed: {
    filteredProducts: function () {
      var searchClean = this.searchText
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      return this.storeList.filter(function (item) {
        var textClean = item.name_fancy
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        return textClean.indexOf(searchClean) !== -1;
      });
    },
  },
  methods: {
    sendMessage(phone) {
      var phoneClean = phone.replace(/\D/g, "");
      window.open(
        `https://wa.me/whatsappphonenumber?text=${phoneClean}`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" >
@import "~@/scss/colors.scss";

.totem-store-list {
  position: relative;
  margin-bottom: 150px;

  h3 {
    text-align: left;
    width: fit-content;
    display: block;

  }

  .search {
    padding: 0 20px;
    width: 300px;
    float: right;
    margin-top: -25px;

    .input-frame {
      input#searchText {
        background: #fff;
        height: auto;
        padding: 8px 20px !important;
      }

      label {
        display: none;
      }
    }

  }
}

.totem-store-content {
  height: 100%;
  padding: 20px 0px;
  margin-bottom: 50px;

  text-align: center;
  align-items: center;
  justify-content: space-evenly;

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }

  .store-list-items {
    margin-top: 30px;
  }


  .store-item {
    text-align: left;
    margin: 20px 0px;
    background: var(--bg-color-light);
    border-radius: 5px;
    padding: 20px;
    position: relative;
    box-shadow: $shadow-border;

    h3 {
      font-weight: bold;
      margin-bottom: 5px;
    }

    button {
      background: transparent;
      color: var(--text-color);
      font-size: 20pt;
      position: absolute;
      right: 0px;
      margin: 20px;
      top: 0px;
      width: auto;

      &:hover {
        color: var(--secundary);
      }
    }
  }
}
</style>