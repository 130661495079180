import Vue from 'vue'
import { publicPath } from '../../vue.config'

// const url = "http://localhost:4072";
// const url = "https://promo.oceantek.com.br";
// const url = "https://promo.passgo.com.br";
const url = process.env.VUE_APP_BASE_URL;

var navigationMixin = {
    data: function () {
        //http://192.168.0.4/
        return {
            api: {
                image_url: url + "/api",
                base_url: url + "/api",
                webservices: url + "/webservices",

                Headers: {
                    Authorization: "",
                    Device: ""
                }
            },
            shoppingSlug: "",
            campaignSlug: "",
            campaignHome: "",
        }
    },
    watch: {
        shoppingSlug(newValue) {
            if (newValue != undefined) {
                this.campaignHome = `${publicPath.replace(".", "")}${this.shoppingSlug}/${this.campaignSlug}`
                localStorage.setItem('shoppingSlug', newValue);
            }
        },
        campaignSlug(newValue) {
            if (newValue != undefined) {
                this.campaignHome = `${publicPath.replace(".", "")}${this.shoppingSlug}/${this.campaignSlug}`
                localStorage.setItem('campaignSlug', newValue);
            }
        },

    },
    created: function () {

    },
    mounted: function () {
        // console.log(this.$route.path);
        if (this.$route.params.shopping) {
            this.shoppingSlug = this.$route.params.shopping;
        } else {
            if (localStorage.shoppingSlug) {
                this.shoppingSlug = localStorage.shoppingSlug
            }
        }
        if (this.$route.params.campanha) {
            this.campaignSlug = this.$route.params.campanha;
        } else {
            if (localStorage.campaignSlug) {
                this.campaignSlug = localStorage.campaignSlug
            }
        }
    },
    methods: {
        getUrl() {

        },
        getImageUrl(image) {
            // console.log( "getImageUrl", image);
            if (!image.url) {
                return "";
            }
            // console.log( "getImageUrl", server.url , image.path , image.url);
            //   return this.api.image_url.replace("/api","") + image.path.replace("..","") + image.url;
            return image.url;
        },
        getImageThumbUrl(image) {
            // console.log( "getImageThumbUrl", image);
            if (!image.thumb_url) {
                return "";
            }
            // console.log( "getImageThumbUrl", server.url , image.path , image.thumb_url);
            //   return this.api.base_url.replace("/api","") + image.path.replace("..","")+ image.thumb_url;
            return image.thumb_url;
        },
        historyBack() {
            this.$router.go(-1);
        }
    }
}

Vue.mixin(navigationMixin);

// export default navigationMixin;