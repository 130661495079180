<template>
  <div class="dev-mode" v-if="isDevelopment">
    <p>Ambiente de homologação</p>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      isDevelopment: false,
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') this.isDevelopment = true
    else this.isDevelopment = false

  },
  methods: {
  }
}
</script>

<style lang="scss">
.dev-mode {
  background-color: #FB9801;
  height: 32px;

  p {
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 15px;
    line-height: 32px;
  }
}


@media only screen and (max-width: 600px) {}
</style>