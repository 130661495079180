import Vue from 'vue'
import moment from "moment";
// import { publicPath } from '../../vue.config'

var globalMixin = {
  data() {
    return {
      isSending: false,
      showKeyboard: false,
    }
  },
  mounted() {
    this.verifyShowKeyboard()
  },
  methods: {
    dateToString(newDate) {
      if (!newDate) return "";
      if (newDate.length > 10) newDate = newDate.substring(0, 10);
      const answer = moment(newDate, "YYYY-MM-DD")
        .add(12, "hours")
        .format("DD/MM/YYYY");
      return answer;
    },
    stringToDate(newDate) {
      const answer = moment(newDate, "DD/MM/YYYY")
        .add(12, "hours")
        .format("YYYY-MM-DD");
      return answer;
    },
    toogleVirtualKeyboard() {
      let localShowKeyboard = JSON.parse(localStorage.getItem('showVirtualKeyboard'))

      if (localShowKeyboard) this.showKeyboard = false
      else this.showKeyboard = true

      localStorage.setItem('showVirtualKeyboard', this.showKeyboard)
      location.reload()
    },
    verifyShowKeyboard() {
      this.showKeyboard = JSON.parse(localStorage.getItem('showVirtualKeyboard')) ? true : false
    },
    insertOnObj(variable, letter, isMoney) {
      // console.log('insertOnObj', variable, letter)
      if (variable == null) variable = ''
      variable = isMoney ? this.fixNumber(variable) + letter : variable + letter
      // console.log('variable', variable)
      return variable
    },
    fixNumber(number) {
      if (!number || isNaN(number)) return ''
      if (typeof number === 'string') number = parseFloat(number)
      return number.toFixed(2)
    },
    removeMask(string) {
      return string.replace(/\D/g, '')
    }
  }
}

Vue.mixin(globalMixin);