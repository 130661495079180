<template>
  <MainLayout :style="style">
    <div class="bill-register">
      <!-- <div class="top-title">
        <h1>Cadastro de nota fiscal</h1>
        <div class="buttons">
          <button type="button" class="primary">Cancelar</button>
          <button type="button" class="secundary left-space" @click="save()">Confirmar</button>
        </div>
      </div> -->

      <div class="top-title">
        <h1>Pagamento</h1>
      </div>
      <div class="bill-register-container">
        <h2>Cadastro de nota fiscal</h2>

        <hr>
        <span class="sub-title">Informe os dados da nota fiscal</span>

        <form>
          <ok-input :model="newBill.code" @updated="(x) => {
            newBill.code = x;
          }
            " :label="'Número da nota*'" :placeholder="' '" :id="'code'">
          </ok-input>

          <ok-input :model="newBill.store" @updated="(x) => {
            newBill.store = x;
          }
            " :label="'Loja*'" :placeholder="' '" :options="storeListNames" :id="'store'">
          </ok-input>

          <ok-input :model="newBill.payment_type" @updated="(x) => {
            newBill.payment_type = x;
          }
            " :label="'Forma de pagamento*'" :placeholder="' '" :options="paymentMethods" :id="'payment-type'"
            @selected="checkPaymentType">
          </ok-input>

          <!-- TODO se for colocar de volta, seguir padrao do desktop, alterar para aceleradores -->
          <!-- <ok-input :model="newBill.card_flag" @updated="
            (x) => {
              newBill.card_flag = x;
            }
          " v-if="showFlags" :label="'Bandeira*'" :placeholder="' '" :options="flagsOptions"
           :id="'payment-type'">
          </ok-input> -->

          <ok-input :model="newBill.purchase_date" @updated="(x) => {
            newBill.purchase_date = x;
          }
            " :label="'Data da compra*'" :mask="'##/##/####'" :placeholder="'DD/MM/AAAA'" class="half-input"
            :isDate="true" :id="'purchase-date'">
          </ok-input>

          <ok-input :model="newBill.price" @updated="(x) => {
            newBill.price = x;
          }
            " :label="'Valor da compra*'" :isCurrency="true" :placeholder="' '" class="half-input" :id="'price'">
          </ok-input>
          <button type="button" class="primary">Cancelar</button>
          <button type="button" class="secundary left-space" @click="save()" :disabled="isSending">Confirmar</button>
        </form>
      </div>
    </div>
    <div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import orderMixin from "../mixins/order.mixin";
import campaignMixin from "../mixins/campaign.mixin";
import companyMixin from "../mixins/company.mixin";
import OkInput from "../components/ok-input.vue";

export default {
  mixins: [orderMixin, companyMixin, campaignMixin],
  components: {
    MainLayout,
    OkInput,
    // WebCam,
  },
  data() {
    return {
      newBill: {
        // quizz_answer: ''
      },
      slugCampaign: '',
      shoppingName: '',
      storeList: [],
      storeListNames: [],
      isOpenQuestion: false,
      showFlags: false,
    };
  },
  mounted() {
    // this.shoppingName = localStorage.shopping.name
    // this.slugCampaign = localStorage.campaignSlug
    this.getStores()

  },
  methods: {
    save() {
      const self = this
      const shopping = localStorage.shoppingSlug
      const campaign = localStorage.campaignSlug
      const user = JSON.parse(localStorage.user)
      const imageId = this.$route.params.id

      const validateBillData = this.validateBillForm(this.newBill);
      if (!validateBillData.validated) {
        this.$swal({
          icon: "error",
          title: "",
          text: validateBillData.message,
          // footer: '<a href>Why do I have this issue?</a>'
        });
        return null;
      }

      this.newBill.user = user._id
      this.newBill.company = shopping
      this.newBill.campaign = campaign
      this.newBill.image = imageId

      // const question = "question-" + this.campaign.company.slug + '-' + this.campaign.slug
      // if (localStorage[question]) this.newBill.quizz_answer = localStorage[question]

      // if (this.newBill.payment_type != 'CREDIT') this.newBill.card_flag = null
      if (this.savedPhoto != null) this.newBill.image = this.savedPhoto._id
      this.isSending = true
      this.createBill({
        invoice: this.newBill,
        shopping: shopping,
        campaign: campaign,
      }).then((response) => {
        self.isSending = false
        if (response && response != undefined) {
          localStorage.setItem('selectedStore', this.newBill.store)
          window.open(this.campaignHome + '/sucesso', "_self")
        }
      }, (error) => {
        console.log(error)
        self.isSending = false
      })
    },
    getStores() {
      let self = this;

      this.listStores().then((response) => {
        self.storeList = response
        self.storeListNames = response.map(item => {
          let obj = {
            name: item.name_fancy,
            value: item._id,
          }
          return obj
        })
      })
    },
    checkPaymentType() {
      if (this.newBill.payment_type === 'CREDIT') this.showFlags = true
      else this.showFlags = false
    },

    // isSelected(selectedOption) {
    //   if (this.newBill.quizz_answer == selectedOption) return true
    //   else return false
    // },
    // selectOption(option) {
    //   this.newBill.quizz_answer = option
    // },
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.bill-register {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 50px;
  column-gap: 40px;

  .sub-title {
    text-align: left;
    width: 80%;
    max-width: 420px;
    margin-bottom: 15px;
  }

  // padding: 20px;
  .bill-register-container {
    padding: 20px;

    h2 {
      color: var(--primary);
      font-weight: bold;
      font-size: 18px;
    }


    hr {
      border-color: $bg-color;
    }

    form {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      column-gap: 10px;
      margin-top: 15px;

      button {
        margin-top: 1vh;

        &:disabled {
          background: #ccc !important;
          cursor: not-allowed;
        }
      }

      .half-input {
        width: calc(50% - 5px);
      }
    }

    .quizz {
      flex-flow: column wrap;

      h3 {
        width: 100%;
        color: var(--primary);
        font-weight: bold;
        font-size: 18px;
      }

      .answer {
        width: 100%;
      }

      .quizz-option {
        width: 100%;

        button {
          width: 100% !important;
        }
      }


    }
  }
}
</style>