<template>
  <div class="virtual-keyboard"
    :class="{ 'virtual-keyboard-left': side === 'left', 'virtual-keyboard-right': side === 'right', 'virtual-keyboard-full': side === 'full', 'virtual-keyboard-hide': side === 'hide' || !showKeyboard }"
    @click.stop>
    <div class="simple-keyboard" @click.stop></div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  props: {
    // form: {
    //   type: Object,
    //   required: true
    // }
    input: {
      type: String
    },
    inputId: {
      type: String
    },
    side: {
      type: String
    }
  },
  data() {
    return {
      keyboard: null,
      // keyboard2: null,
    };
  },
  mounted() {
    // Inicialize o teclado na montagem do componente
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      inputId: this.inputId
    });
    // this.keyboard2 = new Keyboard({
    //   onChange: this.onChange,
    //   onKeyPress: this.onKeyPress,
    //   inputId: this.inputId
    // });
  },
  watch: {
    hideKeyboard(newValue) {
      console.log('watch', this.hideKeyboard, newValue)
    },
    inputId(inputId) {
      // console.log("SimpleKeyboard: inputId updated", inputId);
      this.keyboard.setOptions({ inputId })
      // if (this.side === 'left') this.keyboard.setOptions({ inputId });
      // else this.keyboard2.setOptions({ inputId });
    },
    input(input) {
      // console.log(
      //   "SimpleKeyboard: input Updated",
      //   this.keyboard.options.inputId,
      //   input
      // );
      this.keyboard.setInput(input)
      // if (this.side === 'left') this.keyboard.setInput(input);
      // else this.keyboard2.setInput(input);
    }
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      // if (this.side === 'left') {
      let currentLayout = this.keyboard.options.layoutName;
      // console.log('handleShift left: ', { layoutName: this.keyboard.options.layoutName, layoutName2: this.keyboard2.options.layoutName })
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
      // console.log('handleShift after: ', { layoutName: this.keyboard.options.layoutName, layoutName2: this.keyboard2.options.layoutName })
      // } else {
      //   let currentLayout = this.keyboard2.options.layoutName;
      //   console.log('handleShift right: ', { layoutName: this.keyboard.options.layoutName, layoutName2: this.keyboard2.options.layoutName })
      //   let shiftToggle = currentLayout === "default" ? "shift" : "default";

      //   this.keyboard2.setOptions({
      //     layoutName: shiftToggle
      //   });
      //   console.log('handleShift after: ', { layoutName: this.keyboard.options.layoutName, layoutName2: this.keyboard2.options.layoutName })
      // }
      this.$forceUpdate();
    }
  }
};

</script>

<style lang="scss" >
@import "../scss/colors.scss";

.virtual-keyboard {
  width: 50%;
  position: absolute;
  bottom: 12%;
  z-index: 1;

  .simple-keyboard {

    // left: 0px !important;
    // right: initial;
    .hg-rows {
      display: none;
    }

    .hg-rows:first-child {
      /* estilo para a primeira div com a classe .teste */
      display: block;
    }
  }

  &-left {
    left: 0px;
    margin-left: 20px;
  }

  &-right {
    right: 0px;
    margin-right: 20px;
  }

  &-full {
    width: 100%;
  }

  &-hide {
    display: none;
  }


  .keyboard-btn {
    background-color: red;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 36px;
    position: fixed;
    right: 35px;
    bottom: 95px;
    z-index: 1;
  }

  .hg-theme-default .hg-button span {
    pointer-events: none;
    margin-bottom: 0px;
  }
}
</style>