<template>
  <div class="ticket-item" @click="goTo()" :class="{ 'ticket-item-cursor': usePointer() }">
    <div class="ticket-box not-print" :class="{ 'ticket-box-contemplated': ticket.contemplated && ticket.interacted }"
      v-if="ticket.number">
      <div class="ticket-info">
        <time :class="{ 'lighter': ticket.interacted == false, 'redeemed-text': ticket.status === 'EXCHANGED' }">{{
          ticket.created_at | formatDateTime
        }}</time>
        <p class="ticket-number"
          :class="{ 'lighter': ticket.interacted == false, 'redeemed-text': ticket.status === 'EXCHANGED' }" v-if="type === 'CUPOM'">#{{
            addZeros(ticket.number, campaign.gears.type_cupom.final_value)
          }}</p>
        <p class="ticket-number"
          :class="{ 'lighter': ticket.interacted == false, 'redeemed-text': ticket.status === 'EXCHANGED' }" v-else>#{{
            ticket.number
          }}</p>
      </div>
      <div class="ticket-btn" v-if="type == 'CUPOM' && needToPrint">
        <button class="clean" @click="goToPrint()">
          <img v-if="type == 'CUPOM'" src="../assets/icons/icon-print.svg"
            style="height: 26px; display: block; margin: auto; margin-bottom: 8px;" alt="" />
          <span v-if="ticket.status === 'PRINTED'">Reimprimir</span>
          <span v-else>Imprimir</span>
        </button>
      </div>
      <div class="ticket-btn" v-if="type == 'GIFT' && ticket.status != 'EXCHANGED' && ticket.status != 'PRINTED'">
        <button class="clean" @click="goToExchange($event)">
          <img src="../assets/icons/icon-exchange.svg" alt=""
            style="height: 26px; display: block; margin: auto; margin-bottom: 8px;" />
          <span>Trocar</span>
        </button>
      </div>
      <ScratchCardItem :ticket="ticket" :img="img" :showContent="isScratching" @scratchClosed="isScratching = false"
        @scratched="updateItem">
      </ScratchCardItem>
      <div class="ticket-btn" v-if="type == 'SNAPSHOT'">
        <button class="clean" v-if="ticket.interacted && ticket.contemplated && ticket.status !== 'EXCHANGED'"
          @click="goToExchange($event)">
          <font-awesome-icon icon="fa-solid fa-gift" class="scratch-exchange" />
          Resgatar
        </button>
        <button class="clean gift-redeemed"
          v-if="ticket.interacted && ticket.contemplated && ticket.status === 'EXCHANGED'"
          @click="goToExchange($event)">
          <font-awesome-icon icon="fas fa-box-open" class="scratch-exchange" />
          Resgatado
        </button>
        <button class="clean scratch-btn" @click="scratchTicket()" v-if="!ticket.interacted">
          <!-- <img class="hand-icon" src="../assets/icons/hand-point-up-solid.svg" alt="" srcset="" /> -->
          <!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
          <!-- <font-awesome-icon icon="fas fa-hand-point-up" /> -->
          Raspar
        </button>
      </div>

      <!-- Snapshot antigo -->
      <!-- 
      <div class="ticket-btn" v-if="type == 'SNAPSHOT' && ticket.contemplated && ticket.status != 'EXCHANGED'">
        <button class="clean" @click="goToExchange($event)"
          v-if="ticket.status != 'EXCHANGED' && ticket.status != 'PRINTED'">
          <img src="../assets/icons/icon-exchange-white.svg" class="exchange-icon" alt=""
            style="height: 26px; display: block; margin: auto; margin-bottom: 8px;" />
          <span>Trocar</span>
        </button>
      </div>
      -->
      <!-- <img class="contemplated-icon" v-if="ticket.contemplated" src="../assets/icons/logo-holidays.svg" alt="" /> -->
      <img class="bonus-icon" v-if="ticket.type == 'BONUS'" src="../assets/icons/plus-solid.svg" alt="" />
    </div>
    <div class="register-lucky-number not-print" v-else>
      <div class="registering-number" v-if="isRegistering">
        <ok-input :model="guessNumber" @updated="
          (x) => {
            guessNumber = x;
          }
        " :label="'Insira um número de ' + campaign.gears.type_cupom.initial_value + ' até ' + campaign.gears.type_cupom.final_value"
          :placeholder="' '" :id="'guess-number'">
        </ok-input>
        <button @click="isRegistering = false; guessNumber = ''">Cancelar</button>
        <button @click="sendNumber()" class="confirm-btn">Confirmar</button>
      </div>
      <div v-else>
        <button @click="isRegistering = !isRegistering" class="register">Cadastrar</button>
        <div class="info">Novo número da sorte!</div>
      </div>
    </div>
    <div class="print-file" ref="cupom" v-html="docHtml"></div>
  </div>
</template>

<script>
import OkInput from "../components/ok-input.vue";
import campaignMixin from "../mixins/campaign.mixin.js";
import ScratchCardItem from '../components/scratch-card-item.vue';
export default {
  mixins: [campaignMixin],
  components: {
    OkInput,
    ScratchCardItem
    // PopupQuestion,
    // WebCam,
  },
  data: function () {
    return {
      basePath: '',
      is_totem: false,
      needToPrint: false,
      isRegistering: false,
      guessNumber: '',
      template: {},
      docHtml: "",
      templateId: null,
      printTicket: false,
      isScratching: false,
      img: ''
    };
  },
  props: {
    ticket: {
      type: Object,
      required: true,
      default: null,
    },
    type: {
      type: String,
      required: true,
      default: null,
    },
  },
  mounted: function () {
    if (this.type == 'CUPOM') {
      this.needToPrint = this.ticket.campaign.gears.type_cupom.print_document
    } else if (this.type == 'GIFT') {
      this.needToPrint = this.ticket.campaign.gears.type_gift.print_document
    }

    if (localStorage.campanha) {
      let campaign = JSON.parse(localStorage.campanha)
      this.img = campaign.gears.type_snapshot.scratch_card_image ? campaign.gears.type_snapshot.scratch_card_image.thumb_url : campaign.company.image.thumb_url
    }
  },
  watch: {
    campaignHome(newValue) {
      this.basePath = `${newValue}`

      let path = encodeURI(window.location.pathname);
      this.is_totem = path.indexOf("totem") > -1;
    },
  },
  methods: {
    sendNumber() {
      const self = this
      this.updateTicketNumber(this.ticket, this.guessNumber).then((response) => {
        if (response) {
          this.ticket.number = this.guessNumber
          self.$toasted.show('Número da sorte cadastrado, boa sorte!', { type: 'success' });
          self.$emit("ticketUpdated");
        }
      })
    },
    usePointer() {
      if (this.ticket.type == 'SNAPSHOT') {
        if ((this.ticket.status == 'EXCHANGED' || this.ticket.status == 'PRINTED') && this.ticket.contemplated) return true
        else return false
      } else {
        if (this.ticket.status == 'EXCHANGED') return true
        else return false
      }
    },
    goToExchange(event) {
      if (this.is_totem) {
        this.$emit("click", this.ticket);
        return;
      }
      if (event.stopPropagation) event.stopPropagation();
      this.$router.push(this.basePath + '/cupom/' + this.ticket._id)
    },
    goTo() {
      if (this.is_totem) {
        this.$emit("click", this.ticket);
        return;
      }
      if (this.ticket.type == 'GIFT' && this.ticket.status == 'EXCHANGED') {
        this.$router.push(this.basePath + '/cupom/' + this.ticket._id)
      } else if (this.ticket.type == 'CUPOM' && this.ticket.status == 'EXCHANGED') {
        this.$router.push('/')
      } else if (this.ticket.type == 'SNAPSHOT' && (this.ticket.status == 'EXCHANGED' || this.ticket.status == 'PRINTED') && this.ticket.contemplated) {
        this.$router.push(this.basePath + '/cupom/' + this.ticket._id)
      }
    },
    goToPrint() {
      if (this.is_totem) return;
      this.$router.push(this.basePath + '/impressao-cupom/' + this.ticket._id)
    },
    scratchTicket() {
      this.isScratching = true
    },
    updateItem() {
      const self = this
      this.updateTicketInteracted(this.ticket).then((response) => {
        if (response) {
          self.$emit("ticketUpdated");
        }
      })
    }

  },
};
</script>

<style lang="scss" >
@import "../scss/colors.scss";

.ticket-item {

  &-cursor {
    cursor: pointer;
  }

  .ticket-box {
    display: flex;
    flex-flow: row wrap;
    padding: 20px;
    background-color: $gray-light;
    border-radius: 10px;
    position: relative;
    height: 82px;

    &-contemplated {
      border: 2px solid #cb9b51;
      // background-image: linear-gradient(to right,
      //     #462523 0,
      //     #cb9b51 22%,
      //     #f6e27a 45%,
      //     #f6f2c0 50%,
      //     #f6e27a 55%,
      //     #cb9b51 78%,
      //     #462523 100%);
      background-image: linear-gradient(110deg,
          #462523 0,
          #cb9b51 33%,
          #f6e27a 66%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%);

      .ticket-info {

        time {
          color: $white !important;
        }

        .ticket-number {
          color: $white !important;
        }

        .redeemed-text {
          color: wheat !important;
        }

      }

      .ticket-btn {
        span {
          color: white !important;
        }

        button {
          position: absolute;
          right: 8px !important;
          top: 6px !important;
          font-size: 13px !important;
          color: white;
          font-weight: bold;

          .scratch-exchange {
            width: 100%;
            color: white;
            /* margin-top: -15px; */
            font-size: 36px;
            // position: absolute;
            // left: 13px;
            // top: 6px;
            margin-bottom: 3px;
          }
        }

        .gift-redeemed {
          right: -2px !important;
          color: wheat;

          svg {
            font-size: 34px;
            color: wheat;
          }
        }
      }
    }

    .ticket-info {
      width: 80%;
      height: 100%;

      .lighter {
        color: #959595 !important;
      }

      time {
        color: $text-color;
        font-size: 14px;
      }

      .ticket-number {
        color: $text-color;
        margin-top: 8px;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .ticket-btn {
      display: flex;
      flex-flow: column wrap;
      width: 20%;

      span {
        margin-top: 6px;
        color: var(--primary);
        text-align: center;
      }

      button {
        width: auto;
        border: none;
        position: absolute;
        right: 35px;
        top: 8px;
        margin-top: 6px;

        &:hover {
          background-color: transparent;
        }

        .hand-icon {
          filter: invert(28%) sepia(97%) saturate(1550%) hue-rotate(251deg) brightness(94%) contrast(94%);
          height: 100%;
        }

        svg {
          color: var(--primary);
          font-size: 20px;
        }
      }

      .scratch-btn {
        margin-top: 17px;
        font-weight: bold;
        font-size: 16px;
        color: #959595 !important;
        right: 29px;
      }
    }

    .bonus-icon {
      height: 25px;
      position: absolute;
      top: 14px;
      left: -17px;
      filter: invert(60%) sepia(48%) saturate(2442%) hue-rotate(108deg) brightness(103%) contrast(61%);
    }

    .contemplated-icon {
      height: 40px;
      position: absolute;
      top: 22px;
      left: -27px;
    }
  }

  .register-lucky-number {
    .register {
      width: 100%;
      height: 75px;
      border-radius: 10px;
      background-color: #32B97B;
    }

    .info {
      color: #32B97B;
      text-align: right;
      margin-top: 8px;
    }

    .registering-number {
      display: flex;
      flex-flow: row wrap;
      gap: 10px;
      background-color: var(--bg-color-light);
      border-radius: 10px;
      padding: 20px;

      button {
        width: calc(50% - 5px);
        border-radius: 10px;
      }

      .confirm-btn {
        background-color: #32B97B;
        color: white;
      }
    }
  }
}
</style>