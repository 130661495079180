<template>
  <MainLayout>
    <div class="cupom-view" v-if="campaign.gears && campaign.gears.control">
      <div class="top-title">
        <h1>Cupom</h1>
      </div>

      <div class="cupom-content">
        <h2>Apresente seu QRCode no stand</h2>

        <hr style="margin: 20px 0" />

        <div class="left">
          <center>
            <qrcode-vue :value="qrcode" :size="250"></qrcode-vue>
          </center>

        </div>

        <div class="cupom-info right">
          <table>
            <tr>
              <td class="label">Número</td>
              <td>{{ cupom.number }}</td>
            </tr>
            <tr>
              <td class="label">Criação</td>
              <td v-if="cupom.created_at">{{ cupom.created_at | formatDateTime }}</td>
            </tr>
            <tr>
              <td class="label">Troca</td>
              <td v-if="cupom.status == 'EXCHANGED'">{{ cupom.updated_at | formatDateTime }}</td>
              <td v-else-if="cupom.status == undefined"></td>
              <td v-else>-</td>
            </tr>
            <tr>
              <td class="label">Status</td>
              <td>{{ ticketStatusOptions[cupom.status] }}</td>
            </tr>
          </table>
        </div>
      </div>


      <center>
        <button class="primary" @click="historyBack">Voltar</button>
      </center>


    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import OrderMixin from "../mixins/order.mixin.js";

import CampaignMixin from "../mixins/campaign.mixin.js";
import userMixin from "../mixins/user.mixin";

import QrcodeVue from 'qrcode.vue'

import "../filters/toCurrency";

export default {
  mixins: [CampaignMixin, userMixin, OrderMixin],
  components: {
    MainLayout,
    QrcodeVue
  },
  data() {
    return {
      tab: "bill",
      notaStatus: ["Erro", "Aprovada", "Rejeitada", "Processando"],
      qrcode: null,
      cupom: {
        company: null,
        campaign: null,
        user: null,
        product: null,
        invoice: null,
        type: 'CUPOM',
        number: '',
        status: 'ACTIVE',
        bonus: {
          is_bonus: false,
          generative: ''
        },
        log: [],
        created_at: null,
        updated_at: null,
      },
      ticketStatusOptions: {
        ACTIVE: 'Ativo',
        PRINTED: 'Impresso',
        EXCHANGED: 'Trocado',
        CANCELED: 'Cancelado',
        CANCEL: 'Cancelado'
      },
      orders: [],
      listMessage: "Carregando",
      listMessagePayment: "Carregando",
    };
  },
  mounted: function () {
    let self = this;
    var id = this.$route.params.id;
    this.getTicket(id).then(function (response) {
      console.log(response)
      self.cupom = response.ticket;
      self.qrcode = JSON.stringify({
        ticket: self.cupom._id,
        user: self.cupom.user._id,
      })

    })
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors.scss";

.cupom-view {
  padding-bottom: 150px;
}

.cupom-content {
  height: 100%;
  padding: 20px 10px 0px 20px;
  margin-bottom: 0px;

  justify-content: space-evenly;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 50px;
  padding: 20px;
  gap: 40px;

  .image {
    width: 80%;
    max-width: 420px;
    min-height: 400px;
    background: var(--bg-color-dark);
    display: block;
    margin: auto;
  }

  // overflow-y: scroll;

  h1.info {
    margin-top: 36vh;
    font-size: 20px;
    color: $primary-light;
  }

  .cupom-info {
    background: var(--bg-color-dark);
    padding: 50px 30px;

    table {
      width: 100%;

      td {
        padding: 10px 0px;
        text-align: left;

        &.label {
          font-weight: bold;
          color: var(--primary);
        }
      }
    }
  }
}

.left {
  width: calc(45% - 20px);
}

.right {
  width: calc(55% - 20px);
}

@media (max-width: 768px) {
  .cupom-content {
    padding: 20px 0px !important;

    .left {
      width: 100%;
    }

    .right {
      width: 100%;
    }
  }
}
</style>