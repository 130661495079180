<template>
  <section>
    <div class="camera-photo">
      <!-- <code v-if="device">{{ device.label }}</code> -->

      <div class="camera-content">
        <div v-if="!photo">
          <div class="button-area">
            <button type="button" class="primary-off" @click="changeCamera">
              {{ deviceIndex }} / {{ devices.length }}
              <img class="icon-cam" src="../assets/icons/icon-change-cam.svg" alt="" />
            </button>
          </div>
          <img class="qrcode-frame" src="../assets/icons/qrcode_frame.svg" alt="" />
        </div>
      </div>

      <div class="screen" :class="{ 'totem': isTotem }" v-if="takePhoto">
        <div v-if="!photo">
          <web-cam class="webcam" ref="webcam" :resolution="resolution" :device-id="deviceId" @started="onStarted"
            @stopped="onStopped" @error="onError" @camera-change="onCameraChange" @cameras="onCameras"></web-cam>

          <div class="button-area shot-area">
            <button type="button" class="shot" @click="onCapture" v-if="isTotem" :disabled="!isCameraReady">
              Tirar foto
            </button>

            <button type="button" class="shot" @click="onCapture" v-else>
              <span></span>
            </button>
          </div>

        </div>

        <div v-if="photo">
          <img :src="photo" v-if="photo" class="preview-photo" alt="" />

          <div class="footer-content">
            <button type="button" class="primary-off" @click="cancelPhoto">
              Tentar novamente
            </button>
            <button type="button" class="primary" @click="confirmPhoto">
              Confirmar
            </button>
          </div>
        </div>
        <div v-if="process">
          <h2>Processando sua nota...</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { WebCam } from "vue-web-cam";
import CampaignMixin from "@/mixins/campaign.mixin.js";
// import PopupQuestion from "@/components/popup-question.vue";
import orderMixin from "@/mixins/order.mixin";

// import CameraView from '@/components/CameraView'
// import { QrcodeStream
navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia;

const deviceHeight =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
const deviceWidth =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default {
  mixins: [CampaignMixin, orderMixin],
  components: {
    WebCam,
  },
  data() {
    return {
      isOpenQuestion: false,
      loaded: false,
      photo: null,
      takePhoto: true,
      process: false,
      camera: null,
      deviceId: null,
      deviceIndex: 0,
      resolution: {},
      resolutionCam: {
        height: deviceHeight,
        width: deviceWidth,
      },
      resolutionShot: {
        height: deviceHeight,
        width: deviceWidth,
      },
      devices: [],
      isTotem: false,
      isCameraReady: false,
    };
  },
  mounted() {
    // this.$refs.rootElement.requestFullscreen();
    var max = 2560;
    var proportion = deviceHeight / deviceWidth;
    this.resolutionShot.width = max;
    this.resolutionShot.height = max * proportion;
    this.resolution = this.resolutionShot;

    const route = this.$route.fullPath
    const routeSplit = route.split('/')
    console.log({ rota: route, routeSplit })
    if (routeSplit.length > 0 && routeSplit[1] === 'totem') this.isTotem = true

    if (this.campaign) {
      if (this.campaign.quizz[0].is_enable) {
        this.isOpenQuestion = true;
      }
      var token =
        "question-" + this.campaign.company.slug + "-" + this.campaign.slug;
      console.log(token);
      console.log("aqui esta", localStorage[token]);

      if (localStorage[token] && this.campaign.quizz[0].frequency == "ONCE") {
        console.log("passed");
        this.isOpenQuestion = false;
      }
    }

    this.$swal.fire({
      text: "Envie uma foto da nota fiscal completa.",
    });
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one

      if (!this.loaded) {
        this.changeToLast();
      } else {
        const [first, ...tail] = this.devices;
        console.log(tail);
        if (first) {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
          this.deviceIndex = 1;
        }
      }
    },
  },
  methods: {
    openSuccess() {
      window.open(this.campaignHome + "/sucesso", "_self");
    },
    changeToLast() {
      const last = this.devices.length - 1;
      this.deviceId = this.devices[last].deviceId;
      this.camera = this.deviceId;
      this.deviceIndex = this.devices.length;

      console.log(this.deviceId, this.camera, this.deviceIndex);
    },
    changeCamera() {
      if (this.devices <= 1) return;
      let getNext = false;

      console.log(this.deviceId);
      this.deviceIndex = 0;

      for (let camera of this.devices) {
        this.deviceIndex = this.deviceIndex + 1;
        console.log("teste:", this.deviceId);
        if (getNext) {
          console.log("Set new:", camera.deviceId);
          this.deviceId = camera.deviceId;
          return;
        }
        if (camera.deviceId == this.deviceId) {
          console.log("Found:", this.deviceId);
          getNext = true;
        }
      }
      if (getNext) {
        console.log("Set first:", this.devices[0].deviceId);
        this.deviceIndex = 1;
        this.deviceId = this.devices[0].deviceId;
      }
    },
    take_photo() {
      this.photo = this.$refs.webcam.getPhoto();
    },
    confirmPhoto() {
      let self = this;
      if (this.photo.length < 7) return;
      this.process = true;
      this.takePhoto = false;
      this.readQrcode = false;
      this.uploadPhoto(this.photo).then((response) => {
        console.log(response);
        if (response.image) {
          console.log(self.getImageUrl(response.image));
          if (this.campaign.gears.control.allow_customer_send_NFdata) {
            if (self.isTotem) this.$emit('goToRegister', response.image)
            else window.open(this.campaignHome + "/cadastro-nota-fiscal/" + response.image._id, "_self")
          } else {
            this.saveNF(response.image._id);
          }
        }
      });
    },
    saveNF(imgId) {
      const shopping = localStorage.shoppingSlug;
      let campaign = "";
      const user = JSON.parse(localStorage.user);

      if (this.$route.params.campanha) {
        campaign = this.$route.params.campanha;
      }

      let newBill = {};
      newBill.user = user._id;
      newBill.company = shopping;
      newBill.campaign = campaign;
      newBill.image = imgId;

      this.createBill({
        invoice: newBill,
        shopping: shopping,
        campaign: campaign,
      }).then((response) => {
        if (response && response != undefined) {
          window.open(this.campaignHome + "/sucesso", "_self");
        }
      });
    },
    cancelPhoto() {
      this.photo = null;
      //o componente não existe, demora uns 2 segundos para ele aparecer, por isso o timer de 3s para ai sim conseguir chamar a função de start
      setTimeout(() => {
        // console.log('timer agora', this.$refs.webcam, this.$refs, this.deviceId)
        this.onStart()
      }, 2600);
    },
    // Webcam
    onCapture() {
      console.log("onCapture", "Click");
      // this.resolution = this.resolutionShot;

      this.photo = this.$refs.webcam.capture();

      // this.resolution = this.resolutionCam;
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
      this.isCameraReady = true
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.isCameraReady = false
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      console.log("onCameras");
      this.devices = cameras;
      if (cameras.length > 1) {
        this.deviceId = cameras[1].deviceId;
      }
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      // this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
};
</script>

<style lang="scss">
@import "~@/scss/colors.scss";

.camera-photo {
  position: relative;
  background: #000;
  width: 100%;
  //   aspect-ratio: 3 / 4;
  height: 70vh;
  //   min-height: calc(100vh - 100px);

  h2 {
    text-align: center;
    margin-top: 20%;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;

    img.icon-cam {
      width: 23px;
      margin-bottom: -2px;
    }
  }

  .shot-area {
    position: fixed !important;
    bottom: 60px;
    width: 100%;
    z-index: 102;
  }

  button.shot {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    position: relative;
    margin: auto;

    span {
      width: 60px;
      height: 60px;
      position: absolute;
      display: block;
      top: 10px;
      left: 10px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.6);
    }
  }

  .camera-content {
    position: relative;
    z-index: 100;

    .qrcode-frame {
      display: none;
      position: relative;
      height: 70%;
      margin: auto;
      margin-top: 5%;
    }

    .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;

    &.totem {

      .button-area {
        height: 52px;
      }

      .shot-area {
        position: absolute !important;
        width: 45%;
        bottom: 0px;
        right: 0px;

        button {
          border-radius: 0;
          width: 100%;
          height: 36px;
          float: right;
          margin-top: 7px;
          margin-right: 10px;
          padding: 0px 20px;

          &:hover {
            background-color: var(--primary-dark);
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.4;
          }
        }
      }

      .preview-photo {
        position: initial;
        width: 100%;
      }

      .footer-content {
        position: absolute !important;
        width: 100%;
        bottom: 62px;
        right: 0;
        display: flex;
        gap: 15px;
        padding: 0px 10px;

        button {
          border-radius: 0;
          height: 36px;
          padding: 0px 20px;
          flex: 1;
        }
      }
    }

    .preview-photo {
      position: fixed;
      right: calc(50% - 100vh);
      height: 100%;
    }

    video.webcam {
      position: relative;
      // right: calc(50% - 100vh);
      bottom: 0;
      // min-width: 100%;
      // min-height: 100%;
      width: 100%;
      height: auto;
      background-size: cover;
      max-width: inherit !important;
      max-height: inherit !important;
      object-fit: cover;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .camera-photo {
    position: relative;
    min-height: calc(100vh - 65px);

    &.can-toggle {
      min-height: calc(100vh - 200px);
    }

    .camera-content {
      .qrcode-frame {
        position: absolute;
        width: 70%;
        height: auto;
        // max-height: 70%;
        margin: 15%;
      }
    }

    .screen {
      height: 100vh !important;
      position: fixed !important;
      top: 0px;

      .qrcode-frame {
        max-height: 100vh;
      }

      .preview-photo,
      video.webcam {
        position: fixed;
        right: 0;
      }
    }
  }
}
</style>