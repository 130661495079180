<template>
  <ValidationProvider class="input-frame" tag="div" :vid="vid" :rules="rules" :name="name || label"
    v-slot="{ errors, required, ariaInput, ariaMsg }">
    <input v-if="!options && !isTextArea && !isHtmlArea && !isCurrency" :type="type" :step="step" :id="id" :name="name"
      :placeholder="placeholder" v-model="okModel" v-on:blur="onBlur" v-on:focus="onFocus" v-on:keyup.enter="onEnter"
      v-mask="mask" :disabled="disabled" autocomplete="off" v-bind="ariaInput" />

    <v-select label="name" :id="id" :name="name" v-if="options" v-model="okModel" v-on:change="onBlur"
      v-on:input="onInput" :options="options" :reduce="(option) => option.value" v-bind="ariaInput"
      :disabled="disabled"></v-select>

    <textarea v-if="isTextArea" :id="id" :name="name" :type="type" :placeholder="placeholder" v-model="okModel"
      v-on:blur="onBlur" v-on:keyup.enter="onEnter" :disabled="disabled" autocomplete="off"></textarea>

    <vue-editor v-if="isHtmlArea" :id="id" :name="name" :type="type" placeholder="" v-model="okModel" v-on:blur="onBlur"
      v-on:keyup.enter="onEnter" style="padding-top: 40px" :disabled="disabled">
    </vue-editor>

    <money v-if="isCurrency" :id="id" :name="name" :placeholder="placeholder" v-model="okModel" v-on:blur="onBlur"
      v-on:keyup.enter="onEnter" :disabled="disabled" v-bind="money">
    </money>

    <label for="document"><span :class="{ 'error-text': errors[0] }">{{ label }}</span>
      <span :class="{ 'error-text': errors[0] }">{{
        required ? " *" : ""
      }}</span></label>

    <span class="error-spam" v-bind="ariaMsg" v-if="errors[0]">{{
      errors[0]
    }}</span>
  </ValidationProvider>

  <!-- {{okModel}} -->
</template>

<script>
import moment from "moment";
import { VueEditor } from "vue2-editor";

export default {
  name: "OkInput",
  components: {
    VueEditor,
  },
  data: function () {
    return {
      okModel: "",
      step: null,
      error: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      }
    };
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    vid: {
      type: String,
      default: undefined,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    model: {
      type: String,
      required: false,
      default: null,
    },
    mask: {
      type: [Object, String],
      required: false,
      default: "",
    },
    fail: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
    isDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTextArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHtmlArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    validation: {
      type: String,
      require: false,
      default: null,
    },
  },
  mounted() {
    if (this.model) {
      this.setValue(this.model);
    }
  },
  watch: {
    model(newValue) {
      this.setValue(newValue);
    },
    okModel(newValue) {
      // console.log(newValue);
      if (this.isDate) {
        this.$emit("updated", this.decodeDate(newValue));
        return;
      }

      if (this.type == "number") {
        let numberValue = newValue ? newValue : 0;
        this.$emit("updated", numberValue);
        return;
      }

      this.$emit("updated", newValue);

      if (this.options != null) {
        // console.log('TEM OPTIONS', newValue);
        this.$emit("selected")
      }
    },
  },
  methods: {
    setValue(newValue) {
      // if( isNaN(newValue)) return;

      if (this.isDate) {
        if (this.okModel === "") this.okModel = this.encodeDate(newValue);
        return;
      }

      if (this.type == "number") {
        let numberValue = parseFloat(newValue, 0);
        this.step = .01;
        this.okModel = numberValue;
        return;
      }

      if (this.isCurrency) {
        let numberValue = parseFloat(newValue).toFixed(2);
        this.okModel = numberValue;
        return;
      }

      if (newValue == "") {
        this.okModel = null;
      } else {
        this.okModel = newValue;
      }
    },
    onFocus() {
      this.$emit("onFocus");
    },
    onBlur() {
      this.$emit("onBlur");
    },
    onEnter() {
      this.$emit("onEnter");
    },
    onInput() {
      // console.log("onInput ", this.okModel);
      this.$emit("onInput");
    },
    changed(v) {
      console.log("change", v, this.okModel);
    },
    dateIsValid(newDate, format) {
      var data = moment(newDate, format);
      return data.isValid();
    },
    encodeDate(newDate) {
      if (!newDate) return "";
      if (newDate.length > 10) newDate = newDate.substring(0, 10);
      const answer = moment(newDate, "YYYY-MM-DD")
        .add(12, "hours")
        .format("DD/MM/YYYY");
      return answer;
    },
    decodeDate(newDate) {
      const answer = moment(newDate, "DD/MM/YYYY")
        .add(12, "hours")
        .format("YYYY-MM-DD");
      return answer;
    },

  },
};
</script>

<style  lang="scss" >
@import "~@/scss/colors.scss";
@import "~@/scss/vue-select.scss";

.input-frame {
  position: relative;
  margin-bottom: 5px;
  padding: 0 2px;
  width: 100%;
  margin-bottom: 12px;

  label {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 6px;
    // width: 90%;
    // color: var(--primary) !important;
    color: $text-color !important;
    font-size: 15px;

    transform-origin: top left;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transform: translate(20px, 16px) scale(1);
  }

  div.v-select {

    & div.vs__dropdown-toggle:focus+label,
    &:not(:placeholder-shown)+label {
      top: 2px;
      left: 27px;
      // transform: translate(0px, 0px) scale(0.5) !important;
      color: $primary !important;
      transform: scale(0.8) !important;
    }
  }

  div.autocomplete {

    & div.vs__dropdown-toggle:focus+label,
    &:not(:placeholder-shown)+label {
      top: 6px;
      left: 27px;
      transform: translate(0px, 0px) scale(0.5) !important;
      color: $text-color !important;
    }
  }

  .quillWrapper>* {
    background-color: #ffffff;

    >.ql-disabled {
      background-color: #ccc;
    }
  }

  .quillWrapper>.ql-disabled {
    background-color: #eee;
  }

  .vs--searchable {
    .vs__dropdown-toggle {
      // border: 1px solid #ccc !important;
      // background-color: #ffffff;
      // border-radius: 4px;
      // box-shadow: $shadow-border;
      height: 48px;
      font-size: 15px;
    }

    &.vs--disabled>* {
      background-color: #eee;

      input {
        background-color: transparent;
      }
    }
  }

  .vs--single .vs__selected {
    color: $text-color;
    margin-left: 12px;
    margin-right: 35px;
    height: 50px;
  }

  .vs__search {
    padding: 0px !important;
    padding-left: 22px !important;
    position: absolute;
    width: 100%;
  }

  .vs__clear {
    display: none;
  }

  .vs__search,
  .vs__search:focus {
    padding-left: 22px;
  }

  input.autocomplete-input {
    padding-left: 48px !important;

    &::placeholder {
      color: $text-color !important;
    }
  }

  >input,
  textarea {
    &:disabled {
      background-color: #eee;
    }

    // border: 1px solid #ccc !important;
    // background-color: #ffffff;
    // border-radius: 4px;
    // box-shadow: $shadow-border;
    display: -webkit-box;
    display: flex;
    height: 48px;
    padding: 4.5px 22.5px 0px;
    margin: 0px;
    margin-bottom: 4px;
    width: 100%;
    outline: none;
    text-align: left;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    font-size: 15px;

    &::placeholder {
      color: transparent;
    }

    &:focus::placeholder {
      color: $text-color !important;
    }

    &:required:invalid+label {
      color: red;
    }

    &:focus:required:invalid {
      border-bottom: 2px solid red;
    }

    &:required:invalid+label:before {
      content: "*";
    }

    &:focus+label,
    &:not(:placeholder-shown)+label {
      transform: translate(0px, 0px) scale(0.5) !important;
      top: 2px;
      left: 27px;
      transform: scale(0.8) !important;
      // color: var(--primary) !important;
      color: $primary !important;
    }
  }

  textarea {
    height: 130px;
    padding: 18px 22.5px 0px;
  }

  textarea.autocomplete-input {
    padding-left: 48px !important;

    &::placeholder {
      color: $text-color !important;
    }
  }

  &.modal-input input {
    padding: 5px;
    height: 40px;
  }
}

.error-spam {
  color: red !important;
  font-size: 14px;
  margin-left: 22px;
}

.error-text {
  color: red !important;
}

.fail-error {
  border: 0.5px solid red !important;

  &:focus+label,
  &:not(:placeholder-shown)+label {
    color: red !important;
  }
}
</style>