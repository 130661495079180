<template>
  <MainLayout>
    <div class="billsucess-campaign-container" :style="style">
      <div class="top-title">
        <h1>Aponte a câmera para o QRcode da nota</h1>
      </div>
      <!-- <code v-if="device">{{ device.label }}</code> -->
      <div id="icon-success">
        <img src="../assets/icons/icon_success.svg" alt="" />
      </div>

      <h2>Leitura realizada com sucesso.</h2>

      <form class="rating-box" v-if="campaign && campaign.gears && campaign.gears.control.send_store">
        <h3>
          Gostou do atendimento ? <br />
          Informe o vendedor que lhe atendeu.
        </h3>
        <br />
        <ok-input :model="send_store" @updated="
          (x) => {
            send_store = x;
          }
        " :options="storeListNames" @selected="getUsers()" :id="'store'" :label="'Selecione a loja'"
          :placeholder="' '"></ok-input>

        <div class="star-frame" v-if="campaign && campaign.gears && campaign.gears.control.send_store">
          <i class="star" :class="{ 'active': rate_store > 0 }" @click="rate_store = 1"></i>
          <i class="star" :class="{ 'active': rate_store > 1 }" @click="rate_store = 2"></i>
          <i class="star" :class="{ 'active': rate_store > 2 }" @click="rate_store = 3"></i>
          <i class="star" :class="{ 'active': rate_store > 3 }" @click="rate_store = 4"></i>
          <i class="star" :class="{ 'active': rate_store > 4 }" @click="rate_store = 5"></i>
        </div>
      </form>
      <form class="rating-box" v-if="campaign && campaign.gears && campaign.gears.control.send_seller">
        <ok-input :model="send_seller" @updated="
          (x) => {
            send_seller = x;
          }
        " :options="userListNames" :id="'seller'" :label="'Nome do atendente'" :placeholder="' '"
          style="margin-top: 20px;">
        </ok-input>

        <div class="star-frame" v-if="campaign && campaign.gears && campaign.gears.control.send_seller">
          <i class="star" :class="{ 'active': rate_seller > 0 }" @click="rate_seller = 1"></i>
          <i class="star" :class="{ 'active': rate_seller > 1 }" @click="rate_seller = 2"></i>
          <i class="star" :class="{ 'active': rate_seller > 2 }" @click="rate_seller = 3"></i>
          <i class="star" :class="{ 'active': rate_seller > 3 }" @click="rate_seller = 4"></i>
          <i class="star" :class="{ 'active': rate_seller > 4 }" @click="rate_seller = 5"></i>
        </div>

      </form>

      <hr v-if="survey != null">
      <div class="survey-view" v-if="survey != null">
        <h1>Pesquisa</h1>
        <h2 class="survey-name">{{ survey.name }}</h2>
        <form onSubmit="return false;" v-if="answers && answers.length > 0">
          <div class="question" v-for="(question, index) of survey.questions" :key="question.title">
            <p class="title">{{ question.title }}</p>
            <div v-if="question.type == 'MULTIPLE'">
              <div class="radio-container" v-for="(option, optionIndex) of question.options" :key="option.title">
                <input type="radio" class="input-radio" :id="'question-' + index + '-option-' + optionIndex"
                  :value="option.title" v-model="answers[index].answer_text" />
                <label :for="'question-' + index + '-option-' + optionIndex">{{ option.title }}</label>

                <div v-if="question.other_enable && question.options.length == optionIndex + 1">
                  <ok-input :model="answers[index].answer_number" @updated="
                    (x) => {
                      answers[index].answer_number = x;
                    }
                  " :label="'Resposta'" :placeholder="' '" :id="'other-answer'" style="margin-left: 20px;">
                  </ok-input>
                </div>
              </div>
            </div>
            <div v-else>
              <ok-input v-if="question.answer_type == 'TEXT'" :model="answers[index].answer_text" @updated="
                (x) => {
                  answers[index].answer_text = x;
                }
              " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" style="width: 80%;">
              </ok-input>
              <ok-input v-if="question.answer_type == 'NUMBER'" :model="answers[index].answer_number" @updated="
                (x) => {
                  answers[index].answer_number = x;
                }
              " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :type="'number'"
                style="width: 80%;">
              </ok-input>
              <ok-input v-if="question.answer_type == 'DATE'" :model="answers[index].answer_date" @updated="
                (x) => {
                  answers[index].answer_date = x;
                }
              " :label="'Resposta'" :placeholder="' '" :id="'answer-' + (index + 1)" :mask="'##/##/####'"
                :isDate="true" style="width: 80%;">
              </ok-input>
            </div>
          </div>
        </form>
      </div>
      <div class="buttons-frame">
        <button class="primary_border" id="btn-back" @click="goToHome">Ir para home</button>
        <button class="primary" id="btn-send"
          v-if="(campaign && campaign.gears && campaign.gears.control.send_store) || (campaign && campaign.gears && campaign.gears.control.send_seller)"
          @click="evaluate">Enviar
          indicação</button>
        <button class="primary_border" id="btn-send" @click="send()">Confirmar</button>
      </div>

    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "../layout/main";
import CampaignMixin from "../mixins/campaign.mixin.js";
import CompanyMixin from "../mixins/company.mixin.js";
import UserMixin from "../mixins/user.mixin.js";
import OkInput from "../components/ok-input.vue";
import surveyMixin from "../mixins/survey.mixin.js";

export default {
  mixins: [CampaignMixin, CompanyMixin, UserMixin, surveyMixin],
  components: {
    MainLayout,
    OkInput,
  },
  data() {
    return {
      send_store: null,
      rate_store: null,
      send_seller: null,
      rate_seller: null,
      storeList: [],
      storeListNames: [],
      userList: [],
      userListNames: [],
      survey: null,
    };
  },
  mounted() {
    this.getStores();
    this.getUsers();
    const user = JSON.parse(localStorage.user)
    this.getSurvey(user)
  },
  methods: {
    // navigation

    goToHome() {
      const url = this.campaignHome;
      window.open(url, "_self");
    },
    getStores() {
      let self = this;

      this.listStores().then((response) => {
        self.storeList = response
        self.storeListNames = response.map(item => {
          let obj = {
            name: item.name_fancy,
            value: item._id,
          }
          return obj
        })
        if (localStorage.selectedStore) this.send_store = localStorage.selectedStore
      })
    },
    getUsers() {
      const self = this
      const data = {
        shopping: localStorage.shoppingSlug,
        campaign: localStorage.campaignSlug,
      };

      if (this.send_store) {
        data.parent = this.send_store
      }

      this.listUsers(data, 'SALESMAN').then((response) => {
        self.userList = response.user_list
        self.userListNames = []
        for (let user of self.userList) {
          let name = user.name + ' ' + user.lastname
          if (user.salesman_code) name = user.salesman_code + ' - ' + name
          self.userListNames.push({
            name: name,
            value: user._id,
          });
        }
      })
    },

    getSurvey(user) {
      let self = this;
      let survey
      console.log(user, self.campaign)
      self.campaign.survey.forEach(element => {
        if (element.is_enable) {
          survey = element
          return
        }
      });
      if (!survey || survey.whenever != 'AFTER_INVOICE_REGISTER') return
      console.log(survey, user._id)
      self.loadSurvey(survey._id, user._id).then((res) => {
        if (res) {
          self.survey = res
          self.prepareAnswers()
        } else {
          self.survey = null
        }
      })
    },
    prepareAnswers() {
      this.answers = this.survey.questions.map(item => {
        console.log(item)
        let obj = {
          question: item._id,
          question_type: item.type,
          question_title: item.title,
          answer_type: item.answer_type,
          answer_text: "",
          answer_number: 0,
          answer_date: null,
          answer: '',
        }
        if (item.other_enable) item.options.push({ title: 'Outro' })
        return obj
      })
      console.log('aqui answers', this.answers)
    },
    send() {
      if (this.survey != null) this.sendAnswer()
      else if (this.send_seller || this.send_store) this.evaluate()

    },
    sendAnswer() {
      const self = this
      const shopping = localStorage.shoppingSlug;
      const campaign = localStorage.campaignSlug;

      let answersCopy = JSON.parse(JSON.stringify(self.answers));
      const user = JSON.parse(localStorage.user)
      console.log('aqui user', user)

      answersCopy.forEach(element => {
        if (element.question_type == 'MULTIPLE' && element.answer_number != 0) {
          if (element.answer_text == 'Outro') element.answer_text = element.answer_number
          element.answer_number = 0
        }
      });

      const data = {
        survey_answer: {
          company: shopping,
          campaign,
          user: user._id,
          survey: self.survey._id,
          answers: answersCopy,
        },
      }
      console.log('PESQUISA DATA', data)
      this.sendSurvey(data)
        .then((response) => {
          if (response && response != undefined) {
            self.$toasted.show('Pesquisa enviada com sucesso', { type: 'success' })
            if (this.send_seller || this.send_store) {
              self.evaluate()
            } else {

              self.$router.push('/cadastro-nota-fiscal')
            }
          }

        });
    },
    evaluate() {
      // if (!this.send_store || this.send_store == "") {
      //   this.$swal({
      //     icon: "error",
      //     title: "Informe a empresa que emitiu a nota fiscal.",
      //     // footer: '<a href>Why do I have this issue?</a>'
      //   });
      //   return;
      // }

      // if (this.send_seller.length < 3) {
      //   this.$swal({
      //     icon: "error",
      //     title: "Informe o nome da pessoa que lhe atendeu.",
      //     // footer: '<a href>Why do I have this issue?</a>'
      //   });
      //   return;
      // }

      this.sendEvaluation(this.send_store, this.rate_store, this.send_seller, this.rate_seller)
        .then((response) => {
          if (response && response != undefined) {
            window.open(this.campaignHome + '/cupons', "_self")
          }

        });
    }
  },
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.billsucess-campaign-container {
  position: relative;
  min-height: calc(100vh - 200px);
  margin-bottom: 150px;

  .star-frame {
    width: fit-content;
    height: 40px;

    .star {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 50%;
      background: #ddd;
      cursor: pointer;
      margin: 0px 2px;

      &.active {
        background: var(--primary);
      }
    }
  }

  .buttons-frame {
    margin-top: 50px;
    display: flex;
    // grid-template-columns: 1fr 1fr;

    #btn-back {
      // max-width: 120px;
    }

    #btn-send {
      //  max-width: 120px; 
    }
  }

  .rating-box {
    margin: auto;
    width: 90%;
    max-width: 420px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 30px;
    margin-bottom: 20px;
    margin-top: 70px;
  }

  #icon-success {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 40px;

    img {
      display: block;
    }
  }

  .top-title {
    h1 {
      font-size: 11pt !important;
    }
  }

  h2 {
    text-align: center;
    margin-top: 30px;
    font-size: 15pt;
    color: var(--primary);
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: auto;
    height: auto;
    padding: 10px 15px;
    margin: auto;
  }

  .screen {
    background: #333;
    height: 100%;
    width: 100%;
    position: absolute;

    .button-area {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
  }
}
</style>