import Vue from 'vue'

// dev
import uuid from "device-uuid";
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import SassLoader from 'sass-loader';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toasted from 'vue-toasted';


// forms
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import money from 'v-money'
// import DatePicker from 'vue2-datepicker';
import FileUpload from 'v-file-upload'


import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'Campo obrigatório'
});
extend('email', {
  ...email,
  message: 'Verifique o formato do email'
});

// others
import SocialSharing from 'vue-social-sharing'
import VueScrollTo from 'vue-scrollto';
import VueQrcodeReader from "vue-qrcode-reader";
import moment from 'moment';

//fontawesome

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faHandPointUp, faGift, faBoxOpen, faSort } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faUserSecret, faHandPointUp, faGift, faBoxOpen, faSort)
library.add(faTimesCircle)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


// import QrcodeVue from 'qrcode.vue'
// Vue.use(QrcodeVue);

// Config
Vue.use(uuid);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
// Vue.use(SassLoader);
Vue.use(VueSweetalert2);


Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(money, { prefix: 'R$ ', decimal: ',', thousands: '.', precision: 2 })
Vue.use(FileUpload);
Vue.component('v-select', vSelect);

Vue.use(SocialSharing);
Vue.use(VueScrollTo);
Vue.use(VueQrcodeReader);

Vue.use(Toasted, {
  position: "bottom-center",
  duration: 3000,
  containerClass: 'toasted-custom',
});

Vue.prototype.moment = moment;
moment.locale('pt-br');

Vue.config.productionTip = false

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

// var Router = new VueRouter({
  // 	mode: 'history',
// 	routes: []
// });
// Router.addRoutes(routes);