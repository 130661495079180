import Vue from 'vue'

Vue.filter("toCPF", function (value) {
  let text = new String(value);
  let primeiraParte = text.slice(0, 3);
  let segundaParte = text.slice(3, 6);
  let terceiraParte = text.slice(6, 9);
  let quartaParte = text.slice(-2);

  return primeiraParte
    .concat(".")
    .concat(segundaParte)
    .concat(".")
    .concat(terceiraParte)
    .concat("-")
    .concat(quartaParte);
});

Vue.filter("toCNPJ", function (value) {
  let text = new String(value);
          let primeiraParte = text.slice(0, 2);
          let segundaParte = text.slice(2, 5);
          let terceiraParte = text.slice(5, 8);
          let quartaParte = text.slice(8, 12);
          let quintaParte = text.slice(-2);
        
          return primeiraParte
            .concat(".")
            .concat(segundaParte)
            .concat(".")
            .concat(terceiraParte)
            .concat("/")
            .concat(quartaParte)
            .concat("-")
            .concat(quintaParte);
});



